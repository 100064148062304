import React from 'react';
import QuickFilter from './QuickFilter/QuickFilter';
import FilterOption from './FilterOption/FilterOption';
import './FiltersOptions.css';

function FiltersOptions({
    category,
    searchQuery,
    quickFilters,
    filters,
    quickFilterLabelColor,
    quickFilterBackgroundColor,
    quickFilterCheckboxBackgroundColor,
    filterLabelColor,
    filterBackgroundColor,
    onToggle,
    onDelete,
    metadata
}) {
    const handleToggle = (quickFilterId) => {
        if (onToggle) onToggle(quickFilterId);
    };

    const handleDelete = (filterId, optionId) => {
        if (onDelete) onDelete(filterId, optionId);
    };

    const renderQuickFilters = () => {
        let content = [];
        quickFilters.forEach((quickFilter) => {
            content.push(
                <QuickFilter
                    key={`quick-filter-option-${quickFilter.id}`}
                    selected={quickFilter.selected}
                    category={category}
                    searchQuery={searchQuery}
                    quickFilter={quickFilter}
                    width={170}
                    marginRight={15}
                    labelColor={quickFilterLabelColor}
                    backgroundColor={quickFilterBackgroundColor}
                    checkboxBackgroundColor={quickFilterCheckboxBackgroundColor}
                    onToggle={handleToggle}
                    metadata={metadata}
                />
            );
        });
        return content;
    };

    const renderFilters = () => {
        let content = [];
        filters.forEach((filter) => {
            filter.options.forEach((option) => {
                content.push(
                    <FilterOption
                        key={`filter-option-${filter.id}-${option.id}`}
                        category={category}
                        searchQuery={searchQuery}
                        parentFilter={filter}
                        filterOption={option}
                        width={200}
                        marginRight={15}
                        labelColor={filterLabelColor}
                        backgroundColor={filterBackgroundColor}
                        animate={filter.animate}
                        onDelete={handleDelete}
                        metadata={metadata}
                    />
                );
            });
        });
        return content;
    };

    return (
        <div className='filters-options'>
            {renderQuickFilters()}
            {renderFilters()}
        </div>
    );
}

export default FiltersOptions;