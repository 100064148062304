import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import PromotionalVideo from './components/PromotionalVideo/PromotionalVideo';
import Config from './containers/Config/Config';
import View from './components/View/View';
import NotFound from './containers/NotFound/NotFound';
import DataLoader from './components/DataLoader/DataLoader';
import DataError from './components/DataError/DataError';
import VersionTag from './components/VersionTag/VersionTag';
import UpdateAlert from './components/UpdateAlert/UpdateAlert';
import Categories from './containers/Categories/Categories';
import Category from './containers/Category/Category';
import Product from './containers/Product/Product';
import Debug from './helpers/Debug';
import Storage from './helpers/Storage';
import Viewport from './helpers/Viewport';
import Activity from './helpers/Activity';
import WebServices from './helpers/WebServices';
import Environment from './config/Environment';
import Navigation from './config/Navigation';
import AlgoliaInsights from './config/AlgoliaInsights';
import appData from '../package.json';
import {ShoppingCart as _ShoppingCart} from './models/ShoppingCart';
import {MAX_INACTIVITY_TIME, INACTIVITY_MODAL_TIME, LOG_INACTIVITY_TIME, SHOW_APP_VERSION, getElapsedTime, setElapsedTime} from './config/App';
import {firestore, getCollectionPath} from './config/Firebase';
import {collection, addDoc, doc, onSnapshot, Timestamp} from 'firebase/firestore';
import {setTags} from './config/Sentry';
import {instanceConverter} from './models/Instance';
import {catalogConverter} from './models/Catalog';
import {storeConverter} from './models/Store';
import {styleMapConverter} from './models/StyleMap';
import {sessionConverter} from './models/Session';
import defaultPromotionalVideo from './videos/promotional-video.mp4';
import md5 from 'md5';
import './App.css';

export default class App extends React.Component {

    // COMPONENT LIFECYCLE.

    constructor(props) {
        super(props);
        this.state = {
            renderIndex: 0,
            newConnection: false,
            isLoadingConfig: true,
            configLoaded: false,
            client: Storage.getClient(),
            instanceId: Storage.getInstance(),
            instance: undefined,
            isValidatingInstance: false,
            instanceError: undefined,
            isLoadingSession: true,
            sessionLoaded: false,
            sessionId: Storage.getSession(),
            session: undefined,
            isLoadingCatalog: false,
            catalogLoaded: false,
            catalog: undefined,
            isLoadingStore: false,
            storeLoaded: false,
            store: undefined,
            isLoadingStyle: false,
            styleLoaded: false,
            style: undefined,
            showConfigModal: false,
            zoomLevel: Storage.getZoomLevel(),
            accessibilityMode: Storage.getAccessibilityMode(),
            showPromotionalVideo: false,
            showInactivityModal: false,
            showShoppingCart: false,
            shoppingCart: Storage.getShoppingCart(),
            redirectHome: false,
            algoliaInsights: undefined,
            showUpdateAlert: false
        };
        this._isMounted = true;
        this._unsubscribeInstance = undefined;
        this._unsubscribeSession = undefined;
        this._unsubscribeCatalog = undefined;
        this._unsubscribeStore = undefined;
        this._unsubscribeStyle = undefined;
        this._promotionalVideoInterval = undefined;
        this._sessionPingInterval = undefined;
        this._savingUserInteraction = false; // Required to prevent multiple logs of the same event.
        this._isRequestingSession = false; // Required to prevent multiple requests for session validation/creation.
    };

    getMetadata = () => {
        const {client, instance, catalog, store, session} = this.state;
        return {client: client, instance: instance, catalog: catalog, store: store, session: session};
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        const zoomLevel = Storage.getZoomLevel();
        const zoomViewport = Viewport.dimensions;
        const noZoomViewport = Viewport.noZoomDimensions;
        this._isMounted = true;
        Debug.printToLog('info', `Version: ${appData.version}`);
        Debug.printToLog('info', `Viewport (without zoom): ${noZoomViewport.width} x ${noZoomViewport.height} pixels`);
        Debug.printToLog('info', `Viewport (with x${zoomLevel} zoom): ${zoomViewport.width} x ${zoomViewport.height} pixels`);
        this.setCssDefaultColors();
        this.removeContextMenu();
        this.setResizeListener();
        this.setInteractionListeners();
        this.saveLatestUpdate().then(this.getInstance);
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.removeResizeListener();
        this.removeInteractionListeners();
        this.stopPromotionalVideoTimer();
        this.unsubscribe();
    };

    // SAVE LATEST SOURCE FILES UPDATE DATE

    saveLatestUpdate = () => {
        let timestamp;
        return WebServices.getServerTimestamp()
            .then((serverTimestamp) => {
                if (!serverTimestamp) throw new Error('invalid_server_timestamp');
                timestamp = serverTimestamp;
            })
            .catch((error) => {
                Debug.printToLog('error', `An error occurred while obtaining the latest server timestamp: ${error}`);
                timestamp = new Date().getTime();
            })
            .then(() => {
                Storage.setLatestUpdate(timestamp);
            });
    };

    checkForUpdate = (instance) => {
        if (instance && instance.updateIfBefore) {
            const updateIfBefore = instance.updateIfBefore;
            const instanceUpdate = Storage.getLatestUpdate();
            if (instanceUpdate < updateIfBefore) {
                const metadata = this.getMetadata();
                Activity.log(metadata, 'kiosk', 'update-alert', 'initialize', undefined);
                this.changeState({showUpdateAlert: false}, () => {
                    setTimeout(() => {
                        this.changeState({showUpdateAlert: true});
                    }, 1000);
                });
            }
        }
    };

    closeUpdateAlert = () => {
        const metadata = this.getMetadata();
        Activity.log(metadata, 'update-alert', 'close-button', 'close', undefined);
        this.changeState({showUpdateAlert: false});
    };

    // REMOVE SUBSCRIPTIONS.

    unsubscribe = () => {
        this.unsubscribeInstance();
        this.unsubscribeSession();
        this.unsubscribeCatalog();
        this.unsubscribeStore();
        this.unsubscribeStyle();
    };

    unsubscribeInstance = () => {
        if (this._unsubscribeInstance) this._unsubscribeInstance();
    };

    unsubscribeSession = () => {
        if (this._unsubscribeSession) this._unsubscribeSession();
    };

    unsubscribeCatalog = () => {
        if (this._unsubscribeCatalog) this._unsubscribeCatalog();
    };

    unsubscribeStore = () => {
        if (this._unsubscribeStore) this._unsubscribeStore();
    };

    unsubscribeStyle = () => {
        if (this._unsubscribeStyle) this._unsubscribeStyle();
    };

    // GET INSTANCE DATA.

    getInstance = () => {
        if (this.state.client && this.state.instanceId) {
            const _path = getCollectionPath(this.state.client, 'instances');
            const _doc = doc(firestore, _path, this.state.instanceId).withConverter(instanceConverter);
            this._unsubscribeInstance = onSnapshot(_doc, (_document) => {
                if (_document.exists()) {
                    const newInstance = _document.data();
                    newInstance.client = this.state.client;
                    this.checkForUpdate(newInstance);
                    if (this.state.newConnection) {
                        if (!newInstance.active) this.onInstanceLoad(newInstance, undefined, true);
                        else this.onInstanceLoad(undefined, 'El código está siendo usado por otro Kiosco', false);
                    } else {
                        const oldInstance = this.state.instance;
                        const oldCatalog = oldInstance ? oldInstance.catalog : undefined;
                        const newCatalog = newInstance.catalog;
                        const oldStore = oldInstance ? oldInstance.store : undefined;
                        const newStore = newInstance.store;
                        const changed = oldCatalog !== newCatalog || oldStore !== newStore;
                        this.onInstanceLoad(newInstance, undefined, changed);
                    }
                } else this.onInstanceLoad(undefined, 'El código especificado no es válido', false);
            }, (error) => {
                Debug.printToLog('error', error);
                this.onInstanceLoad(undefined, 'Se ha producido un error al validar el Kiosco', false);
            });
        } else this.onInstanceLoad(undefined, undefined, false);
    };

    onInstanceLoad = (instance, error, loadAdditionalData) => {
        this.changeState({
            isLoadingConfig: false,
            configLoaded: !!instance,
            instance: instance,
            isValidatingInstance: false,
            instanceError: error,
            instanceId: instance ? instance.id : undefined,
        }, () => {
            if (instance) {
                if (this.state.newConnection) {
                    this.changeState({newConnection: false}, () => {
                        instance.setActive();
                        Storage.setClient(this.state.client);
                        Storage.setInstance(this.state.instanceId);
                        Storage.setStore(instance.store);
                        if (loadAdditionalData) this.loadAdditionalData();
                    });
                } else {
                    Storage.setStore(instance.store);
                    if (loadAdditionalData) this.loadAdditionalData();
                }
            } else this.unsubscribeInstance();
        });
    };

    isLoadingConfig = () => {
        return this.state.isLoadingConfig;
    };

    configLoaded = () => {
        return !this.state.isLoadingConfig && this.state.configLoaded;
    };

    configError = () => {
        return !this.state.isLoadingConfig && !this.state.configLoaded;
    };

    clearValidationError = () => {
        this.changeState({instanceError: undefined});
    };

    // LOAD ADDITIONAL DATA.

    loadAdditionalData = () => {
        this.changeState({
            isLoadingCatalog: true,
            catalogLoaded: false,
            catalog: undefined,
            isLoadingStore: true,
            storeLoaded: false,
            store: undefined,
            isLoadingStyle: true,
            styleLoaded: false,
            style: undefined
        }, () => {
            this.getCatalog();
            this.getStore();
            this.getStyle();
        });
    };

    // GET CATALOG DATA.

    getCatalog = () => {
        this.unsubscribeCatalog();
        const _path = getCollectionPath(this.state.client, 'catalogs');
        const _doc = doc(firestore, _path, this.state.instance.catalog).withConverter(catalogConverter);
        this._unsubscribeCatalog = onSnapshot(_doc, (_document) => {
            const exists = _document.exists();
            if (exists) {
                const data = _document.data();
                this.onCatalogLoad(data);
            } else this.onInvalidAdditionalData();
        }, (error) => {
            Debug.printToLog('error', error);
            this.onCatalogLoad(undefined);
        });
    };

    onCatalogLoad = (catalog) => {
        this.changeState({catalog: catalog, isLoadingCatalog: false, catalogLoaded: !!catalog}, this.onAdditionalDataLoaded);
    };

    // GET STORE DATA.

    getStore = () => {
        this.unsubscribeStore();
        const _path = getCollectionPath(this.state.client, 'stores');
        const _doc = doc(firestore, _path, this.state.instance.store).withConverter(storeConverter);
        this._unsubscribeStore = onSnapshot(_doc, (_document) => {
            const exists = _document.exists();
            if (exists) {
                const data = _document.data();
                const backupStores = data.backupStores ? data.backupStores : [];
                Storage.setBackupStores(backupStores.join(","));
                this.onStoreLoad(data);
            } else this.onInvalidAdditionalData();
        }, (error) => {
            Debug.printToLog('error', error);
            this.onStoreLoad(undefined);
        });
    };

    onStoreLoad = (store) => {
        this.changeState({store: store, isLoadingStore: false, storeLoaded: !!store}, this.onAdditionalDataLoaded);
    };

    // GET STYLE DATA.

    getStyle = () => {
        this.unsubscribeStyle();
        const _path = getCollectionPath(this.state.client, 'config');
        const _doc = doc(firestore, _path, 'styles').withConverter(styleMapConverter);
        this._unsubscribeStyle = onSnapshot(_doc, (_document) => {
            const exists = _document.exists();
            if (exists) {
                const data = _document.data();
                this.onStyleLoad(data);
            } else this.onStyleLoad({});
        }, (error) => {
            Debug.printToLog('error', error);
            this.onStyleLoad(undefined);
        });
    };

    onStyleLoad = (style) => {
        this.changeState({style: style, isLoadingStyle: false, styleLoaded: !!style}, this.onAdditionalDataLoaded);
    };

    // ADDITIONAL DATA LOAD CONTROL.

    isLoadingAdditionalData = () => {
        return this.state.isLoadingCatalog || this.state.isLoadingStore || this.state.isLoadingStyle;
    };

    additionalDataLoaded = () => {
        return this.state.catalogLoaded && this.state.storeLoaded && this.state.styleLoaded;
    };

    onAdditionalDataLoaded = () => {
        if (this.additionalDataLoaded() && !this._isRequestingSession) {
            this._isRequestingSession = true;
            this.getSession(true);
        }
    };

    onInvalidAdditionalData = () => {
        this.changeState({
            isLoadingCatalog: false,
            catalogLoaded: false,
            catalog: undefined,
            isLoadingStore: false,
            storeLoaded: false,
            store: undefined,
            isLoadingStyle: false,
            styleLoaded: false,
            style: undefined
        }, () => {
            this.clearConfig();
            this.onInstanceLoad(undefined, 'El Kiosco no tiene la configuración necesaria');
        });
    };

    // SESSION CONTROL.

    getSession = (startInactivityTimer) => {
        this.unsubscribeSession();
        if (this.state.sessionId) {
            const _path = getCollectionPath(this.state.client, 'sessions');
            const _doc = doc(firestore, _path, this.state.sessionId).withConverter(sessionConverter);
            this._unsubscribeSession = onSnapshot(_doc, (_document) => {
                const exists = _document.exists();
                if (exists) {
                    const session = _document.data();
                    const now = Timestamp.now().seconds;
                    const lastPing = session.lastPing ? session.lastPing.seconds : undefined;
                    const isExpired = now - lastPing >= MAX_INACTIVITY_TIME;
                    if (!session.active || isExpired) {
                        let metadata = this.getMetadata();
                        metadata.session = session;
                        this.unsubscribeSession();
                        Activity.log(metadata, 'kiosk', 'session', 'end', undefined); // Session close event.
                        session.setInactive();
                        this.resetKiosk(false);
                        this.createSession(true);
                    } else this.onSessionLoad(session, startInactivityTimer);
                } else {
                    this.createShoppingCart();
                    this.createSession(true);
                }
            }, (error) => {
                Debug.printToLog('error', error);
                this.onInvalidSession();
            });
        } else {
            this.createShoppingCart();
            this.createSession(true);
        }
    };

    createSession = (startInactivityTimer) => {
        const {client, instance} = this.state;
        if (client && instance) {
            const data = {
                client: client,
                instance: instance.id,
                catalog: instance.catalog,
                store: instance.store,
                active: true,
                started_at: Timestamp.now(),
                finished_at: null,
                last_ping: Timestamp.now()
            };
            const _path = getCollectionPath(client, 'sessions');
            const _collection = collection(firestore, _path);
            return addDoc(_collection, data)
                .then((_reference) => {
                    const id = _reference.id;
                    const metadata = this.getMetadata();
                    const newMetadata = {client: metadata.client, instance: {id: metadata.instance.id}, catalog: {id: metadata.catalog.id}, store: {id: metadata.store.id}, session: {id: id}};
                    Activity.log(newMetadata, 'kiosk', 'session', 'start', undefined);
                    Storage.setSession(id);
                    this.changeState({sessionId: _reference.id}, () => this.getSession(startInactivityTimer));
                })
                .catch((error) => {
                    Debug.printToLog('error', error);
                    this.onInvalidSession();
                });
        } else this.onInvalidSession();
    };

    isLoadingSession = () => {
        return this.state.isLoadingSession;
    };

    sessionLoaded = () => {
        return this.state.sessionLoaded;
    };

    onSessionLoad = (session, startInactivityTimer) => {
        this.changeState({session: session, isLoadingSession: false, sessionLoaded: !!session}, () => {
            this._isRequestingSession = false;
            const {newConnection, shoppingCart} = this.state;
            if (newConnection) Storage.setAccessibilityMode(false);
            this.setSentryInstanceTag();
            this.initAlgoliaInsights();
            if (startInactivityTimer) this.startPromotionalVideoTimer();
            this.startSessionPingInterval();
            this.setCssCustomColors();
            if (!shoppingCart) this.createShoppingCart();
        });
    };

    onInvalidSession = () => {
        this.changeState({
            isLoadingSession: false,
            sessionLoaded: false,
            session: undefined
        });
    };

    closeSession = () => {
        if (this.state.session) {
            this.unsubscribeSession();
            this.state.session.setInactive();
            Storage.removeSession();
            this.stopSessionPingInterval();
            this.changeState({sessionId: undefined, session: undefined});
        }
    };

    startSessionPingInterval = () => {
        if (!this._sessionPingInterval && this.state.session) {
            this.state.session.setActive();
            this._sessionPingInterval = setInterval(() => {
                if (this.state.session) this.state.session.setActive();
            }, 1000 * 60);
        }
    };

    stopSessionPingInterval = () => {
        if (this._sessionPingInterval) {
            clearInterval(this._sessionPingInterval);
            this._sessionPingInterval = undefined;
        }
    };

    // DOCUMENT LISTENERS.

    removeContextMenu = () => {
        window.oncontextmenu = () => {return false};
    };

    setResizeListener = () => {
        window.addEventListener('resize', this.handleResize);
    };

    removeResizeListener = () => {
        window.removeEventListener('resize', this.handleResize);
    };

    handleResize = () => {
        this.changeState({renderIndex: this.state.renderIndex + 1});
    };

    setInteractionListeners = () => {
        const options = {capture: true, passive: false};
        window.addEventListener('mousemove',  this.resetPromotionalVideoTimer, options);
        window.addEventListener('mousedown',  this.resetPromotionalVideoTimer, options);
        window.addEventListener('touchstart', this.resetPromotionalVideoTimer, options);
        window.addEventListener('click',      this.resetPromotionalVideoTimer, options);
        window.addEventListener('scroll',     this.resetPromotionalVideoTimer, options);
        window.addEventListener('keypress',   this.resetPromotionalVideoTimer, options);
    };

    removeInteractionListeners = () => {
        const options = {capture: true, passive: false};
        window.removeEventListener('mousemove',  this.resetPromotionalVideoTimer, options);
        window.removeEventListener('mousedown',  this.resetPromotionalVideoTimer, options);
        window.removeEventListener('touchstart', this.resetPromotionalVideoTimer, options);
        window.removeEventListener('click',      this.resetPromotionalVideoTimer, options);
        window.removeEventListener('scroll',     this.resetPromotionalVideoTimer, options);
        window.removeEventListener('keypress',   this.resetPromotionalVideoTimer, options);
    };

    // ZOOM LEVEL.

    changeZoomLevel = (zoomLevel, logActivity) => {
        const zoomChanged = this.state.zoomLevel !== zoomLevel;
        this.changeState({zoomLevel: zoomLevel}, () => {
            if (logActivity && zoomChanged) {
                const metadata = this.getMetadata();
                Activity.log(metadata, 'config-modal', 'zoom-slider', 'change-zoom', {zoomLevel: zoomLevel});
            }
            Storage.setZoomLevel(zoomLevel);
        });
    };

    resetZoomLevel = () => {
        this.changeZoomLevel(1.0, false);
    };

    // ACCESSIBILITY MODE.

    toggleAccessibilityMode = () => {
        if (this.state.accessibilityMode) this.disableAccessibilityMode();
        else this.enableAccessibilityMode();
    };

    enableAccessibilityMode = () => {
        this.changeState({accessibilityMode: true});
        Storage.setAccessibilityMode(true);
    };

    disableAccessibilityMode = () => {
        this.changeState({accessibilityMode: false});
        Storage.setAccessibilityMode(false);
    };

    // PROMOTIONAL VIDEO.

    getPromotionalVideo = () => {
        const {style} = this.state;
        return style && style['global'] && style['global']['promotionalVideo'] ? style['global']['promotionalVideo'] : defaultPromotionalVideo;
    };

    hasPromotionalVideo = () => {
        const promotionalVideo = this.getPromotionalVideo();
        return Boolean(promotionalVideo);
    };

    startPromotionalVideoTimer = () => {
        if (this.hasPromotionalVideo() && !this._promotionalVideoInterval && !this.state.showConfigModal) {
            this._promotionalVideoInterval = setInterval(() => {
                setElapsedTime(getElapsedTime() + 1);
                const resetRemainingTime = MAX_INACTIVITY_TIME - getElapsedTime();
                if (LOG_INACTIVITY_TIME) Debug.printToLog('info', `Remaining time until reset: ${resetRemainingTime} seconds`);
                if (resetRemainingTime === 0) {
                    this.closeInactivityModal();
                    this.stopPromotionalVideoTimer();
                    this.openPromotionalVideo();
                    this.resetKiosk(false);
                    this.createSession(false);
                }
                const modalRemainingTime = INACTIVITY_MODAL_TIME - getElapsedTime();
                if (modalRemainingTime === 0) {
                    this.openInactivityModal();
                }
            }, 1000);
        }
    };

    stopPromotionalVideoTimer = () => {
        if (this._promotionalVideoInterval) {
            clearInterval(this._promotionalVideoInterval);
            this._promotionalVideoInterval = undefined;
        }
    };

    resetPromotionalVideoTimer = () => {
        this.closePromotionalVideo();
        setElapsedTime(0);
        if (this.additionalDataLoaded() && !this.state.showConfigModal) this.startPromotionalVideoTimer();
    };

    openPromotionalVideo = () => {
        if (!this.state.showPromotionalVideo) {
            const metadata = this.getMetadata();
            Activity.log(metadata, 'promotional-video', 'inactivity-timer', 'open', undefined);
            Activity.log(metadata, 'kiosk', 'session', 'end', undefined); // Session close event.
            this.changeState({showPromotionalVideo: true});
        }
    };

    closePromotionalVideo = () => {
        if (this.state.showPromotionalVideo && !this._savingUserInteraction) {
            this._savingUserInteraction = true;
            const metadata = this.getMetadata();
            Activity.log(metadata, 'promotional-video', 'inactivity-timer', 'close', undefined);
            this.changeState({showPromotionalVideo: false}, () => {
                this._savingUserInteraction = false;
            });
        }
    };

    // INACTIVITY MODAL.

    openInactivityModal = () => {
        const metadata = this.getMetadata();
        Activity.log(metadata, 'inactivity-modal', 'inactivity-timer', 'open', undefined);
        this.changeState({showInactivityModal: true});
    };

    closeInactivityModal = () => {
        this.changeState({showInactivityModal: false});
    };

    // CONFIG MODAL.

    openConfigModal = () => {
        this.changeState({showConfigModal: true});
    };

    closeConfigModal = () => {
        this.changeState({showConfigModal: false});
    };

    // SHOPPING CART.

    openShoppingCart = () => {
        this.changeState({showShoppingCart: true});
    };

    closeShoppingCart = () => {
        this.changeState({showShoppingCart: false});
    };

    createShoppingCart = () => {
        return new Promise((resolve, reject) => {
            try {
                Storage.removeShoppingCart();
                const id = `${this.state.client}-cart-${new Date().getTime()}`;
                const shoppingCart = new _ShoppingCart({id: id, dbId: undefined, client: this.state.client, items: []});
                Storage.setShoppingCart(shoppingCart);
                this.changeState({shoppingCart: shoppingCart}, resolve);
            } catch (error) {
                reject(error);
            }
        });
    };

    updateShoppingCart = (shoppingCart) => {
        return new Promise((resolve) => {
            this.changeState({shoppingCart: shoppingCart}, () => {
                Storage.setShoppingCart(shoppingCart);
                resolve();
            });
        });
    };

    deleteShoppingCart = () => {
        Storage.removeShoppingCart();
        this.changeState({shoppingCart: undefined});
    };

    addItemToCart = (item) => {
        const addItem = (item) => {
            return new Promise((resolve) => {
                const {shoppingCart} = this.state;
                shoppingCart.addItem(item);
                this.updateShoppingCart(shoppingCart).then(() => {resolve()});
            });
        };
        return new Promise((resolve) => {
            if (!this.state.shoppingCart) {
                this.createShoppingCart()
                    .then(() => {return addItem(item)})
                    .then(() => {resolve()});
            } else addItem(item).then(() => {resolve()});
        });
    };

    deleteItemFromCart = (id) => {
        const {shoppingCart} = this.state;
        shoppingCart.deleteItem(id);
        this.updateShoppingCart(shoppingCart);
    };

    // CSS COLORS.

    setCssDefaultColors = () => {
        document.documentElement.style.setProperty('--cross-loader-primary-color', '#111111');
        document.documentElement.style.setProperty('--cross-loader-secondary-color', '#111111');
        document.documentElement.style.setProperty('--modal-close-button-background-color', '#DE1C24');
        document.documentElement.style.setProperty('--modal-close-button-label-color', '#FFFFFF');
    };

    setCssCustomColors = () => {
        const {style} = this.state;
        const crossLoaderPrimaryColor = style && style['global'] && style['global']['mainLoader'] && style['global']['mainLoader']['primary'] ? style['global']['mainLoader']['primary'] : '#111111';
        const crossLoaderSecondaryColor = style && style['global'] && style['global']['mainLoader'] && style['global']['mainLoader']['secondary'] ? style['global']['mainLoader']['secondary'] : '#111111';
        const modalCloseButtonBackgroundColor = style && style['global'] && style['global']['modal'] && style['global']['modal']['closeButtonBackgroundColor'] ? style['global']['modal']['closeButtonBackgroundColor'] : '#DE1C24';
        const modalCloseButtonLabelColor = style && style['global'] && style['global']['modal'] && style['global']['modal']['closeButtonLabelColor'] ? style['global']['modal']['closeButtonLabelColor'] : '#FFFFFF';
        if (crossLoaderPrimaryColor) document.documentElement.style.setProperty('--cross-loader-primary-color', crossLoaderPrimaryColor);
        if (crossLoaderSecondaryColor) document.documentElement.style.setProperty('--cross-loader-secondary-color', crossLoaderSecondaryColor);
        if (modalCloseButtonBackgroundColor) document.documentElement.style.setProperty('--modal-close-button-background-color', modalCloseButtonBackgroundColor);
        if (modalCloseButtonLabelColor) document.documentElement.style.setProperty('--modal-close-button-label-color', modalCloseButtonLabelColor);
    };

    // SAVED CONFIG CONTROL.

    setConfig = (client, instanceId) => {
        this.changeState({client: client, instanceId: instanceId, isValidatingInstance: true, newConnection: true}, this.getInstance);
    };

    clearConfig = () => {
        this.unsubscribe();
        this.state.instance.setInactive();
        Storage.clearAll();
        this.closeConfigModal();
        this.resetZoomLevel();
        this.stopPromotionalVideoTimer();
        this.closeSession();
        this.deleteShoppingCart();
        this.onInstanceLoad(undefined, undefined);
        this.onCatalogLoad(undefined);
        this.onStoreLoad(undefined);
        this.onStyleLoad(undefined);
        this.setCssDefaultColors();
        Navigation.rewriteHome();
    };

    resetKiosk = (redirect) => {
        Storage.clearSession();
        this.disableAccessibilityMode();
        this.closeSession();
        this.createShoppingCart();
        if (redirect) {
            const url = Navigation.getHomeUrl();
            Navigation.forceRedirect(url);
        } else this.closeInactivityModal();
    };

    // SENTRY PLATFORM CONFIG.

    setSentryInstanceTag = () => {
        setTags([{key: 'instance', value: this.state.instanceId}]);
    };

    // ALGOLIA INSIGHTS INITIALIZATION.

    initAlgoliaInsights = () => {
        const {algoliaInsights} = this.state;
        if (!algoliaInsights) {
            const algoliaInsights = new AlgoliaInsights(Environment.current, this.state.client);
            algoliaInsights.init();
            this.changeState({algoliaInsights: algoliaInsights});
        }
    };

    // MAIN ATTRIBUTES PROPAGATION.

    getViewProperties = (showActionBar, actionBarMode, showAccessibilityButton, includeShoppingCart) => {
        return {
            client: this.state.client,
            instance: this.state.instance,
            catalog: this.state.catalog,
            store: this.state.store,
            session: this.state.session,
            style: this.state.style,
            showConfigModal: this.state.showConfigModal,
            zoomLevel: this.state.zoomLevel,
            actionBarMode: actionBarMode,
            showPromotionalVideo: this.state.showPromotionalVideo,
            showActionBar: showActionBar,
            showAccessibilityButton: showAccessibilityButton,
            accessibilityMode: this.state.accessibilityMode,
            showTopSpacer: this.state.accessibilityMode,
            showBottomSpacer: true,
            onAccessibilityButtonClick: this.toggleAccessibilityMode,
            startPromotionalVideoTimer: this.startPromotionalVideoTimer,
            stopPromotionalVideoTimer: this.stopPromotionalVideoTimer,
            resetPromotionalVideoTimer: this.resetPromotionalVideoTimer,
            showInactivityModal: this.state.showInactivityModal,
            openInactivityModal: this.openInactivityModal,
            closeInactivityModal: this.closeInactivityModal,
            resetKiosk: this.resetKiosk,
            shoppingCart: includeShoppingCart ? this.state.shoppingCart : undefined,
            showShoppingCart: includeShoppingCart ? this.state.showShoppingCart : undefined,
            openShoppingCart: includeShoppingCart ? this.openShoppingCart : undefined,
            closeShoppingCart: includeShoppingCart ? this.closeShoppingCart : undefined,
            createShoppingCart: includeShoppingCart ? this.createShoppingCart : undefined,
            updateShoppingCart: includeShoppingCart ? this.updateShoppingCart : undefined,
            addItemToCart: includeShoppingCart ? this.addItemToCart : undefined,
            deleteItemFromCart: includeShoppingCart ? this.deleteItemFromCart : undefined,
            openConfigModal: this.openConfigModal,
            closeConfigModal: this.closeConfigModal,
            changeZoomLevel: this.changeZoomLevel,
            onConfigClear: this.clearConfig,
            algoliaInsights: this.state.algoliaInsights
        };
    };

    // RENDERERS.

    renderVersionTag = () => {
        return (
            <div className='app-version-tag'>
                <VersionTag version={appData.version}/>
            </div>
        );
    };

    renderUpdateAlert = () => {
        const {showUpdateAlert} = this.state;
        return (
            <div className={`update-alert-container ${showUpdateAlert ? 'visible' : 'hidden'}`}>
                <UpdateAlert show={showUpdateAlert} onClose={this.closeUpdateAlert}/>
            </div>
        );
    };

    renderLoader = (message) => {
        return (
            <div className='app-loader'>
                <div className='app-loader-wrapper'>
                    <DataLoader message={message}/>
                </div>
            </div>
        );
    };

    renderError = (message) => {
        const {style} = this.state;
        const actionButtonBackgroundColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['backgroundColor'] ? style.global['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['borderColor'] ? style.global['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style.global && style.global['actionButton'] && style.global['actionButton']['labelColor'] ? style.global['actionButton']['labelColor'] : undefined;
        return (
            <div className='app-error'>
                <div className='app-error-wrapper'>
                    <DataError
                        message={message}
                        actionLabel='Reintentar'
                        action={Navigation.reload}
                        actionButtonLabelColor={actionButtonLabelColor}
                        actionButtonBackgroundColor={actionButtonBackgroundColor}
                        actionButtonBorderColor={actionButtonBorderColor}
                    />
                </div>
            </div>
        );
    };

    renderConfig = () => {
        return (
            <Config
                isValidating={this.state.isValidatingInstance}
                validationError={this.state.instanceError}
                onInputFocus={this.clearValidationError}
                onConfigChange={this.setConfig}
            />
        );
    };

    renderCategories = () => {
        const properties = this.getViewProperties(true, 'catalog', true, true);
        return (
            <View key='categories-view' {...properties}>
                <Categories/>
            </View>
        );
    };

    renderCategory = () => {
        const properties = this.getViewProperties(true, 'products-list', false, true);
        return (
            <View key='category-view' {...properties}>
                <Category/>
            </View>
        );
    };

    renderProduct = () => {
        const properties = this.getViewProperties(true, 'product', false, true);
        return (
            <View key='product-view' {...properties}>
                <Product/>
            </View>
        );
    };

    renderSearch = () => {
        const properties = this.getViewProperties(true, 'products-list', false, true);
        return (
            <View key='search-view' {...properties}>
                <Category/>
            </View>
        );
    };

    renderNotFound = () => {
        const properties = this.getViewProperties(false, undefined, false, false);
        return (
            <View key='not-found-view' {...properties}>
                <NotFound/>
            </View>
        );
    };

    renderRouter = () => {
        const promotionalVideo = this.getPromotionalVideo();
        const key = md5(promotionalVideo);
        return (
            <React.Fragment>
                <PromotionalVideo key={key} video={promotionalVideo} show={this.state.showPromotionalVideo}/>
                <Router>
                    <Routes>
                        <Route path='/' element={this.renderCategories()}/>
                        <Route path='/category/:categoryId' element={this.renderCategory()}/>
                        <Route path='/product/:productId' element={this.renderProduct()}/>
                        <Route path='/search/:searchQuery' element={this.renderSearch()}/>
                        <Route path='*' element={this.renderNotFound()}/>
                    </Routes>
                </Router>
            </React.Fragment>
        );
    };

    render() {
        return (
            <div className='app'>
                {SHOW_APP_VERSION && this.renderVersionTag()}
                {this.renderUpdateAlert()}
                {this.isLoadingConfig() && this.renderLoader('Obteniendo configuración del Kiosco...')}
                {this.configError() && this.renderConfig()}
                {this.configLoaded() && this.isLoadingAdditionalData() && this.renderLoader('Validando configuración del Kiosco...')}
                {this.configLoaded() && !this.isLoadingAdditionalData() && !this.additionalDataLoaded() && this.renderError('Se ha producido un error al obtener los datos del Kiosco')}
                {this.configLoaded() && !this.isLoadingAdditionalData() && this.additionalDataLoaded() &&  this.isLoadingSession() &&  this.renderLoader('Validando sesión del Kiosco...')}
                {this.configLoaded() && !this.isLoadingAdditionalData() && this.additionalDataLoaded() && !this.isLoadingSession() && !this.sessionLoaded() && this.renderError('Se ha producido un error al validar la sesión del Kiosco')}
                {this.configLoaded() && !this.isLoadingAdditionalData() && this.additionalDataLoaded() && !this.isLoadingSession() &&  this.sessionLoaded() && this.renderRouter()}
            </div>
        );
    };
}