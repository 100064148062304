// noinspection JSUnusedGlobalSymbols,JSUnresolvedReference

export class Catalog {
    constructor({id, name, banner, isVisible, categories, createdAt, updatedAt}) {
        this.id = id;
        this.name = name;
        this.banner = banner;
        this.categories = categories;
        this.isVisible = isVisible;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    };

    static catalogChanged(oldCatalog, newCatalog) {
        const oldCategories = oldCatalog ? (oldCatalog.categories || []) : [];
        const newCategories = newCatalog ? (newCatalog.categories || []) : [];
        let changed = oldCategories.length !== newCategories.length;
        if (!changed) {
            if (newCategories && newCategories.length > 0) {
                newCategories.forEach((newCategory) => {
                    if (!oldCategories.includes(newCategory)) changed = true;
                });
            }
        }
        return changed;
    };

    static inCatalog(catalog, categories) {
        let inCatalog = false;
        const catalogCategories = catalog.categories;
        for (let i = 0; i < categories.length; i++) {
            if (catalogCategories.includes(categories[i])) {
                inCatalog = true;
                break;
            }
        }
        return inCatalog;
    };

    clone() {
        return new Catalog({
            id: this.id,
            name: this.name,
            banner: this.banner,
            categories: this.categories,
            isVisible: this.isVisible,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        });
    };
}

export const catalogConverter = {
    toFirestore() {
        return null;
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return new Catalog({
            id: snapshot.id,
            name: data.name,
            banner: data.banner,
            categories: data.categories,
            isVisible: data.is_visible,
            createdAt: data.created_at,
            updatedAt: data.updated_at
        });
    }
};