import React from 'react';
import PaymentStepActions from '../PaymentStepActions/PaymentStepActions';
import PaymentOptionSelection from '../PaymentOptionSelection/PaymentOptionSelection';
import DataLoader from '../../../DataLoader/DataLoader';
import DataError from '../../../DataError/DataError';
import './PaymentMessage.css';

function PaymentMessage({
    isLoading,
    loaded,
    showPreview,
    preview,
    previewSize,
    title1,
    title2,
    loadingMessage,
    step1Message1,
    step1Message2,
    step1Image,
    showStep2,
    step2Message1,
    step2Message2,
    step2Image,
    customStepWidth,
    errorMessage,
    actionLabel,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    disableCloseButton,
    onClose,
    onSubmit,
    submitted,
    submitSuccess,
    submitSuccessMessage,
    submitErrorMessage
}) {
    const isLoadingData = () => {
        return isLoading;
    };

    const dataError = () => {
        return !isLoading && !loaded;
    };

    const dataLoaded = () => {
        return !isLoading && loaded;
    };

    const handleSubmit = () => {
        if (onSubmit) onSubmit();
    };

    const previewStyle = () => {
        if (dataLoaded()) {
            return {
                width: previewSize.width || 'auto',
                height: previewSize.height || 'auto',
                opacity: 1,
                margin: '0 auto 20px auto'
            };
        } else {
            return {
                width: 0,
                height: 0,
                opacity: 0,
                margin: 0
            };
        }
    };

    const renderLoader = () => {
        return (
            <div className='payment-message-status-container'>
                <DataLoader message={loadingMessage}/>
            </div>
        );
    }

    const renderError = () => {
        return (
            <div className='payment-message-status-container'>
                <DataError
                    message={errorMessage}
                    actionButtonBackgroundColor={actionButtonBackgroundColor}
                    actionButtonBorderColor={actionButtonBorderColor}
                    actionButtonLabelColor={actionButtonLabelColor}
                    actionLabel='Cerrar'
                    action={onClose}
                />
            </div>
        );
    };

    const renderMessage = () => {
        const options = [
            {
                type: 'step-1',
                icon: step1Image,
                label1: step1Message1,
                label2: step1Message2,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: false,
                borderRadius: '100%',
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                disabled: false,
                onClick: undefined
            }
        ];
        if (showStep2) {
            options.push({
                type: 'step-2',
                icon: step2Image,
                label1: step2Message1,
                label2: step2Message2,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: false,
                borderRadius: '100%',
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                disabled: false,
                onClick: undefined
            });
        }
        return (
            <PaymentOptionSelection
                title1={title1}
                title2={title2}
                titleLineBreak={true}
                showShadow={false}
                options={options}
                customOptionWidth={customStepWidth}
                cancelButtonBackgroundColor={undefined}
                cancelButtonBorderColor={undefined}
                cancelButtonLabelColor={undefined}
                backButtonBackgroundColor={undefined}
                backButtonBorderColor={undefined}
                backButtonLabelColor={undefined}
                padding={0}
                onClose={undefined}
                onBack={undefined}
            />
        );
    };

    const renderPreview = () => {
        return (
            <div className='payment-message-wrapper'>
                <div className='payment-message-preview' style={previewStyle()}>
                    {preview}
                </div>
            </div>
        );
    };

    const renderSubmitSuccessMessage = () => {
        return (
            <p className='payment-message-submit-message success'>{submitSuccessMessage}</p>
        );
    };

    const renderSubmitErrorMessage = () => {
        return (
            <p className='payment-message-submit-message error'>{submitErrorMessage}</p>
        );
    };

    return (
        <div className="payment-message">
            {isLoadingData() && renderLoader()}
            {dataError() && renderError()}
            {dataLoaded() && renderMessage()}
            {(isLoadingData() || dataLoaded()) && showPreview && renderPreview()}
            {submitted && submitSuccess && renderSubmitSuccessMessage()}
            {submitted && !submitSuccess && renderSubmitErrorMessage()}
            {dataLoaded() && (
                <PaymentStepActions
                    isLoading={false}
                    loadingLabel={undefined}
                    submitLabel={actionLabel}
                    cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                    cancelButtonBorderColor={cancelButtonBorderColor}
                    cancelButtonLabelColor={cancelButtonLabelColor}
                    backButtonBackgroundColor={undefined}
                    backButtonBorderColor={undefined}
                    backButtonLabelColor={undefined}
                    submitButtonBackgroundColor={actionButtonBackgroundColor}
                    submitButtonBorderColor={actionButtonBorderColor}
                    submitButtonLabelColor={actionButtonLabelColor}
                    disableCloseButton={disableCloseButton}
                    showCloseButton={dataLoaded() && !!onClose}
                    showBackButton={false}
                    showSubmitButton={!!onSubmit}
                    onClose={onClose}
                    onBack={undefined}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
}

export default PaymentMessage;