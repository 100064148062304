import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from '../../helpers/WithRouter';
import NotFound from '../../containers/NotFound/NotFound';
import DataLoader from '../../components/DataLoader/DataLoader';
import DataError from '../../components/DataError/DataError';
import Header from '../../components/Header/Header';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import CategoriesOptionsV2 from '../../components/CategoriesOptionsV2/CategoriesOptionsV2';
import ProductBox from '../../components/ProductBox/ProductBox';
import FiltersOptions from '../../components/FiltersOptions/FiltersOptions';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import Paginator from '../../components/Paginator/Paginator';
import FiltersModal from '../../components/FiltersModal/FiltersModal';
import Button from '../../components/Button/Button';
import ImageWrapper from '../../components/ImageWrapper/ImageWrapper';
import {QuickFilter as _QuickFilter} from '../../models/QuickFilter';
import {Filter as _Filter} from '../../models/Filter';
import {Product as _Product} from '../../models/Product';
import {Category as _Category} from '../../models/Category';
import {Catalog as _Catalog} from '../../models/Catalog';
import {categoryConverter} from '../../models/Category';
import {productConverter} from '../../models/Product';
import Navigation from '../../config/Navigation';
import Environment from '../../config/Environment';
import Storage from '../../helpers/Storage';
import Debug from '../../helpers/Debug';
import Activity from "../../helpers/Activity";
import Viewport from '../../helpers/Viewport';
import HTMLElement from '../../helpers/HTMLElement';
import Text from '../../helpers/Text';
import {PLP_ITEMS_PER_PAGE} from "../../config/App";
import {algoliaSearchClient, itemSearchSettings, buildVariantObject} from '../../config/AlgoliaSearch';
import {firestore, getCollectionPath} from '../../config/Firebase';
import {collection, doc, onSnapshot, query, where} from 'firebase/firestore';
import './Category.css';

class Category extends React.Component {
    constructor(props) {
        super(props);
        const parameters = new URLSearchParams(this.props.location.search);
        const categoryId = this.props.params.categoryId;
        const categories = parameters.getAll('categories');
        const brands = parameters.getAll('brands');
        const searchQuery = this.props.params.searchQuery;
        if (categories && categories.length > 0) {
            const storageCategories = {category: categoryId, categoryFilters: categories};
            Storage.setCategoryFilters(storageCategories);
        }
        if (brands && brands.length > 0) {
            const storageFilters = {category: categoryId, filters: [{id: 'brands-filter', options: brands.map((brand) => ({id: Text.toHash(brand), value: true}))}]};
            Storage.setFilters(storageFilters);
        }
        const isSearching = !!searchQuery;
        const mainOptions = [
            {label: 'Modelo 3D', value: '3d'},
            {label: 'Destacados', value: 'featured'},
            {label: 'Menor precio', value: 'lowest-price'},
            {label: 'Mayor precio', value: 'highest-price'}
        ];
        let sortOptions = [];
        if (isSearching) sortOptions.push({label: 'Relevancia', value: 'relevance'});
        sortOptions = sortOptions.concat(mainOptions);
        let sorting = this.getSorting(categoryId, searchQuery);
        sorting = !sorting && searchQuery ? 'relevance' : sorting;
        let match = sorting ? sortOptions.find(option => option.value === sorting) : undefined;
        this.state = {
            searchQuery: searchQuery,
            queryId: undefined,
            categoryId: categoryId,
            isLoadingCategory: true,
            categoryLoaded: false,
            category: undefined,
            isLoadingChildren: true,
            childrenLoaded: false,
            children: [],
            isLoadingProducts: true,
            productsLoaded: false,
            products: [],
            quickFilters: [],
            filters: [],
            showFilters: false,
            notFound: false,
            errorMessage: undefined,
            errorActionLabel: undefined,
            errorAction: undefined,
            selectedCategories: [],
            sortBy: match ? match.value : 'featured',
            sortOptions: sortOptions,
            page: 1,
            perPage: PLP_ITEMS_PER_PAGE,
            lastProduct: undefined,
            lastProductIndicatorDuration: 8, // Seconds
            scrolledToLastProduct: false,
            enableStickyBar: false,
            results: []
        };
        this._isMounted = true;
        this._unsubscribeCategory = undefined;
        this._unsubscribeChildren = undefined;
        this._unsubscribeProducts = undefined;
    };

    getMetadata = () => {
        const {queryId} = this.state;
        const {client, instance, catalog, store, session, algoliaInsights} = this.props;
        return {client: client, instance: instance, catalog: catalog, store: store, session: session, queryId: queryId, algoliaInsights: algoliaInsights};
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.setResizeListener();
        this.setScrollListener();
        if (this.state.searchQuery) {
            this.getSearchProducts();
        } else {
            this.getCategory();
            this.getCategoryChildren();
            this.getCategoryProducts();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const needsReload = _Catalog.catalogChanged(prevProps.catalog, this.props.catalog);
        if (needsReload) Navigation.reload();
        let oldStyle = prevProps.style;
        let newStyle = this.props.style;
        const oldQuickFilterExclusivePriceLabel = oldStyle && oldStyle['category'] ? oldStyle['category']['quickFilterExclusivePriceLabel'] : undefined;
        const oldShowExternalSale = oldStyle && oldStyle['global'] && oldStyle['global']['filters'] && oldStyle['global']['filters']['showExternalSale'] ? oldStyle['global']['filters']['showExternalSale'] : false;
        const oldExternalSaleLabel = oldStyle && oldStyle['global'] && oldStyle['global']['filters'] && oldStyle['global']['filters']['externalSaleLabel'] ? oldStyle['global']['filters']['externalSaleLabel'] : false;
        const oldClientName = oldStyle && oldStyle['global'] && oldStyle['global']['clientName'] ? oldStyle['global']['clientName'] : undefined;
        const newQuickFilterExclusivePriceLabel = newStyle && newStyle['category'] ? newStyle['category']['quickFilterExclusivePriceLabel'] : undefined;
        const newShowExternalSale = newStyle && newStyle['global'] && newStyle['global']['filters'] && newStyle['global']['filters']['showExternalSale'] ? newStyle['global']['filters']['showExternalSale'] : false;
        const newExternalSaleLabel = newStyle && newStyle['global'] && newStyle['global']['filters'] && newStyle['global']['filters']['externalSaleLabel'] ? newStyle['global']['filters']['externalSaleLabel'] : false;
        const newClientName = newStyle && newStyle['global'] && newStyle['global']['clientName'] ? newStyle['global']['clientName'] : undefined;
        const quickFilterExclusivePriceLabelChanged = oldQuickFilterExclusivePriceLabel !== newQuickFilterExclusivePriceLabel;
        const showExternalSaleChanged = oldShowExternalSale !== newShowExternalSale;
        const externalSaleLabelChanged = oldExternalSaleLabel !== newExternalSaleLabel;
        const clientNameChanged = oldClientName !== newClientName;
        if (quickFilterExclusivePriceLabelChanged) this.getQuickFilters();
        if (showExternalSaleChanged || externalSaleLabelChanged || clientNameChanged) {
            this.getFilters();
            if (showExternalSaleChanged) {
                setTimeout(() => {
                    this.updateResults();
                });
            }
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.unsubscribe();
    };

    setResizeListener = () => {
        window.addEventListener('resize', this.checkForStickyBar);
    };

    setScrollListener = () => {
        const view = document.getElementsByClassName('view')[0];
        view.addEventListener('scroll', this.checkForStickyBar);
    };

    checkForStickyBar = () => {
        let enableStickyBar = this.showStickyBar();
        if (enableStickyBar !== this.state.enableStickyBar) this.changeState({enableStickyBar: enableStickyBar});
    };

    showStickyBar = () => {
        if (!this.props.accessibilityMode) {
            const viewport = Viewport.dimensions;
            const view = document.getElementsByClassName('view')[0];
            if (!view) return false;
            return viewport.height >= 1000 ? view.scrollTop > 300 : false;
        } else return false;
    };

    getSearchProducts = () => {
        const {searchQuery} = this.state;
        const environment = Environment.current;
        const indexName = `${environment}_${this.props.client}_items`;
        const index = algoliaSearchClient.initIndex(indexName);
        const settings = itemSearchSettings(this.props.catalog, undefined);
        let queryId = undefined;
        let searchSorting = [];
        let products = [];
        let errorMessage = undefined;
        let errorActionLabel = undefined;
        let errorAction = undefined;
        index.search(searchQuery, settings)
            .then((response) => {
                queryId = response.queryID;
                if (response.hits.length === 0) {
                    errorMessage = 'No se han encontrado productos con el criterio de búsqueda especificado';
                    errorActionLabel = 'Ir al inicio';
                    errorAction = this.home;
                    throw new Error('No results');
                } else {
                    response.hits.forEach((hit) => {
                        const product = buildVariantObject(hit);
                        searchSorting.push(product.id);
                        products.push(product);
                    });
                }
            })
            .then(() => {
                const category = new _Category({id: 'search', name: `Buscando: ${searchQuery}`});
                this.changeState({
                    queryId: queryId,
                    searchSorting: searchSorting,
                    isLoadingCategory: false,
                    categoryLoaded: true,
                    category: category,
                    isLoadingChildren: false,
                    childrenLoaded: true,
                    children: [],
                    isLoadingProducts: false,
                    productsLoaded: true,
                    products: products,
                }, () => {
                    Storage.removeCategoryFilters();
                    this.getQuickFilters();
                    this.getFilters();
                    this.getPage();
                    this.getLastProduct();
                    this.saveSelectedSorting();
                    setTimeout(() => {
                        this.updateResults();
                    });
                });
            })
            .catch((error) => {
                Debug.printToLog('error', error);
                this.onLoadError(
                    false,
                    errorMessage || 'Se ha producido un error al realizar la búsqueda',
                    errorActionLabel || 'Reintentar',
                    errorAction || Navigation.reload
                );
            });
    };

    getCategory = () => {
        const _path = getCollectionPath(this.props.client, 'categories');
        const _doc = doc(firestore, _path, this.state.categoryId).withConverter(categoryConverter);
        this._unsubscribeCategory = onSnapshot(_doc, (_document) => {
            if (_document.exists()) {
                const category = _document.data();
                if (category.isEmpty) this.onLoadError(false, 'No se han encontrado productos en esta categoría', 'Volver', this.back);
                else if (!category.isVisible) this.onLoadError(true, undefined, 'Volver', this.back);
                else if (!_Catalog.inCatalog(this.props.catalog, category.parents)) this.onLoadError(true, undefined, 'Volver', this.back);
                else this.changeState({isLoadingCategory: false, categoryLoaded: true, category: category});
            } else this.onLoadError(true, undefined, 'Volver', this.back);
        }, (error) => {
            Debug.printToLog('error', error);
            this.onLoadError(false, 'Se ha producido un error al cargar la categoría', 'Reintentar', Navigation.reload);
        });
    };

    getCategoryChildren = () => {
        const _path = getCollectionPath(this.props.client, 'categories');
        const _collection = collection(firestore, _path);
        const _where1 = where('parents', 'array-contains', this.state.categoryId);
        const _where2 = where('is_empty', '==', false);
        const _where3 = where('is_visible', '==', true);
        const _query = query(_collection, _where1, _where2, _where3).withConverter(categoryConverter);
        this._unsubscribeChildren = onSnapshot(_query, (_snapshot) => {
            let {children} = this.state;
            _snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    children.push(change.doc.data());
                } else if (change.type === 'modified') {
                    const index = children.findIndex(child => child.id === change.doc.id);
                    if (index >= 0) children[index] = change.doc.data();
                } else if (change.type === 'removed') {
                    const index = children.findIndex(child => child.id === change.doc.id);
                    if (index >= 0) children.splice(index, 1);
                }
            });
            this.changeState({isLoadingChildren: false, childrenLoaded: true, children: children}, () => {
                this.getCategoryFilters();
            });
        }, (error) => {
            Debug.printToLog('error', error);
            this.onLoadError(false, 'Se ha producido un error al cargar las subcategorías disponibles', 'Reintentar', Navigation.reload);
        });
    };

    getCategoryProducts = () => {
        const _path = getCollectionPath(this.props.client, 'items');
        const _collection = collection(firestore, _path);
        const _where1 = where('categories', 'array-contains', this.state.categoryId);
        const _query = query(_collection, _where1).withConverter(productConverter);
        this._unsubscribeProducts = onSnapshot(_query, (_snapshot) => {
            let {products} = this.state;
            _snapshot.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    products.push(change.doc.data());
                } else if (change.type === 'modified') {
                    const index = products.findIndex(product => product.id === change.doc.id);
                    if (index >= 0) products[index] = change.doc.data();
                } else if (change.type === 'removed') {
                    const index = products.findIndex(product => product.id === change.doc.id);
                    if (index >= 0) products.splice(index, 1);
                }
            });
            this.changeState({isLoadingProducts: false, productsLoaded: true, products: products}, () => {
                this.getQuickFilters();
                this.getFilters();
                this.getPage();
                this.getLastProduct();
                this.saveSelectedSorting();
                setTimeout(() => {
                    this.updateResults();
                });
            });
        }, (error) => {
            Debug.printToLog('error', error);
            this.onLoadError(false, 'Se ha producido un error al obtener los productos de la categoría.', 'Reintentar', Navigation.reload);
        });
    };

    getCategoryFilters = () => {
        const {categoryId, children} = this.state;
        const data = Storage.getCategoryFilters();
        if (data && data.category === categoryId) {
            let validCategoryFilters = [];
            data.categoryFilters.forEach((categoryFilter) => {
                const match = children.find(child => child.id === categoryFilter);
                if (match) validCategoryFilters.push(categoryFilter);
            });
            this.changeState({selectedCategories: validCategoryFilters});
        } else Storage.removeCategoryFilters();
    };

    getQuickFilters = () => {
        const {style} = this.props;
        const {categoryId, searchQuery} = this.state;
        const quickFilterExclusivePriceLabel = style && style['category'] ? style['category']['quickFilterExclusivePriceLabel'] : undefined;
        let quickFilters = this.buildQuickFilters(quickFilterExclusivePriceLabel);
        quickFilters = _QuickFilter.fromStorage(categoryId, searchQuery, quickFilters);
        this.changeState({quickFilters: quickFilters});
    };

    getFilters = () => {
        const {style} = this.props;
        const {categoryId, searchQuery, products} = this.state;
        const showExternalSale = style && style['global'] && style['global']['filters'] && style['global']['filters']['showExternalSale'] ? style['global']['filters']['showExternalSale'] : false;
        const externalSaleLabel = style && style['global'] && style['global']['filters'] && style['global']['filters']['externalSaleLabel'] ? style['global']['filters']['externalSaleLabel'] : false;
        const clientName = style && style['global'] && style['global']['clientName'] ? style['global']['clientName'] : undefined;
        let validProducts = _Product.filterValidProducts(products);
        let filters = _Filter.buildFilters(validProducts, showExternalSale, externalSaleLabel, clientName);
        filters = _Filter.fromStorage(categoryId, searchQuery, filters);
        this.changeState({filters: filters});
    };

    getSorting = (categoryId, searchQuery) => {
        const storageData = Storage.getSorting();
        const storageType = storageData ? storageData['type'] : undefined;
        const storageKey = storageData ? storageData['key'] : undefined;
        const storageSortBy = storageData ? storageData['sortBy'] : undefined;
        let isCategory = storageType === 'category' && categoryId && storageKey === categoryId;
        let isSearch = storageType === 'search' && !categoryId && storageKey === searchQuery;
        if (isCategory || isSearch) return storageSortBy;
        else return undefined;
    };

    getPage = () => {
        const {categoryId, searchQuery} = this.state;
        const data = Storage.getPage();
        let reset = false;
        if (data) {
            if (data.type === 'category' && categoryId && data.key === categoryId) {
                this.changeState({page: data.page});
            } else if (data.type === 'search' && !categoryId && data.key === searchQuery) {
                this.changeState({page: data.page});
            } else reset = true;
        } else reset = true;
        if (reset) this.changeState({page: 1}, this.saveSelectedPage);
    }

    getLastProduct = () => {
        const {categoryId, searchQuery, lastProductIndicatorDuration, scrolledToLastProduct} = this.state;
        const data = Storage.getPage();
        if (data) {
            const sameCategory = data.type === 'category' && categoryId && data.key === categoryId;
            const sameSearch = data.type === 'search' && !categoryId && data.key === searchQuery;
            const shouldScroll = (sameCategory || sameSearch) && !scrolledToLastProduct;
            if (shouldScroll) {
                setTimeout(() => {
                    const lastProduct = Storage.getLastProduct();
                    const element = document.getElementById(lastProduct);
                    const inViewport = HTMLElement.isElementInViewport(element);
                    if (element && !inViewport) {
                        element.scrollIntoView({behavior: 'instant', block: 'start'}); // When the scroll happens, the sticky bar changes the structure of the page resulting in an imperfect center scroll, so this first scroll is to trigger the sticky bar to show if the conditions are met.
                        setTimeout(() => {
                            element.scrollIntoView({behavior: 'instant', block: 'center'}); // And this second scroll is to center the element perfectly once the sticky bar is shown.
                        }, 100);
                    }
                    this.changeState({lastProduct: lastProduct, scrolledToLastProduct: true}, () => {
                        setTimeout(() => {
                            this.changeState({lastProduct: undefined});
                        }, lastProductIndicatorDuration * 1000);
                    });
                }, 100);
            } else Storage.removeLastProduct();
        }
    };

    getValidChildren = () => {
        const {children, products} = this.state;
        const validProducts = _Product.filterValidProducts(products);
        let validChildren = [];
        if (children && children.length && validProducts && validProducts.length > 0) {
            children.forEach((child) => {
                const match = validProducts.find((product) => product.categories.includes(child.id));
                if (match) validChildren.push(child);
            });
            if (validChildren.length > 1) return validChildren;
            else return [];
        } else return validChildren;
    };

    showChildren = () => {
        const validChildren = this.getValidChildren();
        return validChildren && validChildren.length > 0;
    };

    unsubscribe = () => {
        if (this._unsubscribeCategory) this._unsubscribeCategory();
        if (this._unsubscribeChildren) this._unsubscribeChildren();
        if (this._unsubscribeProducts) this._unsubscribeProducts();
    };

    loadingData = () => {
        return this.state.isLoadingCategory || this.state.isLoadingChildren || this.state.isLoadingProducts;
    };

    dataLoaded = () => {
        return this.state.categoryLoaded && this.state.childrenLoaded && this.state.productsLoaded;
    };

    onLoadError = (notFound, message, actionLabel, action) => {
        this.changeState({
            isLoadingCategory: false,
            categoryLoaded: false,
            category: undefined,
            isLoadingChildren: false,
            childrenLoaded: false,
            children: [],
            isLoadingProducts: false,
            productsLoaded: false,
            products: [],
            filters: [],
            notFound: notFound,
            errorMessage: message,
            errorActionLabel: actionLabel,
            errorAction: action
        });
    };

    home = () => {
        const url = Navigation.getHomeUrl();
        this.props.navigate(url);
    };

    back = () => {
        this.props.navigate(-1);
    };

    buildQuickFilters(exclusivePriceLabel) {
        const {products} = this.state;
        const validProducts = _Product.filterValidProducts(products);
        const anyExclusivePrices = _Product.filterByExclusivePrice(validProducts).length > 0;
        const anyDiscounts = _Product.filterByDiscounts(validProducts).length > 0;
        const anyFreeDelivery = _Product.filterByFreeDelivery(validProducts).length > 0;
        const anyPickup = _Product.filterByPickup(validProducts).length > 0;
        const filters = [];
        if (anyExclusivePrices) filters.push(new _QuickFilter({id: 'exclusive-price', label: exclusivePriceLabel || 'Precio exclusivo', selected: false, action: _Product.filterByExclusivePrice}))
        if (anyDiscounts)       filters.push(new _QuickFilter({id: 'discounts', label: 'Descuentos', selected: false, action: _Product.filterByDiscounts}))
        if (anyFreeDelivery)    filters.push(new _QuickFilter({id: 'free-delivery', label: 'Despacho gratis', selected: false, action: _Product.filterByFreeDelivery}))
        if (anyPickup)          filters.push(new _QuickFilter({id: 'pickup', label: 'Retiro en tienda', selected: false, action: _Product.filterByPickup}))
        return filters;
    };

    toggleQuickFilter = (quickFilterId) => {
        const {quickFilters} = this.state;
        const matchIndex = quickFilters.findIndex((quickFilter) => quickFilter.id === quickFilterId);
        if (matchIndex >= 0) {
            quickFilters[matchIndex].toggle();
            this.changeState({quickFilters: quickFilters, page: 1}, this.onSelectedQuickFiltersChange);
        }
    };

    onSelectedQuickFiltersChange = () => {
        this.saveSelectedQuickFilters();
        this.saveSelectedPage();
        this.updateResults();
    };

    saveSelectedQuickFilters = () => {
        const {categoryId, searchQuery, quickFilters} = this.state;
        const type = categoryId ? 'category' : 'search';
        const key = categoryId ? categoryId : searchQuery;
        Storage.setQuickFilters({type: type, key: key, category: categoryId, quickFilters: quickFilters});
    };

    changeCategoryFilters = (selectedCategories) => {
        this.changeState({selectedCategories: selectedCategories, page: 1}, this.onSelectedCategoryFiltersChange);
    };

    clearCategoryFilters = () => {
        this.changeState({selectedCategories: [], page: 1}, this.onSelectedCategoryFiltersChange);
    };

    onSelectedCategoryFiltersChange = () => {
        this.saveSelectedCategoryFilters();
        this.saveSelectedPage();
        this.updateResults();
    };

    saveSelectedCategoryFilters = () => {
        const {categoryId, selectedCategories} = this.state;
        Storage.setCategoryFilters({category: categoryId, categoryFilters: selectedCategories});
    };

    openFilters = () => {
        this.changeState({showFilters: true});
    };

    closeFilters = () => {
        this.changeState({showFilters: false});
    };

    changeFilters = (filterId, optionId, values) => {
        const newFilters = [...this.state.filters];
        const filter = newFilters.find(filter => filter.id === filterId);
        if (filter) {
            filter.animate = false;
            const option = filter.options.find(option => option.id === optionId);
            switch (filter.type) {
                case 'size':
                    option.setValue(values[0]);
                    break;
                case 'price':
                    option.setStartValue(values[0]);
                    option.setEndValue(values[1]);
                    break;
                default:
                    option.toggle();
                    break;
            }
            this.changeState({filters: newFilters, page: 1}, this.onSelectedFiltersChange);
        }
    };

    removeFilter = (filterId, optionId) => {
        const newFilters = [...this.state.filters];
        const filter = newFilters.find((filter) => filter.id === filterId);
        if (!filter) return;
        const option = filter.options.find((option) => option.id === optionId);
        if (option < 0) return;
        option.deselect();
        this.changeState({filters: newFilters, page: 1}, this.onSelectedFiltersChange);
    };

    clearFilters = () => {
        const newFilters = [...this.state.filters];
        newFilters.forEach((filter) => {
            filter.options.forEach((option) => {
                option.deselect();
            });
        });
        this.changeState({filters: newFilters, page: 1}, this.onSelectedFiltersChange);
    };

    onSelectedFiltersChange = () => {
        this.saveSelectedFilters();
        this.saveSelectedPage();
        this.updateResults();
    };

    saveSelectedFilters = () => {
        const {categoryId, searchQuery, filters} = this.state;
        const type = categoryId ? 'category' : 'search';
        const key = categoryId ? categoryId : searchQuery;
        const selectedFilters = _Filter.getSelectedFilters(filters);
        const storageFilters = _Filter.toStorage(type, key, selectedFilters);
        Storage.setFilters(storageFilters);
    };

    hasFilters = () => {
        const {products, filters} = this.state;
        return products.length > 1 && filters.length > 0;
    };

    getSelectedFiltersCount = () => {
        let selectedFiltersCount = 0;
        _Filter.getSelectedFilters(this.state.filters).forEach((filter) => {selectedFiltersCount += filter.options.length});
        return selectedFiltersCount;
    };

    clearAllFilters = () => {
        this.clearCategoryFilters();
        this.clearFilters();
    };

    changeSorting = (sortBy) => {
        const {category, searchQuery} = this.state;
        const metadata = this.getMetadata();
        Activity.log(metadata, 'sort-select', sortBy, 'sort', {category_id: category.id, category_name: category.name, search_query: searchQuery || null});
        this.changeState({sortBy: sortBy, page: 1}, this.onSelectedSortingChange);
    };

    onSelectedSortingChange = () => {
        this.saveSelectedSorting();
        this.saveSelectedPage();
        this.updateResults();
    };

    saveSelectedSorting = () => {
        const {categoryId, searchQuery, sortBy} = this.state;
        const type = categoryId ? 'category' : 'search';
        const key = categoryId ? categoryId : searchQuery;
        Storage.setSorting({type: type, key: key, sortBy: sortBy});
    };

    changePage = (type, page) => {
        const {category, searchQuery} = this.state;
        const metadata = this.getMetadata();
        const entityId = type ? `${type}-page` : `page-${page}`;
        Activity.log(metadata, 'paginator', entityId, 'change-page', {category_id: category.id, category_name: category.name, search_query: searchQuery || null, target_page: page});
        this.changeState({page: page}, this.onSelectedPageChange);
    };

    onSelectedPageChange = () => {
        this.saveSelectedPage();
        this.updateResults();
    };

    saveSelectedPage = () => {
        const {categoryId} = this.state;
        const type = categoryId ? 'category' : 'search';
        const key = categoryId ? categoryId : this.state.searchQuery;
        Storage.setPage({type: type, key: key, page: this.state.page});
    };

    filteredProducts = (selectedFilters, products) => {
        let response = [];
        const {selectedCategories} = this.state;
        const {style} = this.props;
        const showExternalSale = style && style['global'] && style['global']['filters'] && style['global']['filters']['showExternalSale'] ? style['global']['filters']['showExternalSale'] : false;
        const externalSaleLabel = style && style['global'] && style['global']['filters'] && style['global']['filters']['externalSaleLabel'] ? style['global']['filters']['externalSaleLabel'] : false;
        const clientName = style && style['global'] && style['global']['clientName'] ? style['global']['clientName'] : undefined;
        const hasSelectedCategories = selectedCategories && selectedCategories.length > 0;
        const hasSelectedFilters = selectedFilters && selectedFilters.length > 0;
        if (!hasSelectedCategories && !hasSelectedFilters) return products;
        const byCategories = hasSelectedCategories ? _Product.filterByCategories(products, selectedCategories) : [];
        const byFilters = hasSelectedFilters ? _Product.filterByFilters(products, selectedFilters, showExternalSale, externalSaleLabel, clientName) : [];
        response = hasSelectedCategories ? byCategories : products;
        response = hasSelectedCategories ? (hasSelectedFilters ? response.filter(product => byFilters.includes(product)) : response) : byFilters;
        return response;
    };

    quickFilteredProducts = (products) => {
        const {quickFilters} = this.state;
        let results = products;
        quickFilters.forEach((quickFilter) => {
            if (quickFilter.selected && quickFilter.action) results = quickFilter.action(results);
        });
        return results;
    };

    sortedProducts = (products) => {
        const {sortBy, searchSorting} = this.state;
        switch (sortBy) {
            case '3d':
                return _Product.sortBy3dModel(products);
            case 'featured':
                return _Product.sortByFeatured(products);
            case 'lowest-price':
                return _Product.sortByLowestPrice(products);
            case 'highest-price':
                return _Product.sortByHighestPrice(products);
            case 'relevance':
                return _Product.sortByRelevance(products, searchSorting);
            default:
                return products;
        }
    };

    updateResults = () => {
        const {products, filters} = this.state;
        const valid = _Product.filterValidProducts(products);
        const selectedFilters = _Filter.getSelectedFilters(filters);
        const filtered = this.filteredProducts(selectedFilters, valid);
        const quickFiltered = this.quickFilteredProducts(filtered);
        const sorted = this.sortedProducts(quickFiltered);
        this.changeState({results: sorted});
    };

    onProductClick = (category, product, index) => {
        const metadata = this.getMetadata();
        const algoliaInsights = metadata.algoliaInsights;
        const algoliaIndex = index >= 0 ? index + 1 : undefined;
        const productId = product.parent ? product.parent : product.id;
        const variantId = product.parent ? product.id : null;
        Activity.log(metadata, 'product', productId, 'click', {
            from: 'catalog',
            category_id: category ? category.id : null,
            category_name: category ? category.name : null,
            product_id: productId,
            product_name: product.name,
            variant_id: variantId,
            has_3d_model: product.has3dModel || false
        });
        if (metadata && metadata.session && metadata.session.id) {
            if (metadata.queryId && algoliaIndex) algoliaInsights.clickedObjectIDsAfterSearch(metadata.session.id, metadata.queryId, [productId], [algoliaIndex]);
            else algoliaInsights.clickedObjectIDs(metadata.session.id, [productId]);
        }
        Storage.setLastProduct(productId);
        const url = Navigation.getProductUrl(productId, variantId, metadata.queryId);
        this.props.navigate(url);
    };

    contentWrapperStyle = () => {
        return {
            padding: this.props.inSmallMode ? '40px 0' : '40px 0 40px 90px'
        };
    };

    productsListStyle = (selectedFilters) => {
        const inSmallMode = this.props.inSmallMode;
        const hasChildren = this.state.children.length > 0 && this.state.products.length > 1;
        const hasFilters = selectedFilters.length > 0;
        let heightUnit = 200;
        let multiplier = 0;
        if (!hasChildren && !hasFilters) multiplier = 3;
        if (hasChildren && !hasFilters) multiplier = 6;
        if (!hasChildren && hasFilters) multiplier = 5;
        if (hasChildren && hasFilters) multiplier = 7;
        if (inSmallMode) multiplier += 1;
        return {
            minHeight: `calc(100vh - ${heightUnit * multiplier}px)`
        };
    };

    renderHeader = () => {
        const {style} = this.props;
        const {category, products} = this.state;
        const validProducts = _Product.filterValidProducts(products);
        const clientLogo = style && style['global'] ? style['global']['secondaryClientLogo'] : undefined;
        const titleBarTopSectionBackgroundColor = style && style['category'] ? style['category']['titleBarTopSectionBackgroundColor'] : undefined;
        const titleBarBottomSectionBackgroundColor = style && style['category'] ? style['category']['titleBarBottomSectionBackgroundColor'] : undefined;
        const titleBarBottomSectionLabelColor = style && style['category'] ? style['category']['titleBarBottomSectionLabelColor'] : undefined;
        return (
            <Header
                clientLogo={clientLogo}
                category={category}
                productsQuantity={validProducts.length || 0}
                clientLogoBackgroundColor={titleBarTopSectionBackgroundColor}
                categoryBackgroundColor={titleBarBottomSectionBackgroundColor}
                categoryLabelColor={titleBarBottomSectionLabelColor}
            />
        );
    };

    renderStickyBar = (quickFilters, selectedFilters) => {
        const viewport = Viewport.dimensions;
        const showSectionTitle = !!this.state.searchQuery || this.showChildren();
        const showLogo = viewport.width >= 768;
        const clientLogo = this.props.style && this.props.style['global'] ? this.props.style['global']['mainClientLogo'] : undefined;
        return (
            <div className={`category-view-sticky-bar ${this.state.enableStickyBar ? 'enabled' : ''}`}>
                <div className='category-view-section-title-wrapper' style={{justifyContent: showSectionTitle ? "space-between" : "center"}}>
                    {showSectionTitle && this.renderSectionTitle()}
                    {this.state.enableStickyBar && clientLogo && showLogo && (
                        <div className='category-view-section-title-wrapper-sticky-bar-image'>
                            <ImageWrapper image={clientLogo} alt='Logo' display='block' width={170} height='auto' loadDelay={0}/>
                        </div>
                    )}
                </div>
                {this.showChildren() && (
                    <div className='category-view-categories-options-container'>
                        {this.renderChildren()}
                    </div>
                )}
                {this.hasFilters() && (
                    <div className='category-view-actions-container'>
                        <div className='category-view-filter-button-container'>
                            {this.renderFiltersButton()}
                        </div>
                        <div className='category-view-sort-select-container'>
                            {this.renderSortOptions()}
                        </div>
                    </div>
                )}
                {((quickFilters && quickFilters.length > 0) || (selectedFilters && selectedFilters.length > 0)) && (
                    <div className='category-view-filters-options-container'>
                        {this.renderFilterOptions(quickFilters, selectedFilters)}
                    </div>
                )}
            </div>
        );
    };

    renderSectionTitle = () => {
        const {searchQuery, products} = this.state;
        let title;
        let subtitle;
        if (searchQuery) {
            title = 'Resultados';
            subtitle = `${products.length} producto(s)`
        } else {
            title = '¡Selecciona lo que buscas!';
            subtitle = 'Puedes seleccionar múltiples opciones';
        }
        return (
            <div className='category-view-section-title-container'>
                <SectionTitle title={title} subtitle={subtitle}/>
            </div>
        );
    };

    renderFiltersButton = () => {
        const {style} = this.props;
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        const filterButtonIcon = style && style['category'] && style['category']['filterButtonIcon'] ? style['category']['filterButtonIcon'] : undefined;
        const filterButtonIndicatorBackgroundColor = style && style['category'] && style['category']['filterButtonIndicatorBackgroundColor'] ? style['category']['filterButtonIndicatorBackgroundColor'] : undefined;
        const filterButtonIndicatorLabelColor = style && style['category'] && style['category']['filterButtonIndicatorBackgroundColor'] ? style['category']['filterButtonIndicatorLabelColor'] : undefined;
        return (
            <Button
                icon={filterButtonIcon}
                label='Filtros'
                labelColor={actionButtonLabelColor}
                backgroundColor={actionButtonBackgroundColor}
                borderColor={actionButtonBorderColor}
                borderRadius={12}
                notificationNumber={this.getSelectedFiltersCount()}
                notificationBackgroundColor={filterButtonIndicatorBackgroundColor}
                notificationLabelColor={filterButtonIndicatorLabelColor}
                onClick={this.openFilters}
            />
        );
    };

    renderSortOptions = () => {
        const {style} = this.props;
        const sortSelectionHoverColor = style && style['category'] ? style['category']['sortSelectionHoverColor'] : undefined;
        const sortSelectionAccentColor = style && style['category'] ? style['category']['sortSelectionAccentColor'] : undefined;
        const sortOptionHoverColor = style && style['category'] ? style['category']['sortOptionHoverColor'] : undefined;
        const sortOptionHoverLabelColor = style && style['category'] ? style['category']['sortOptionHoverLabelColor'] : undefined;
        const enabledSortOptions = style && style['category'] ? style['category']['enabledSortOptions'] : undefined;
        let validOptions = [];
        if (!enabledSortOptions) validOptions = this.state.sortOptions;
        else {
            this.state.sortOptions.forEach((option) => {
                if (enabledSortOptions.includes(option.value)) validOptions.push(option);
            });
        }
        return (
            <CustomSelect
                id='category-sort-options'
                label={undefined}
                prefix='Ordenar por'
                placeholder='Ordenamiento'
                value={this.state.sortBy}
                options={validOptions}
                showValueInLabel={false}
                formatLabel={false}
                allowSearch={false}
                selectionHoverColor={sortSelectionHoverColor}
                selectionAccentColor={sortSelectionAccentColor}
                optionHoverColor={sortOptionHoverColor}
                optionHoverLabelColor={sortOptionHoverLabelColor}
                onClick={undefined}
                onChange={this.changeSorting}
            />
        );
    };

    renderChildren = () => {
        const {style} = this.props;
        const {searchQuery, category, selectedCategories} = this.state;
        const children = this.getValidChildren();
        const subcategorySelectionLabelColor = style && style['category'] && style['category']['subcategorySelectionActiveColor'] ? style['category']['subcategorySelectionActiveColor'] : undefined;
        const sorted = children.sort((a, b) => a.order - b.order);
        return (
            <div className='category-view-categories-options-container'>
                <CategoriesOptionsV2 parent={category} searchQuery={searchQuery} categories={sorted} selectedCategories={selectedCategories} activeColor={subcategorySelectionLabelColor} pulseDuration={8} onClick={this.changeCategoryFilters} metadata={this.getMetadata()}/>
            </div>
        );
    };

    renderFilterOptions = (quickFilters, selectedFilters) => {
        const {style} = this.props;
        const {category, searchQuery} = this.state;
        const quickFilterLabelColor = style && style['category'] ? style['category']['quickFilterLabelColor'] : undefined;
        const quickFilterBackgroundColor = style && style['category'] ? style['category']['quickFilterBackgroundColor'] : undefined;
        const quickFilterCheckboxBackgroundColor = style && style['category'] ? style['category']['quickFilterCheckboxBackgroundColor'] : undefined;
        const filterOptionsLabelColor = style && style['category'] ? style['category']['filterOptionsLabelColor'] : undefined;
        const filterOptionsBackgroundColor = style && style['category'] ? style['category']['filterOptionsBackgroundColor'] : undefined;
        return (
            <FiltersOptions
                category={category}
                searchQuery={searchQuery}
                quickFilters={quickFilters}
                filters={selectedFilters}
                quickFilterLabelColor={quickFilterLabelColor}
                quickFilterBackgroundColor={quickFilterBackgroundColor}
                quickFilterCheckboxBackgroundColor={quickFilterCheckboxBackgroundColor}
                filterLabelColor={filterOptionsLabelColor}
                filterBackgroundColor={filterOptionsBackgroundColor}
                onToggle={this.toggleQuickFilter}
                onDelete={this.removeFilter}
                metadata={this.getMetadata()}
            />
        );
    };

    renderPaginator = (page, totalPages, margin) => {
        const {style} = this.props;
        const paginatorActiveItemBackgroundColor = style && style['category'] ? style['category']['paginatorActiveItemBackgroundColor'] : undefined;
        const paginatorActiveItemLabelColor = style && style['category'] ? style['category']['paginatorActiveItemLabelColor'] : undefined;
        return (
            <div className='category-view-products-list-paginator' style={{margin: margin}}>
                <Paginator
                    action={this.changePage}
                    currentPage={page}
                    totalPages={totalPages}
                    activeItemBackgroundColor={paginatorActiveItemBackgroundColor}
                    activeItemLabelColor={paginatorActiveItemLabelColor}
                />
            </div>
        );
    };

    renderProducts = (selectedFilters) => {
        const {style, inSmallMode} = this.props;
        const {category, page, perPage, selectedCategories, lastProduct, results} = this.state;
        const clientName = style && style['global'] && style['global']['clientName'] ? style['global']['clientName'] : undefined;
        const productBoxDesign = style && style['category'] && style['category']['productBoxDesign'] ? style['category']['productBoxDesign'] : undefined;
        const _3dIcon = style && style['category'] && style['category']['3dIcon'] ? style['category']['3dIcon'] : undefined;
        const featureIcon = style && style['category'] && style['category']['featureIcon'] ? style['category']['featureIcon'] : undefined;
        const discountBackgroundColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountBackgroundColor'] ? style['global']['prices']['discountBackgroundColor'] : undefined;
        const discountLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['discountLabelColor'] ? style['global']['prices']['discountLabelColor'] : undefined;
        const exclusivePriceLabelColor = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceLabelColor'] ? style['global']['prices']['exclusivePriceLabelColor'] : undefined;
        const exclusivePriceIcon = style && style['global'] && style['global']['prices'] && style['global']['prices']['exclusivePriceIcon'] ? style['global']['prices']['exclusivePriceIcon'] : undefined;
        const offerBackgroundColor = style && style['global'] && style['global']['offers'] && style['global']['offers']['backgroundColor'] ? style['global']['offers']['backgroundColor'] : undefined;
        const offerLabelColor      = style && style['global'] && style['global']['offers'] && style['global']['offers']['labelColor'] ? style['global']['offers']['labelColor'] : undefined;
        const freeDeliveryIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['icon'] ? style['global']['tags']['freeDelivery']['icon'] : undefined;
        const freeDeliveryLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['labelColor'] ? style['global']['tags']['freeDelivery']['labelColor'] : undefined;
        const freeDeliveryBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['freeDelivery'] && style['global']['tags']['freeDelivery']['backgroundColor'] ? style['global']['tags']['freeDelivery']['backgroundColor'] : undefined;
        const deliveryIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['icon'] ? style['global']['tags']['delivery']['icon'] : undefined;
        const deliveryLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['labelColor'] ? style['global']['tags']['delivery']['labelColor'] : undefined;
        const deliveryBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['delivery'] && style['global']['tags']['delivery']['backgroundColor'] ? style['global']['tags']['delivery']['backgroundColor'] : undefined;
        const pickupIcon = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['icon'] ? style['global']['tags']['pickup']['icon'] : undefined;
        const pickupLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['labelColor'] ? style['global']['tags']['pickup']['labelColor'] : undefined;
        const pickupBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['pickup'] && style['global']['tags']['pickup']['backgroundColor'] ? style['global']['tags']['pickup']['backgroundColor'] : undefined;
        const externalSaleBorderColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['externalSale'] && style['global']['tags']['externalSale']['borderColor'] ? style['global']['tags']['externalSale']['borderColor'] : undefined;
        const externalSaleBackgroundColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['externalSale'] && style['global']['tags']['externalSale']['backgroundColor'] ? style['global']['tags']['externalSale']['backgroundColor'] : undefined;
        const externalSaleLabelColor = style && style['global'] && style['global']['tags'] && style['global']['tags']['externalSale'] && style['global']['tags']['externalSale']['labelColor'] ? style['global']['tags']['externalSale']['labelColor'] : undefined;
        const sellerLabelColor = style && style['global'] && style['global']['seller'] && style['global']['seller']['labelColor'] ? style['global']['seller']['labelColor'] : undefined;
        const mainSellerIcon = style && style['global'] && style['global']['seller'] && style['global']['seller']['mainIcon'] ? style['global']['seller']['mainIcon'] : undefined;
        const sliced = results.slice((page - 1) * perPage, page * perPage);
        const hasCategoryFilters = selectedCategories.length > 0;
        const hasNormalFilters = selectedFilters.length > 0;
        if (sliced && sliced.length > 0) {
            const elements = sliced.map((product, index) => (
                <ProductBox
                    key={`product-${product.id}`}
                    index={((page - 1) * perPage) + index}
                    category={category}
                    product={product}
                    showSku={true}
                    showIcons={true}
                    active={lastProduct === product.id}
                    clientName={clientName}
                    design={productBoxDesign}
                    _3dIcon={_3dIcon}
                    featuredIcon={featureIcon}
                    discountBackgroundColor={discountBackgroundColor}
                    discountLabelColor={discountLabelColor}
                    exclusivePriceLabelColor={exclusivePriceLabelColor}
                    exclusivePriceIcon={exclusivePriceIcon}
                    offerBackgroundColor={offerBackgroundColor}
                    offerLabelColor={offerLabelColor}
                    showTags={true}
                    freeDeliveryIcon={freeDeliveryIcon}
                    freeDeliveryLabelColor={freeDeliveryLabelColor}
                    freeDeliveryBackgroundColor={freeDeliveryBackgroundColor}
                    deliveryIcon={deliveryIcon}
                    deliveryLabelColor={deliveryLabelColor}
                    deliveryBackgroundColor={deliveryBackgroundColor}
                    pickupIcon={pickupIcon}
                    pickupLabelColor={pickupLabelColor}
                    pickupBackgroundColor={pickupBackgroundColor}
                    externalSaleBorderColor={externalSaleBorderColor}
                    externalSaleBackgroundColor={externalSaleBackgroundColor}
                    externalSaleLabelColor={externalSaleLabelColor}
                    sellerLabelColor={sellerLabelColor}
                    mainSellerIcon={mainSellerIcon}
                    visible={true}
                    disabled={false}
                    onClick={this.onProductClick}
                />
            ));
            const totalPages = Math.ceil(results.length / perPage);
            return (
                <React.Fragment>
                    {(hasCategoryFilters || hasNormalFilters) && <div className='category-view-filter-results'>{results.length} resultado(s) encontrado(s)</div>}
                    {totalPages > 1 && this.renderPaginator(page, totalPages, '20px 0')}
                    <div className='category-view-products-list' style={this.productsListStyle(selectedFilters)}>{elements}</div>
                    {totalPages > 1 && this.renderPaginator(page, totalPages, '30px 0 0 0')}
                </React.Fragment>
            );
        } else {
            let message;
            let actionLabel;
            let action;
            if (hasCategoryFilters || hasNormalFilters) {
                message = 'No se han encontrado productos';
                actionLabel = 'Limpiar';
                action = this.clearAllFilters;
            } else {
                message = 'No se han encontrado productos';
                actionLabel = 'Volver';
                action = this.back;
            }
            return (
                <div className='category-view-no-data' style={{marginRight: inSmallMode ? 0 : 90}}>
                    {this.renderError(message, actionLabel, action)}
                </div>
            );
        }
    };

    renderFilters = () => {
        const {style} = this.props;
        const filtersBackgroundColor = style && style['global'] && style['global']['filters'] && style['global']['filters']['backgroundColor'] ? style['global']['filters']['backgroundColor'] : undefined;
        const filtersLabelColor = style && style['global'] && style['global']['filters'] && style['global']['filters']['labelColor'] ? style['global']['filters']['labelColor'] : undefined;
        const filtersCheckboxBackgroundColor = style && style['global'] && style['global']['filters'] && style['global']['filters']['checkboxBackgroundColor'] ? style['global']['filters']['checkboxBackgroundColor'] : undefined;
        const brandsButtonStyle = style && style['global'] && style['global']['filters'] && style['global']['filters']['brands'] ? style['global']['filters']['brands'] : {};
        const sizeButtonStyle = style && style['global'] && style['global']['filters'] && style['global']['filters']['size'] ? style['global']['filters']['size'] : {};
        const priceButtonStyle = style && style['global'] && style['global']['filters'] && style['global']['filters']['price'] ? style['global']['filters']['price'] : {};
        const othersButtonStyle = style && style['global'] && style['global']['filters'] && style['global']['filters']['others'] ? style['global']['filters']['others'] : {};
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        const cancelButtonBackgroundColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['backgroundColor'] ? style['global']['cancelButton']['backgroundColor'] : undefined;
        const cancelButtonBorderColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['borderColor'] ? style['global']['cancelButton']['borderColor'] : undefined;
        const cancelButtonLabelColor = style && style['global'] && style['global']['cancelButton'] && style['global']['cancelButton']['labelColor'] ? style['global']['cancelButton']['labelColor'] : undefined;
        return (
            <FiltersModal
                category={this.state.category}
                searchQuery={this.state.searchQuery}
                filters={this.state.filters}
                onChange={this.changeFilters}
                onClear={this.clearFilters}
                onConfirm={() => {}}
                onClose={this.closeFilters}
                backgroundColor={filtersBackgroundColor}
                labelColor={filtersLabelColor}
                checkboxBackgroundColor={filtersCheckboxBackgroundColor}
                brandsButtonStyle={brandsButtonStyle}
                sizeButtonStyle={sizeButtonStyle}
                priceButtonStyle={priceButtonStyle}
                othersButtonStyle={othersButtonStyle}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                metadata={this.getMetadata()}
            />
        );
    };

    renderError = (message, actionLabel, action) => {
        const {style} = this.props;
        const actionButtonBackgroundColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['backgroundColor'] ? style['global']['actionButton']['backgroundColor'] : undefined;
        const actionButtonBorderColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['borderColor'] ? style['global']['actionButton']['borderColor'] : undefined;
        const actionButtonLabelColor = style && style['global'] && style['global']['actionButton'] && style['global']['actionButton']['labelColor'] ? style['global']['actionButton']['labelColor'] : undefined;
        return (
            <DataError
                message={message}
                actionLabel={actionLabel}
                action={action}
                actionButtonLabelColor={actionButtonLabelColor}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
            />
        );
    };

    render() {
        const selectedFilters = _Filter.getSelectedFilters(this.state.filters);
        return (
            <div className='category-view'>
                {this.loadingData() && (
                    <div className='category-view-loader'>
                        <div className='category-view-loader-wrapper'>
                            <DataLoader message='Cargando productos disponibles...'/>
                        </div>
                    </div>
                )}
                {!this.loadingData() && !this.dataLoaded() && (
                    <React.Fragment>
                        {this.state.notFound ? (
                            <NotFound message={undefined} actionLabel={this.state.errorActionLabel} action={this.state.errorAction} style={this.props.style}/>
                        ) : (
                            <div className='category-view-error'>
                                <div className='category-view-error-wrapper'>
                                    {this.renderError(this.state.errorMessage, this.state.errorActionLabel, this.state.errorAction)}
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {!this.loadingData() && this.dataLoaded() && (
                    <React.Fragment>
                        <div className='category-view-header-container'>{this.renderHeader()}</div>
                        <div className='category-view-content-wrapper' style={this.contentWrapperStyle()}>
                            {this.renderStickyBar(this.state.quickFilters, selectedFilters)}
                            <div className='category-view-products-list-container'>
                                {this.renderProducts(selectedFilters)}
                            </div>
                        </div>
                        {this.state.showFilters && !this.props.showInactivityModal && this.renderFilters()}
                    </React.Fragment>
                )}
            </div>
        );
    };
}

export default withRouter(Category);

Category.propTypes = {
    params: PropTypes.object,
    client: PropTypes.string,
    instance: PropTypes.object,
    catalog: PropTypes.object,
    store: PropTypes.object,
    session: PropTypes.object,
    style: PropTypes.object,
    accessibilityMode: PropTypes.bool,
    algoliaInsights: PropTypes.object,
    inSmallMode: PropTypes.bool,
    showInactivityModal: PropTypes.bool
};