import React, {useEffect, useState} from 'react';
import Button from '../Button/Button';
import Navigation from '../../config/Navigation';
import './UpdateAlert.css';

function UpdateAlert({show, onClose}) {
    const DURATION = 30; // Seconds.
    const [elapsedTime, setElapsedTime] = useState(0);
    const [_interval, _setInterval] = useState(undefined);

    useEffect(() => {
        if (show) {
            _setInterval(
                setInterval(() => {
                    setElapsedTime((et) => et + 1);
                }, 1000)
            );
        } else {
            handleClear();
        }
        return () => {
            handleClear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        if (elapsedTime > DURATION) Navigation.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elapsedTime]);

    const handleClear = () => {
        if (_interval) clearInterval(_interval);
        setInterval(undefined);
        setElapsedTime(0);
    };

    const handleClose = () => {
        if (onClose) onClose();
    };

    const remainingTime = DURATION - elapsedTime;
    const progress = Math.round(100 - ((elapsedTime / DURATION) * 100));

    return (
        <div className='update-alert'>
            <p className='update-alert-label'>Existe una actualización disponible, este Kiosco se reiniciará en {remainingTime > 0 ? remainingTime : 0} segundo(s)</p>
            <div className='update-alert-cancel-button'>
                <Button label='Cancelar' labelColor='#FFFFFF' backgroundColor='#CD5C5C' borderColor='#CD5C5C' minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={handleClose}/>
            </div>
            <div className='update-alert-progress-bar'>
                <div className='update-alert-progress-bar-fill' style={{width: `${progress > 0 ? progress : 0}%`}}/>
            </div>
        </div>
    );
}

export default UpdateAlert;