import React, {useRef, useState, useEffect} from 'react';
import PaymentStepActions from '../PaymentStepActions/PaymentStepActions';
import DataLoader from '../../../DataLoader/DataLoader';
import DataError from '../../../DataError/DataError';
import {EXTERNAL_CHECKOUT_IFRAME_MAX_TIME, LOG_INACTIVITY_TIME} from '../../../../config/App';
import './PaymentIframe.css';
import Debug from "../../../../helpers/Debug";

function PaymentIframe({
    checkoutUrl,
    onStartup,
    cancelButtonBackgroundColor,
    cancelButtonLabelColor,
    cancelButtonBorderColor,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    resetPromotionalVideoTimer,
    onClose,
    onBack
}) {
    const iframeRef = useRef(null);
    const [error, setError] = useState(false);
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const inactivityOverrideIntervalRef = useRef(null);
    const autoCloseIntervalRef = useRef(null);
    const [autoCloseElapsedTime, setAutoCloseElapsedTime] = useState(0);

    useEffect(() => {
        if (!checkoutUrl) {
            setError(true);
            setIsLoadingIframe(false);
        } else {
            setIsLoadingIframe(true);
            setIframeListeners();
            startInactivityTimerOverride();
            startAutoCloseInterval();
        }
        if (onStartup) onStartup();
        return () => {
            stopInactivityTimerOverride();
            stopAutoCloseInterval();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setIframeListeners = () => {
        const element = iframeRef.current;
        element.onload = () => {
            setTimeout(() => {
                setIsLoadingIframe(false);
                setIframeLoaded(true);
            }, 1000);
        };
        element.onerror = () => {
            setTimeout(() => {
                setIsLoadingIframe(false);
                setIframeLoaded(false);
            }, 1000);
        };
        element.src = checkoutUrl;
    };

    const startInactivityTimerOverride = () => {
        inactivityOverrideIntervalRef.current = setInterval(() => {
            resetPromotionalVideoTimer();
        }, 5000);
    };

    const stopInactivityTimerOverride = () => {
        if (inactivityOverrideIntervalRef && inactivityOverrideIntervalRef.current) clearInterval(inactivityOverrideIntervalRef.current);
    };

    const startAutoCloseInterval = () => {
        autoCloseIntervalRef.current = setInterval(() => {
            setAutoCloseElapsedTime((elapsedTime) => elapsedTime + 1);
        }, 1000);
    };

    const stopAutoCloseInterval = () => {
        setAutoCloseElapsedTime(0);
        if (autoCloseIntervalRef && autoCloseIntervalRef.current) clearInterval(autoCloseIntervalRef.current);
    };

    useEffect(() => {
        const remainingTime = EXTERNAL_CHECKOUT_IFRAME_MAX_TIME - autoCloseElapsedTime;
        if (LOG_INACTIVITY_TIME) Debug.printToLog('info', `Remaining time until checkout auto close: ${remainingTime} seconds`);
        if (remainingTime <= 0) {
            stopAutoCloseInterval();
            if (onClose) onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoCloseElapsedTime]);

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleBack = () => {
        if (onBack) onBack();
    };

    const iframeStyle = () => {
        const show = !isLoadingIframe && iframeLoaded;
        return {
            opacity: show ? 1 : 0
        };
    };

    const iframeLoaderStyle = () => {
        return {
            opacity: isLoadingIframe ? 1 : 0
        };
    };

    const iframeErrorStyle = () => {
        const show = !isLoadingIframe && !iframeLoaded;
        return {
            opacity: show ? 1 : 0,
            width: show ? 'auto' : 0,
            height: show ? 'auto' : 0
        };
    };

    return (
        <div className='payment-iframe'>
            {!error && (
                <React.Fragment>
                    <p className='payment-iframe-title' style={{padding: '20px 20px 30px 20px', margin: 0}}>
                        <span className='bold'>¡Comencemos! Ingresa tus datos</span>
                    </p>
                    <div className='payment-iframe-content' style={{height: 970, padding: 0, overflow: 'unset'}}>
                        <iframe ref={iframeRef} className='payment-iframe-module' title='External checkout' style={iframeStyle()}/>
                        <div className='payment-iframe-module-loader' style={iframeLoaderStyle()}>
                            <DataLoader message='Cargando enlace de compra...'/>
                        </div>
                        <div className='payment-iframe-module-error' style={iframeErrorStyle()}>
                            <DataError message='Se ha producido un error al cargar el enlace de compra'/>
                        </div>
                    </div>
                </React.Fragment>
            )}
            {error && (
                <div className='payment-iframe-error'>
                    <DataError message='Lo sentimos, el enlace de pago no es válido'/>
                </div>
            )}
            <div className='payment-iframe-actions-container' style={{padding: error ? '0px 30px 30px 30px' : 30}}>
                <PaymentStepActions
                    isLoading={false}
                    loadingLabel={undefined}
                    submitLabel={undefined}
                    cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                    cancelButtonBorderColor={cancelButtonBorderColor}
                    cancelButtonLabelColor={cancelButtonLabelColor}
                    backButtonBackgroundColor={backButtonBackgroundColor}
                    backButtonBorderColor={backButtonBorderColor}
                    backButtonLabelColor={backButtonLabelColor}
                    submitButtonBackgroundColor={undefined}
                    submitButtonBorderColor={undefined}
                    submitButtonLabelColor={undefined}
                    showCloseButton={true}
                    showBackButton={true}
                    showSubmitButton={false}
                    onClose={handleClose}
                    onBack={handleBack}
                    onSubmit={undefined}
                />
            </div>
        </div>
    );
}

export default PaymentIframe;