import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {getBlob, ref} from 'firebase/storage';
import Signup from '../Signup/Signup';
import PaymentOptionSelection from '../../PaymentSteps/PaymentOptionSelection/PaymentOptionSelection';
import PaymentMessage from '../../PaymentSteps/PaymentMessage/PaymentMessage';
import Budget from '../../PaymentDocuments/Budget/Budget';
import WaitingPayment from '../../PaymentSteps/WaitingPayment/WaitingPayment';
import DataLoader from '../../../DataLoader/DataLoader';
import DataError from "../../../DataError/DataError";
import Activity from '../../../../helpers/Activity';
import Pin from '../../../../helpers/Pin';
import Printer from '../../../../helpers/Printer';
import TransbankPOS from '../../../../helpers/TransbankPOS';
import Debug from '../../../../helpers/Debug';
import WebServices from '../../../../helpers/WebServices';
import {storage} from '../../../../config/Firebase';
import defaultCardOptionImage from './images/card-option.png';
import defaultOtherPaymentOptionImage from './images/other-payment-option.png';
import defaultTicketOptionImage from './images/ticket-option.png';
import defaultInvoiceOptionImage from './images/invoice-option.png';
import defaultTimerImage from './images/ticket-option.png';
import defaultPrintMessageImage from './images/print-message.png';
import defaultPaymentSuccessImage from './images/success.png';
import packageErrorImage from './images/package-error.png';
import './IntegratedPOS.css';

export default class IntegratedPOS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 'signup',
            loadingMessage: undefined,
            registerData: undefined,
            paymentMode: undefined,
            receiptType: undefined,
            isSavingCustomer: false,
            customerSaved: true,
            isSavingOrder: false,
            orderSaved: true,
            lastSaveOrderStatus: undefined,
            lastPosSuccessResponse: undefined,
            posConnected: false,
            isCreatingTicket: false,
            documentCreated: false,
            documentPrinted: false,
            documentPrintError: true,
            maxPaymentTime: 60 * 3, // 3 Minutes.
            elapsedPaymentTime: 0,
            paymentErrorMessage: undefined,
            documentUrl: undefined,
            paymentDocumentPrinted: false,
            printErrorMessage: undefined,
            stockCheckAttempts: 0
        };
        this._isMounted = true;
        this._containerRef = createRef();
        this._ticketRef = createRef();
        this._posConnectionCheckInterval = undefined;
        this._paymentInterval = undefined;
        this._paymentController = undefined;
    };

    changeState = (newState, callback) => {
        if (this._isMounted) {
            this.setState(newState, () => {
                if (callback) callback();
            });
        }
    };

    componentDidMount() {
        this._isMounted = true;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const lockedSteps = ['loading', 'waiting-payment', 'payment-success'];
        if (prevState.step !== this.state.step && lockedSteps.includes(this.state.step)) {
            this.props.preventClosing();
            if (this.state.step === 'waiting-payment') this.sendPaymentSignal();
        }
        if (prevState.step !== this.state.step && !lockedSteps.includes(this.state.step)) {
            this.props.allowClosing();
        }
        const finishSteps = ['close-after-print', 'printing-error', 'other-payment-ticket-print', 'go-to-cashier-error'];
        if (prevState.step !== this.state.step && finishSteps.includes(this.state.step)) {
            this.props.flowFinished();
        }
        const posConnectionCheckSteps = ["payment-mode-selection"];
        if (prevState.step !== this.state.step && posConnectionCheckSteps.includes(this.state.step)) {
            this.startPOSConnectionCheck();
        }
        if (prevState.step !== this.state.step && !posConnectionCheckSteps.includes(this.state.step)) {
            this.stopPOSConnectionCheck();
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.stopPOSConnectionCheck();
        this.stopPaymentInterval();
        this.abortPayment();
    };

    saveCustomer = (document) => {
        const {registerData} = this.state;
        return new Promise((resolve) => {
            this.changeState({step: 'loading', loadingMessage: 'Guardando cliente en el sistema...', isSavingCustomer: true, customerSaved: false}, () => {
                WebServices.saveCustomer({
                    entity: registerData.client.entity,
                    document: document,
                    segment: registerData.client.segment,
                    pin: registerData.client.pin,
                    name: registerData.client.name,
                    phone: registerData.client.phone,
                    email: registerData.client.email,
                    activity: registerData.client.activity,
                    state: registerData.client.state,
                    city: registerData.client.city,
                    district: registerData.client.district,
                    street: registerData.client.street
                })
                    .then((response) => {
                        if (response && response.status) {
                            this.changeState({loadingMessage: undefined, isSavingCustomer: false, customerSaved: true}, () => {
                                resolve(true);
                            });
                        } else {
                            this.changeState({step: 'error-saving-client', loadingMessage: undefined, isSavingCustomer: false, customerSaved: false}, () => {
                                resolve(false);
                            });
                        }
                    })
            });
        });
    };

    saveOrder = (checkStock, quote, status, receiptType, cardType) => {
        const {registerData} = this.state;
        return new Promise((resolve) => {
            this.changeState({step: 'loading', loadingMessage: `Guardando ${quote ? 'cotización' : 'orden'} en el sistema...`, isSavingOrder: true, orderSaved: false, lastSaveOrderStatus: status}, () => {
                const products = this.props.shoppingCart.listProductsAndVariantsIds();
                const promise = checkStock ? WebServices.startProductExtraction(this.props.client, products) : Promise.resolve();
                promise
                    .then((response) => {
                        if (checkStock) {
                            if (response) {
                                const allItemsInStock = this.props.shoppingCart.items.every((item) => {
                                    const quantity = item.quantity;
                                    const match = response.find((data) => data.id === item.product);
                                    const maxPerItem = match ? match.maxPerItem : 0;
                                    return maxPerItem !== 0 || quantity <= maxPerItem;
                                });
                                if (!allItemsInStock) throw new Error('no-stock-error');
                                else return true;
                            } else { // Error case: invalid response, do not check stock because if we do, then every product will be flagged with a stock error.
                                Debug.printToLog('error', 'Invalid product extraction response, ignoring stock check');
                                const attempts = this.state.stockCheckAttempts + 1;
                                const showGoToCashierError = attempts >= 3;
                                let newState = {stockCheckAttempts: attempts};
                                if (showGoToCashierError) {
                                    newState.isCreatingDocument = true;
                                    newState.documentCreated = false;
                                }
                                this.changeState(newState);
                                if (showGoToCashierError) throw new Error('go-to-cashier-error');
                                else throw new Error('stock-check-error');
                            }
                        } else return true;
                    })
                    .then(() => {
                        return WebServices.saveOrder({
                            id: this.props.shoppingCart.dbId,
                            instance: this.props.instance.id,
                            session: this.props.metadata && this.props.metadata.session && this.props.metadata.session.id ? this.props.metadata.session.id : undefined,
                            pin: registerData.client.pin,
                            name: registerData.client.name,
                            phone: registerData.client.phone,
                            email: registerData.client.email,
                            store: this.props.store.id,
                            quote: quote,
                            document: receiptType,
                            state: registerData.client.state,
                            city: registerData.client.city,
                            district: registerData.client.district,
                            street: registerData.client.street,
                            status: status,
                            cardType: cardType,
                            pk: this.props.pk
                        });
                    })
                    .then((response) => {
                        if (response && response.status) {
                            this.changeState({loadingMessage: undefined, isSavingOrder: false, orderSaved: true, lastSaveOrderStatus: undefined, lastPosSuccessResponse: undefined, documentUrl: response.documentUrl ? response.documentUrl : undefined});
                            resolve(true);
                        } else throw new Error('error-saving-order');
                    })
                    .catch((error) => {
                        Debug.printToLog('error', error);
                        const newStep = error && error.message ? error.message : 'unknown-error';
                        this.changeState({step: newStep, loadingMessage: undefined, isSavingOrder: false, orderSaved: false, documentUrl: undefined}, () => {
                            resolve(false);
                        });
                    });
            });
        });
    };

    checkPOSConnection = () => {
        TransbankPOS.status()
            .then((status) => {
                Debug.printToLog(status ? "info" : "error", `POS status: ${status ? 'Connected' : 'Disconnected'}`);
                this.changeState({posConnected: status});
            });
    };

    isPerson = () => {
        const {registerData} = this.state;
        return registerData && registerData.client && registerData.client.entity && registerData.client.entity === 'person';
    };

    isCompany = () => {
        const {registerData} = this.state;
        return registerData && registerData.client && registerData.client.entity && registerData.client.entity === 'company';
    };

    handleClose = (closeType) => {
        if (closeType) Activity.log(this.props.metadata, 'checkout', closeType, 'close', {type: 'integrated-pos', step: this.state.step || 'unknown'});
        if (this.props.onClose) this.props.onClose();
    };

    handleBack = () => {
        let newState = {};
        switch (this.state.step) {
            case 'payment-mode-selection':
                newState.step = 'signup';
                break;
            case 'receipt-type-selection':
                if (this.isCompany()) newState.paymentMode = undefined;
                newState.step = 'payment-mode-selection';
                break;
            default: // Do not allow back navigation for the following states: 'signup', 'other-payment-ticket-print', 'waiting-payment', 'payment-error' and 'payment-success'.
                break;
        }
        Activity.log(this.props.metadata, 'checkout', 'back-button', 'back', {type: 'integrated-pos'});
        this.changeState(newState);
    };

    handleOpenShoppingCart = () => {
        Activity.log(this.props.metadata, 'checkout', 'no-stock-error', 'open-shopping-cart', {type: 'integrated-pos', step: this.state.step || 'unknown'});
        if (this.props.openShoppingCart) this.props.openShoppingCart();
        this.handleClose(undefined);
    };

    onRegisterFinished = (newRegisterData) => {
        this.changeState({registerData: newRegisterData, step: 'payment-mode-selection'});
    };

    onPaymentModeSelected = (newPaymentMode, retry) => {
        this.changeState({paymentMode: newPaymentMode}, () => {
            if (['card', 'other-payment'].includes(newPaymentMode)) {
                let newState = {};
                if (newPaymentMode === 'card') {
                    if (this.isPerson()) {
                        newState.receiptType = 'ticket';
                        newState.step = 'waiting-payment';
                    }
                    else if (this.isCompany()) newState.step = 'receipt-type-selection';
                    else newState.step = 'receipt-type-selection';
                } else {
                    newState.step = 'other-payment-ticket-print';
                    newState.isCreatingDocument = true;
                    newState.documentCreated = false;
                }
                const action = retry ? 'retry' : `selected-${newPaymentMode}-option`;
                Activity.log(this.props.metadata, 'checkout', this.state.step, action, {
                    type: 'integrated-pos',
                    paymentMode: newPaymentMode,
                    entity: this.state.registerData.client.entity,
                    shoppingCart: this.props.shoppingCart.toActivityLog()
                });
                const isQuote = newPaymentMode === 'other-payment';
                const isFinalStepBeforePayment = newPaymentMode === 'card' && this.isPerson();
                if (isQuote || isFinalStepBeforePayment) {
                    this.saveOrder(!isQuote, isQuote, 'pending', "ticket", undefined).then((success) => {if (success) this.changeState(newState)});
                } else this.changeState(newState);
            }
        });
    };

    startPOSConnectionCheck = () => {
        Debug.printToLog("info", "Starting POS check interval...");
        this.checkPOSConnection();
        this._posConnectionCheckInterval = setInterval(() => {
            this.checkPOSConnection();
        }, 3000);
    };

    stopPOSConnectionCheck = () => {
        Debug.printToLog("info", "Stopping POS check interval...");
        if (this._posConnectionCheckInterval) clearInterval(this._posConnectionCheckInterval);
    };

    onReceiptTypeSelected = (receiptType, retry) => {
        const action = retry ? 'retry' : `selected-${receiptType}-option`;
        Activity.log(this.props.metadata, 'checkout', this.state.step, action, {
            type: 'integrated-pos',
            paymentMode: this.state.paymentMode,
            receiptType: receiptType,
            entity: this.state.registerData.client.entity,
            shoppingCart: this.props.shoppingCart.toActivityLog()
        });
        this.changeState({receiptType: receiptType}, () => {
            this.saveCustomer(receiptType)
                .then((success) => {
                    if (success) return this.saveOrder(true, false, 'pending', receiptType, undefined);
                })
                .then((success) => {
                    if (success) this.changeState({step: 'waiting-payment'});
                });
        });
    };

    onOrderCreationRetry = () => {
        if (this.state.lastSaveOrderStatus === 'completed') {
            this.onPaymentReceived(this.state.lastPosSuccessResponse);
        } else {
            const isQuote = this.state.paymentMode === 'other-payment';
            const isFinalStepBeforePayment = this.state.paymentMode === 'card' && this.isPerson();
            if (isQuote || isFinalStepBeforePayment) {
                this.onPaymentModeSelected(this.state.paymentMode, true);
            } else {
                this.onReceiptTypeSelected(this.state.receiptType, true);
            }
        }
    };

    onDocumentLoaded = () => {
        setTimeout(() => {
            this.changeState({isCreatingDocument: false, documentCreated: true});
        }, 2000);
    };

    printTicket = () => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, 'print-ticket', {
            type: 'integrated-pos',
            paymentMode: this.state.paymentMode,
            entity: this.state.registerData.client.entity,
            shoppingCart: this.props.shoppingCart.toActivityLog()
        });
        this.changeState({documentPrinted: false, documentPrintError: false}, () => {
            return Printer.printHTMLFromElement(this._containerRef, this._ticketRef)
                .then((status) => {
                    this.changeState({documentPrinted: true, documentPrintError: !status});
                })
                .then(() => {
                    setTimeout(() => {
                        this.changeState({documentPrinted: false, documentPrintError: false});
                    }, 1000 * 10);
                });
        });
    };

    printPaymentDocument = () => {
        Activity.log(this.props.metadata, 'checkout', this.state.step, 'print-payment-document', {
            type: 'integrated-pos',
            paymentMode: this.state.paymentMode,
            receiptType: this.state.receiptType,
            documentUrl: this.state.documentUrl || null,
            entity: this.state.registerData.client.entity,
            shoppingCart: this.props.shoppingCart.toActivityLog()
        });
        this.changeState({paymentDocumentPrinted: true}, () => {
            if (this.state.documentUrl) {
                Printer.removePrinterIframe('payment-document-iframe');
                const reference = ref(storage, this.state.documentUrl);
                getBlob(reference)
                    .then((blob) => {
                        const blobUrl = URL.createObjectURL(blob);
                        return Printer.addPrinterIframe('payment-document-iframe', this._containerRef, blobUrl);
                    })
                    .then(() => {
                        this.changeState({step: 'close-after-print'});
                    })
                    .catch((error) => {
                        Debug.printToLog('error', error);
                        this.changeState({step: 'printing-error', printErrorMessage: 'El pago fue completado exitosamente, pero se ha producido un error al imprimir el comprobante de pago. Por favor diríjase al mesón de atención e indique su RUT para continuar'});
                    })
            } else {
                this.changeState({step: 'printing-error', printErrorMessage: 'El pago fue completado exitosamente, pero se ha producido un error al obtener el comprobante de pago. Por favor diríjase al mesón de atención e indique su RUT para continuar'});
            }
        });
    };

    sendPaymentSignal = () => {
        this._paymentController = new AbortController();
        const signal = this._paymentController.signal;
        Promise.resolve()
            .then(() => {
                if (this.state.posConnected) {
                    this.startPaymentInterval();
                    // To test the timer, we can add a timeout when connecting to the POS in mocking mode.
                    // We are not using exclusive total, because we do not have a way to know if the user has a credit/debit card from the client.
                    const timeout = 1;
                    return TransbankPOS.startSale(this.props.metadata, this.props.shoppingCart.getTotal(this.props.taxMultiplier), this.props.paymentNumber, true, timeout, signal);
                } else throw new Error('payment-server-offline');
            })
            .then((response) => {
                if (response) {
                    Debug.printToLog('info', `Payment server responded with code: ${response['responseCode']}`);
                    switch (response['responseCode']) {
                        case 0: // Aprobado
                            this.changeState({lastPosSuccessResponse: response}, () => {
                                this.onPaymentReceived(response);
                            });
                            break;
                        case 1: // Rechazado
                            throw new Error('payment-rejected');
                        case 2: // Autorizador no responde
                            throw new Error('authorizer-no-response');
                        case 5: // No existe transacción para anular
                            throw new Error('no-transaction-to-cancel');
                        case 6: // Tarjeta no soportada
                            throw new Error('card-not-supported');
                        case 7: // Transacción cancelada
                            throw new Error('transaction-canceled');
                        case 9: // Error lectura tarjeta
                            throw new Error('card-reading-error');
                        case 10: // Monto menor al mínimo permitido
                            throw new Error('amount-less-than-minimum');
                        case 11: // No existe venta
                            throw new Error('no-sale');
                        case 12: // Transacción no soportada
                            throw new Error('transaction-not-supported');
                        case 78: // Modo Multicomercio no activo
                            throw new Error('multi-commerce-mode-not-active');
                        case 79: // Modo Venta Normal no Activo
                            throw new Error('normal-sale-mode-not-active');
                        case 81: // Solicitando ingreso de clave
                            throw new Error('requesting-key-entry');
                        case 82: // Enviando transacción al autorizador
                            throw new Error('sending-to-authorizer');
                        case 83: // Selección menú crédito/Redcompra
                            throw new Error('credit-debit-selection-menu');
                        case 84: // Opere tarjeta
                            throw new Error('operate-card');
                        case 85: // Selección de cuotas
                            throw new Error('select-installments');
                        case 86: // Ingreso de cuotas
                            throw new Error('installments-entry');
                        case 88: // Aceptar consulta cuotas
                            throw new Error('accept-installments-query');
                        case 87: // Confirmación de cuotas
                            throw new Error('installments-confirmation');
                        case 90: // Inicialización exitosa
                            throw new Error('initialization-success');
                        case 91: // Inicialización fallida
                            throw new Error('initialization-failed');
                        case 93: // Consultando cuota al autorizador
                            throw new Error('querying-installments');
                        default: // Otros
                            throw new Error('other-error');
                    }
                } else throw new Error('no-response');
            })
            .catch((error) => {
                let userMessage;
                const errorMessage = error && error.message ? error.message : undefined;
                Debug.printToLog('error', `Transaction failed with error: ${errorMessage}`);
                // Errors that are not included will be treated as unknown errors (not every error requires an explanation).
                switch (errorMessage) {
                    case 'payment-server-offline':
                        userMessage = 'No se ha podido establecer conexión con el servidor de pago. Puede volver a intentar o puede ir al mesón de atención con su RUT para continuar';
                        break;
                    case 'no-response':
                        userMessage = 'El servidor de pago no ha respondido';
                        break;
                    case 'payment-rejected':
                        userMessage = 'El pago ha sido rechazado';
                        break;
                    case 'authorizer-no-response':
                        userMessage = 'El autorizador no responde';
                        break;
                    case 'card-not-supported':
                        userMessage = 'La tarjeta usada no es soportada';
                        break;
                    case 'transaction-canceled':
                        userMessage = 'La transacción ha sido cancelada';
                        break;
                    case 'card-reading-error':
                        userMessage = 'Se ha producido un error al leer la tarjeta';
                        break;
                    case 'amount-less-than-minimum':
                        userMessage = 'El monto de la transacción es menor al mínimo permitido';
                        break;
                    default:
                        userMessage = 'Se ha producido un error al realizar el pago';
                        break;
                }
                this.onPaymentError(userMessage);
            });
    };

    abortPayment = () => {
        if (this._paymentController) this._paymentController.abort();
    };

    startPaymentInterval = () => {
        this.resetPaymentInterval();
        this._paymentInterval = setInterval(() => {
            const elapsedPaymentTime = this.state.elapsedPaymentTime + 1;
            if (elapsedPaymentTime >= this.state.maxPaymentTime) {
                this.abortPayment();
                this.onPaymentExpired();
            }
            this.changeState({elapsedPaymentTime: elapsedPaymentTime});
        }, 1000);
    };

    stopPaymentInterval = () => {
        if (this._paymentInterval) clearInterval(this._paymentInterval);
    };

    resetPaymentInterval = () => {
        this.changeState({elapsedPaymentTime: 0});
    };

    onPaymentExpired = () => {
        this._paymentController = undefined;
        this.stopPaymentInterval();
        this.changeState({step: 'payment-expired'}, () => this.sendPaymentMetric('cancel'));
    };

    onPaymentError = (message) => {
        this._paymentController = undefined;
        this.stopPaymentInterval();
        this.changeState({step: 'payment-error', paymentErrorMessage: message}, () => this.sendPaymentMetric('cancel'));
    };

    onPaymentReceived = (response) => {
        this._paymentController = undefined;
        this.stopPaymentInterval();
        let cardType = null;
        switch (response['cardType']) {
            case "DB":
                cardType = 'debit';
                break;
            case "CR":
                cardType = 'credit';
                break;
            default:
                break;
        }
        this.saveOrder(false, false, 'completed', this.state.receiptType, cardType)
            .then((status) => {
                if (status) this.changeState({step: 'payment-success'}, () => {
                    this.sendPaymentMetric('continue');
                });
            });
    };

    retryPayment = () => {
        this.sendPaymentMetric('retry'); // Metric is sent before changing the state, so the step says payment-error.
        this.changeState({step: 'waiting-payment', paymentErrorMessage: undefined});
    };

    sendPaymentMetric = (action) => {
        const {step, paymentMode, receiptType, registerData} = this.state;
        const {metadata, shoppingCart} = this.props;
        const algoliaInsights = metadata.algoliaInsights;
        Activity.log(metadata, 'checkout', step, action, {
            type: 'integrated-pos',
            paymentMode: paymentMode,
            receiptType: receiptType,
            entity: registerData.client.entity,
            shoppingCart: shoppingCart.toActivityLog()
        });
        if (metadata && metadata.session && metadata.session.id) algoliaInsights.purchasedObjectIDs(metadata.session.id, shoppingCart);
    };

    renderLoader = () => {
        return (
            <div className='integrated-pos-loader'>
                <DataLoader message={this.state.loadingMessage || 'Cargando...'}/>
            </div>
        );
    };

    renderStep = () => {
        switch (this.state.step) {
            case 'loading':
                return this.renderLoader();
            case 'signup':
                return this.renderSignup();
            case 'error-saving-order':
                return this.renderErrorSavingOrder();
            case 'stock-check-error':
                return this.renderStockCheckError();
            case 'go-to-cashier-error':
                return this.renderGoToCashierError();
            case 'payment-mode-selection':
                return this.renderPaymentModeSelection();
            case 'receipt-type-selection':
                return this.renderCardReceiptTypeSelection();
            case 'other-payment-ticket-print':
                return this.renderOtherPaymentTicketPrint();
            case 'error-saving-client':
                return this.renderErrorSavingClient();
            case 'no-stock-error':
                return this.renderNoStockError();
            case 'waiting-payment':
                return this.renderWaitingPayment();
            case 'payment-expired':
                return this.renderPaymentExpired();
            case 'payment-error':
                return this.renderPaymentError();
            case 'payment-success':
                return this.renderPaymentSuccess();
            case 'close-after-print':
                return this.renderPaymentSuccess();
            case 'printing-error':
                return this.renderPrintError();
            case 'unknown-error':
                return this.renderUnknownError();
            default:
                break;
        }
    };

    renderSignup = () => {
        return (
            <Signup
                flow='integrated-pos'
                initialData={this.state.registerData && this.state.registerData.client ? this.state.registerData.client : undefined}
                instance={this.props.instance}
                store={this.props.store}
                shoppingCart={this.props.shoppingCart}
                orderNumber={this.props.orderNumber}
                allowBudgetPrint={false}
                mainClientLogo={this.props.mainClientLogo}
                personOptionImage={this.props.personOptionImage}
                companyOptionImage={this.props.companyOptionImage}
                printMessageImage={undefined}
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                customInputOutline={this.props.customInputOutline}
                customInputClearButtonBackgroundColor={this.props.customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={this.props.customInputClearButtonLabelColor}
                inSmallMode={this.props.inSmallMode}
                onClose={this.props.onClose}
                onRegisterFinished={this.onRegisterFinished}
                metadata={this.props.metadata}
            />
        );
    };

    renderErrorSavingClient = () => {
        return (
            <div className='integrated-pos-error'>
                <DataError
                    message='Se ha producido un error al guardar la información del cliente'
                    actionLabel='Reintentar'
                    action={() => {this.onReceiptTypeSelected(this.state.receiptType, true)}}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    secondaryActionLabel='Cerrar'
                    secondaryAction={() => this.handleClose('error-saving-client')}
                    secondaryActionButtonLabelColor={this.props.cancelButtonLabelColor}
                    secondaryActionButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                    secondaryActionButtonBorderColor={this.props.cancelButtonBorderColor}
                    maxWidth={800}
                />
            </div>
        );
    };

    renderErrorSavingOrder = () => {
        return (
            <div className='integrated-pos-error'>
                <DataError
                    message='Se ha producido un error al crear la orden en el sistema'
                    actionLabel='Reintentar'
                    action={this.onOrderCreationRetry}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    secondaryActionLabel='Cerrar'
                    secondaryAction={() => this.handleClose('error-saving-order')}
                    secondaryActionButtonLabelColor={this.props.cancelButtonLabelColor}
                    secondaryActionButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                    secondaryActionButtonBorderColor={this.props.cancelButtonBorderColor}
                    maxWidth={800}
                />
            </div>
        );
    };

    renderPaymentModeSelection = () => {
        const options = [
            {
                type: 'card',
                icon: this.props.cardOptionImage || defaultCardOptionImage,
                label1: 'Paga aquí',
                label2: 'con tu tarjeta de crédito o débito',
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                disabled: !this.state.posConnected,
                onClick: (mode) => {this.onPaymentModeSelected(mode, false)}
            },
            {
                type: 'other-payment',
                icon: this.props.otherPaymentOptionImage || defaultOtherPaymentOptionImage,
                label1: 'Paga en caja',
                label2: 'con otros medios de pago',
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                disabled: false,
                onClick: (mode) => {this.onPaymentModeSelected(mode, false)}
            }
        ];
        return (
            <PaymentOptionSelection
                title1='Pago de productos'
                title2='Selecciona una de las siguientes opciones:'
                titleLineBreak={true}
                showShadow={true}
                options={options}
                cancelButtonBackgroundColor={undefined}
                cancelButtonBorderColor={undefined}
                cancelButtonLabelColor={undefined}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                onClose={undefined}
                onBack={this.handleBack}
            />
        );
    };

    renderCardReceiptTypeSelection = () => {
        const options = [
            {
                type: 'ticket',
                icon: this.props.ticketOptionImage || defaultTicketOptionImage,
                label1: 'Boleta',
                label2: undefined,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                disabled: false,
                onClick: (type) => {this.onReceiptTypeSelected(type, false)}
            },
            {
                type: 'invoice',
                icon: this.props.invoiceOptionImage || defaultInvoiceOptionImage,
                label1: 'Factura',
                label2: undefined,
                boldLabel1: true,
                boldLabel2: false,
                labelLineBreak: true,
                waitingMode: false,
                waitingModeIcon: undefined,
                waitingModeLabel: undefined,
                disabled: false,
                onClick: (type) => {this.onReceiptTypeSelected(type, false)}
            }
        ];
        return (
            <PaymentOptionSelection
                title1='¿Qué tipo de comprobante quieres?'
                title2='Selecciona una de las siguientes opciones:'
                titleLineBreak={true}
                showShadow={true}
                options={options}
                cancelButtonBackgroundColor={undefined}
                cancelButtonBorderColor={undefined}
                cancelButtonLabelColor={undefined}
                backButtonBackgroundColor={this.props.backButtonBackgroundColor}
                backButtonBorderColor={this.props.backButtonBorderColor}
                backButtonLabelColor={this.props.backButtonLabelColor}
                onClose={undefined}
                onBack={this.handleBack}
            />
        );
    };

    renderOtherPaymentTicketPrint = () => {
        return (
            <PaymentMessage
                isLoading={this.state.isCreatingDocument}
                loaded={this.state.documentCreated}
                showPreview={true}
                preview={this.renderDocumentPreview()}
                previewSize={Printer.getDocumentSize()}
                title1={undefined}
                title2='Tu ticket está listo'
                loadingMessage='Preparando ticket...'
                step1Message1='Imprime tu ticket'
                step1Message2=' y acércate al mesón de atención para completar con tu compra'
                step1Image={this.props.printMessageImage || defaultPrintMessageImage}
                showStep2={false}
                step2Message1={undefined}
                step2Message2={undefined}
                step2Image={undefined}
                customStepWidth='100%'
                errorMessage='Se ha producido un error al imprimir tu ticket'
                actionLabel='Imprimir'
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={undefined}
                cancelButtonBorderColor={undefined}
                cancelButtonLabelColor={undefined}
                onClose={undefined}
                onSubmit={this.printTicket}
                submitted={this.state.documentPrinted}
                submitSuccess={!this.state.documentPrintError}
                submitSuccessMessage='Tu ticket se está imprimiendo...'
                submitErrorMessage='Se ha producido un error al imprimir tu ticket'
            />
        );
    };

    renderStockCheckError = () => {
        return (
            <div className='integrated-pos-error'>
                <DataError
                    message='Se ha producido un error al validar el stock de los productos'
                    actionLabel='Reintentar'
                    action={this.onOrderCreationRetry}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    secondaryActionLabel='Cerrar'
                    secondaryAction={() => this.handleClose('stock-check-error')}
                    secondaryActionButtonLabelColor={this.props.cancelButtonLabelColor}
                    secondaryActionButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                    secondaryActionButtonBorderColor={this.props.cancelButtonBorderColor}
                    maxWidth={800}
                />
            </div>
        );
    };

    renderGoToCashierError = () => {
        return (
            <PaymentMessage
                isLoading={this.state.isCreatingDocument}
                loaded={this.state.documentCreated}
                showPreview={true}
                preview={this.renderDocumentPreview()}
                previewSize={Printer.getDocumentSize()}
                title1={undefined}
                title2='Error al validar el stock de los productos'
                loadingMessage='Preparando cotización...'
                step1Message1='Lo sentimos, '
                step1Message2=' se ha producido un error al validar el stock de los productos. Puedes imprimir una cotización y finalizar la compra en caja'
                step1Image={this.props.printMessageImage || defaultPrintMessageImage}
                showStep2={false}
                step2Message1={undefined}
                step2Message2={undefined}
                step2Image={undefined}
                customStepWidth='100%'
                errorMessage='Se ha producido un error al imprimir tu cotización'
                actionLabel='Imprimir'
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={undefined}
                cancelButtonBorderColor={undefined}
                cancelButtonLabelColor={undefined}
                onClose={undefined}
                onSubmit={this.printTicket}
                submitted={this.state.documentPrinted}
                submitSuccess={!this.state.documentPrintError}
                submitSuccessMessage='Tu cotización se está imprimiendo...'
                submitErrorMessage='Se ha producido un error al imprimir tu cotización'
            />
        );
    };

    renderNoStockError = () => {
        return (
            <div className='integrated-pos-error'>
                <div className='integrated-pos-no-stock-modal'>
                    <p className='integrated-pos-no-stock-modal-title'>¡Lo sentimos!</p>
                    <p className='integrated-pos-no-stock-modal-description'>No hay <span className='bold'>stock suficiente</span> para comprar algunos productos. Por favor revisa tu carro de compras y vuelve a intentar.</p>
                    <div className='integrated-pos-no-stock-modal-content'>
                        <DataError
                            message={undefined}
                            customImage={packageErrorImage}
                            actionLabel='Ir al carro de compras'
                            action={this.handleOpenShoppingCart}
                            actionButtonLabelColor={this.props.actionButtonLabelColor}
                            actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                            actionButtonBorderColor={this.props.actionButtonBorderColor}
                            maxWidth='100%'
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderDocumentPreview = () => {
        return (
            <Budget
                documentRef={this._ticketRef}
                title='PRESUPUESTO ELECTRÓNICO'
                id={this.props.orderNumber}
                idPrefix='N°'
                image={this.state.registerData.company.companyLogo}
                issuerName={this.state.registerData.company.companyName}
                issuerAddressLabel='Casa Matriz'
                issuerAddress={this.state.registerData.company.hqAddress}
                issuerActivityLabel='Giro'
                issuerActivity={this.state.registerData.company.hqActivity}
                issuerBranchLabel='Sucursal'
                issuerBranch={this.props.store.address}
                receiverName={this.state.registerData.client.name}
                receiverPinLabel='RUT'
                receiverPin={Pin.format(this.state.registerData.client.pin)}
                receiverActivityLabel='Giro'
                receiverActivity={this.state.registerData.client.activityName}
                receiverAddressLabel='Dirección'
                receiverAddress={this.state.registerData.client.street}
                receiverDistrictLabel='Comuna'
                receiverDistrict={this.state.registerData.client.district}
                receiverCityLabel='Ciudad'
                receiverCity={this.state.registerData.client.cityName}
                receiverStateLabel='Región'
                receiverState={this.state.registerData.client.stateName}
                additionalInstructions='Lleve este presupuesto a caja para finalizar la venta'
                expirationMessage='Documento válido sólo por 24 horas desde la fecha de emisión'
                shoppingCart={this.props.shoppingCart}
                showTax={true}
                unitLabel=' c/u'
                onRenderComplete={this.onDocumentLoaded}
            />
        );
    };

    renderWaitingPayment = () => {
        return (
            <WaitingPayment
                title1='Paga en el POS'
                title2='Esperando confirmación de pago'
                titleLineBreak={true}
                timerImage={this.props.timerImage || defaultTimerImage}
                remainingTime={this.state.maxPaymentTime - this.state.elapsedPaymentTime}
            />
        );
    };

    renderPaymentExpired = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message='No se ha recibido confirmación del pago'
                    actionLabel='Reintentar'
                    action={this.retryPayment}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    secondaryActionLabel='Cerrar'
                    secondaryAction={() => this.handleClose('payment-expired')}
                    secondaryActionButtonLabelColor={this.props.cancelButtonLabelColor}
                    secondaryActionButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                    secondaryActionButtonBorderColor={this.props.cancelButtonBorderColor}
                    maxWidth={800}
                />
            </div>
        );
    };

    renderPaymentError = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message={this.state.paymentErrorMessage}
                    actionLabel='Reintentar'
                    action={this.retryPayment}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    secondaryActionLabel='Cerrar'
                    secondaryAction={() => this.handleClose('payment-error')}
                    secondaryActionButtonLabelColor={this.props.cancelButtonLabelColor}
                    secondaryActionButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                    secondaryActionButtonBorderColor={this.props.cancelButtonBorderColor}
                    maxWidth={800}
                />
            </div>
        );
    };

    renderPaymentSuccess = () => {
        return (
            <PaymentMessage
                isLoading={false}
                loaded={true}
                showPreview={false}
                preview={undefined}
                previewSize={undefined}
                title1={undefined}
                title2='Pago confirmado'
                loadingMessage={undefined}
                step1Message1='Imprime tu documento'
                step1Message2=' y acércate al mesón de atención para retirar tus productos'
                step1Image={this.props.paymentSuccessImage || defaultPaymentSuccessImage}
                showStep2={false}
                step2Message1={undefined}
                step2Message2={undefined}
                step2Image={undefined}
                customStepWidth='100%'
                errorMessage={undefined}
                actionLabel='Imprimir'
                actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                actionButtonBorderColor={this.props.actionButtonBorderColor}
                actionButtonLabelColor={this.props.actionButtonLabelColor}
                cancelButtonBackgroundColor={this.props.cancelButtonBackgroundColor}
                cancelButtonBorderColor={this.props.cancelButtonBorderColor}
                cancelButtonLabelColor={this.props.cancelButtonLabelColor}
                disableCloseButton={!this.state.paymentDocumentPrinted}
                onClose={undefined}
                onSubmit={this.printPaymentDocument}
                submitted={false}
                submitSuccess={false}
                submitSuccessMessage={undefined}
                submitErrorMessage={undefined}
            />
        );
    };

    renderPrintError = () => {
        return (
            <div className='integrated-pos-error'>
                <DataError
                    customImage={this.props.getHelpErrorImage}
                    customImageBorderRadius='100%'
                    message={this.state.printErrorMessage}
                    actionLabel='Cerrar'
                    action={() => this.handleClose('print-error')}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    maxWidth={800}
                />
            </div>
        )
    };

    renderUnknownError = () => {
        return (
            <div className='integrated-pos-error'>
                <DataError
                    message='Se ha producido un error inesperado, por favor comuníquese con un administrador'
                    actionLabel='Cerrar'
                    action={() => this.handleClose('unknown-error')}
                    actionButtonLabelColor={this.props.actionButtonLabelColor}
                    actionButtonBackgroundColor={this.props.actionButtonBackgroundColor}
                    actionButtonBorderColor={this.props.actionButtonBorderColor}
                    maxWidth={800}
                />
            </div>
        );
    };

    render() {
        return (
            <div ref={this._containerRef} className='integrated-pos-container'>
                {this.renderStep()}
            </div>
        );
    };
}

IntegratedPOS.propTypes = {
    client: PropTypes.string,
    instance: PropTypes.object,
    store: PropTypes.object,
    shoppingCart: PropTypes.object,
    orderNumber: PropTypes.string,
    paymentNumber: PropTypes.string,
    pk: PropTypes.string,
    taxMultiplier: PropTypes.number,
    mainClientLogo: PropTypes.string,
    personOptionImage: PropTypes.string,
    companyOptionImage: PropTypes.string,
    cardOptionImage: PropTypes.string,
    otherPaymentOptionImage: PropTypes.string,
    ticketOptionImage: PropTypes.string,
    invoiceOptionImage: PropTypes.string,
    timerImage: PropTypes.string,
    printMessageImage: PropTypes.string,
    paymentSuccessImage: PropTypes.string,
    getHelpErrorImage: PropTypes.string,
    actionButtonBackgroundColor: PropTypes.string,
    actionButtonBorderColor: PropTypes.string,
    actionButtonLabelColor: PropTypes.string,
    cancelButtonBackgroundColor: PropTypes.string,
    cancelButtonBorderColor: PropTypes.string,
    cancelButtonLabelColor: PropTypes.string,
    backButtonBackgroundColor: PropTypes.string,
    backButtonBorderColor: PropTypes.string,
    backButtonLabelColor: PropTypes.string,
    customInputOutline: PropTypes.string,
    customInputClearButtonBackgroundColor: PropTypes.string,
    customInputClearButtonLabelColor: PropTypes.string,
    inSmallMode: PropTypes.bool,
    onClose: PropTypes.func,
    allowClosing: PropTypes.func,
    preventClosing: PropTypes.func,
    flowFinished: PropTypes.func,
    openShoppingCart: PropTypes.func,
    metadata: PropTypes.object
};