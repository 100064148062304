import React from 'react';
import ImageWrapper from '../../../../ImageWrapper/ImageWrapper';
import './PaymentOption.css';

function PaymentOption({
    type,
    icon,
    label1,
    label2,
    boldLabel1,
    boldLabel2,
    labelLineBreak,
    borderRadius,
    showShadow,
    waitingMode,
    waitingModeIcon,
    waitingModeLabel,
    customWidth,
    disabled,
    onClick
}) {
    const allowAction = () => {
        return !waitingMode && !disabled && !!onClick;
    };

    const handleClick = () => {
        if (allowAction()) onClick(type);
    };

    const containerStyle = () => {
        return {
            width: customWidth ? customWidth : 300
        };
    };

    const labelContainerStyle = () => {
        return {
            margin: label2 ? '20px 0 0 0' : '40px 0 0 0'
        };
    };

    const labelStyle = (bold) => {
        return {
            fontWeight: bold ? 900 : 400
        };
    };

    return (
        <div className={`payment-option${allowAction() ? ' hover' : ''}${showShadow ? ' shadow' : ''}`} onClick={handleClick} style={containerStyle()}>
            {disabled && <div className='payment-option-unavailable'>No disponible</div>}
            <div className={`payment-option-wrapper${disabled ? ' disabled' : ''}`}>
                {icon && (
                    <div className='payment-option-icon'>
                        <ImageWrapper image={icon} alt={type} display='block' width='100%' height='auto' loadDelay={0.2} borderRadius={borderRadius || 0}/>
                    </div>
                )}
                <p className='payment-option-label' style={labelContainerStyle()}>
                    {label1 && <span className='payment-option-label-item' style={labelStyle(boldLabel1)}>{label1}</span>}
                    {labelLineBreak && <br/>}
                    {label2 && <span className='payment-option-label-item' style={labelStyle(boldLabel2)}>{label2}</span>}
                </p>
                {waitingMode && (
                    <div className='payment-option-timer'>
                        <img className='payment-option-timer-icon' src={waitingModeIcon} alt='Waiting'/>
                        <div className='payment-option-timer-label'>{waitingModeLabel}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PaymentOption;