import WebServices from './WebServices';
import Activity from './Activity';
import {TRANSBANK_POS_LAST_SALE_URL, TRANSBANK_POS_POLL_URL, TRANSBANK_POS_SALE_URL} from '../config/App';

export default class TransbankPOS {
    static status = () => {
        return fetch(TRANSBANK_POS_POLL_URL, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, signal: AbortSignal.timeout(3000)})
            .then(response => WebServices.onResponse(response))
            .then(response => {
                if (response) return response.status;
                else return false;
            })
            .catch(() => {
                return false;
            });
    };

    static startSale = (metadata, amount, ticket, print, timeout, signal) => {
        return new Promise((resolve) => {
            const body = {Amount: amount, Ticket: ticket, Print: print ? 1 : 0};
            Activity.log(metadata, 'checkout', 'pos-sale', 'start', body);
            return fetch(TRANSBANK_POS_SALE_URL, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, signal: signal, body: JSON.stringify(body)})
                .then(response => WebServices.onResponse(response))
                .then(response => {
                    Activity.log(metadata, 'checkout', 'pos-sale', 'finish', response);
                    setTimeout(() => {
                        if (response) {
                            response["cardType"] = response["cardType"] || "DB"; // CR for "credit" or DB for "debit". Fallback case will be "debit".
                            resolve(response);
                        } else resolve(undefined);
                    }, 1000 * timeout);
                })
                .catch(() => {
                    resolve(undefined);
                });
        });
    };

    // noinspection JSUnusedGlobalSymbols
    static getLastSale = () => {
        return fetch(TRANSBANK_POS_LAST_SALE_URL, {method: 'GET', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}})
            .then(response => WebServices.onResponse(response))
            .then(response => {
                if (response) return response;
                else return undefined;
            })
            .catch(() => {
                return undefined;
            });
    };
}