import React, {useEffect, useState} from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Viewport from '../../helpers/Viewport';
import Activity from "../../helpers/Activity";
import Environment from '../../config/Environment';
import {algoliaSearchClient, itemSearchSettings} from '../../config/AlgoliaSearch';
import {autocomplete, getAlgoliaResults} from '@algolia/autocomplete-js';
import searchGreyIcon from './images/search-grey.png';
import searchBlackIcon from './images/search-black.png';
import '@algolia/autocomplete-theme-classic';
import './SearchModal.css';

function SearchModal({
    client,
    catalog,
    inputBorderColor,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    onSearch,
    onClose,
    metadata
}) {
    const [query, setQuery] = useState('');
    const smallModeTreshold = {width: 768, height: 600};
    const viewport = Viewport.dimensions;
    const environment = Environment.current;
    const indexName = `${environment}_${client}_items`;
    const minQueryLength = 3;

    useEffect(() => {
        const {destroy} = autocomplete({
            container: '#search-modal-input',
            panelContainer: '#search-modal-suggestions',
            panelPlacement: 'full-width',
            placeholder: 'Buscar...',
            openOnFocus: false,
            autoFocus: false,
            onStateChange({state}) {
                setQuery(state.query);
            },
            onSubmit(params) {
                handleSearch(params.state.query);
            },
            getSources({query}) {
                // noinspection JSUnusedGlobalSymbols
                return [{
                    sourceId: 'items',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient: algoliaSearchClient,
                            queries: [{
                                indexName: indexName,
                                query: query,
                                params: itemSearchSettings(catalog, 30)
                            }],
                        });
                    },
                    templates: {
                        item({item, createElement, Fragment, components}) {
                            return renderSuggestion(item, createElement, Fragment, components);
                        }
                    }
                }];
            }
        });
        setTimeout(() => {
            focusSearchInput();
            preventSuggestionsPanelClose();
        });
        return () => {
            if (destroy) destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const focusSearchInput = () => {
        const input = document.getElementsByClassName('aa-Input')[0];
        if (input) input.focus();
    };

    const preventSuggestionsPanelClose = () => {
        const input = document.getElementsByClassName('aa-Input')[0];
        if (input) {
            input.addEventListener('blur', () => {
                input.focus();
            });
        }
    };

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const validQuery = (query) => {
        return query && query.length >= minQueryLength;
    };

    const handleSearch = (query) => {
        if (onSearch && validQuery(query)) {
            Activity.log(metadata, 'search-modal', 'search-button', 'search', {query: query})
                .then(() => {onSearch(query, undefined)});
        }
        silentClose();
    };

    const handleSuggestionClick = (value, index) => {
        if (onSearch) {
            Activity.log(metadata, 'search-modal', 'search-suggestion', 'search', {suggestion: value})
                .then(() => {onSearch(value, index + 1)});
        }
        silentClose();
    };

    const silentClose = () => {
        if (onClose) onClose();
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'search-modal', closeType, 'close', undefined);
            onClose();
        }
    };

    const inputStyle = () => {
        const inFocus = document.activeElement === document.getElementsByClassName('aa-Input')[0];
        return {
            border: `2px solid ${inFocus ? (inputBorderColor || '#005B80') : '#B2B2B2'}`
        };
    };

    const renderSuggestion = (hit, createElement, Fragment, components) => {
        const image = createElement('img', {className: 'suggestion-icon', src: `${searchBlackIcon}`, alt: 'Suggestion'});
        const label = createElement('p', {className: 'suggestion-text', style: {color: '#414042'}}, components.Highlight({hit: hit, attribute: 'name'}));
        const container = createElement('div', {className: 'suggestion', onClick: () => handleSuggestionClick(hit.name, hit['__autocomplete_id'])}, [image, label]);
        return createElement(Fragment, null, container);
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={40} width={800} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            <div className='search-modal'>
                <p className='search-modal-title'>Buscar</p>
                <p className='search-modal-description'>Ingresa el criterio de búsqueda para encontrar los productos perfectos para ti</p>
                <div id='search-modal-input' className='search-modal-input' style={inputStyle()}>
                    <img className='search-modal-input-icon' src={`${searchGreyIcon}`} alt='Search'/>
                </div>
                {!validQuery(query) && <p className='search-modal-input-guide'>Ingrese 3 o más caracteres para una búsuqeda más precisa</p>}
                <div id='search-modal-suggestions' className='search-modal-suggestions'>
                </div>
                <div className='search-modal-actions'>
                    <div className='search-modal-action'>
                        <Button label='Cerrar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={() => handleClose('custom-close-button')}/>
                    </div>
                    <div className='search-modal-action'>
                        <Button label='Buscar' labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' disabled={!validQuery(query)} onClick={() => handleSearch(query)}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SearchModal;