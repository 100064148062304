import algoliaSearch from 'algoliasearch/lite';
import {ALGOLIA_APP_ID, ALGOLIA_API_KEY} from "./App";
import {Feature as _Feature, Measure as _Measure, Multimedia as _Multimedia, MultimediaItem as _MultimediaItem, Product as _Product} from "../models/Product";
import Environment from './Environment';
import WebServices from '../helpers/WebServices';
import Storage from "../helpers/Storage";

export const algoliaSearchClient = algoliaSearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

export const itemSearchSettings = (catalog, hitsPerPage) => {
    const categories = catalog ? catalog.categories : [];
    let categoryQuery = '';
    categories.forEach((category, index) => {
        categoryQuery += `categories:${category}`;
        if (index < categories.length - 1) categoryQuery += ' OR ';
    });
    const query = {
        filters: categoryQuery,
        clickAnalytics: true
    };
    if (hitsPerPage) query.hitsPerPage = hitsPerPage;
    return query;
};

export const getRecommendedItems = (client, model, productId, threshold, maxRecommendations) => {
    const url = `https://${ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/*/recommendations`;
    const environment = Environment.current;
    const indexName = `${environment}_${client}_items`;
    const body = {
        requests: [{
            indexName: indexName,
            model: model,
            threshold: threshold,
            maxRecommendations: maxRecommendations,
            objectID: productId
        }]
    };
    return fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'X-Algolia-Application-Id': ALGOLIA_APP_ID,
            'X-Algolia-API-Key': ALGOLIA_API_KEY
        },
        body: JSON.stringify(body)
    })
        .then((response) => {
            return WebServices.onResponse(response);
        })
        .then((response) => {
            if (!response) return undefined;
            const hits = response['results'][0]['hits'];
            return hits.map((hit) => buildProductObject(hit));
        })
        .catch(() => {
            return [];
        });
}

export const buildProductObject = (hit) => {
    const prices = getSearchMainPriceGroup(hit['prices']);
    const stock = getSearchStoreMaxPerItem(hit['stocks']);
    const deliveryOptions = hit['delivery'] && hit['delivery'].length > 0 ? hit['delivery'] : [];
    const variants = buildSearchVariantsData(hit['variants']);
    const variantsMatches = getSearchVariantsMatches(hit['_highlightResult']);
    return new _Product({
        id: hit['objectID'],
        sku: hit['sku'],
        ean: undefined,
        name: hit['name'],
        brand: hit['brand'],
        description: hit['description'],
        longDescription: undefined,
        isFeatured: hit['isFeatured'],
        categories: hit['categories'],
        categoryPaths: undefined,
        multimedia: hit['image'] ? new _Multimedia({gallery: undefined, mainImage: new _MultimediaItem({m: hit['image']})}) : undefined,
        createdAt: undefined,
        updatedAt: undefined,
        originUpdatedAt: undefined,
        normalPrice: prices.normal ? prices.normal.value : undefined,
        offerPrice: prices.offer ? prices.offer.value : undefined,
        exclusivePrice: prices.exclusive ? prices.exclusive.value : undefined,
        sellerName: hit['seller'] && hit['seller'].length > 0 ? hit['seller'][1] : undefined,
        externalSale: hit['seller'] && hit['seller'].length > 0 ? hit['seller'][0] : false,
        externalSaleUrl: undefined,
        clientDelivery: hit['deliveredBy'] && hit['deliveredBy'].length > 0 ? hit['deliveredBy'][0] : false,
        internationalShipping: hit['deliveredBy'] && hit['deliveredBy'].length > 0 ? hit['deliveredBy'][1] : false,
        onRequest: false,
        maxPerItem: stock,
        saleUnit: undefined, // Not required to index in Algolia, it is shown only in the product page.
        has3dModel: hit['has3dModel'],
        delivery: deliveryOptions.includes('Shipping'),
        freeDelivery: deliveryOptions.includes('FreeShipping'),
        pickup: deliveryOptions.includes('Pickup'),
        features: hit['features'] && hit['features'].length > 0 ? hit['features'].map((feature) => new _Feature({name: feature[0], value: feature[1], order: feature[2], indexable: feature[3], visible: true})) : [],
        measures: hit['measures'] && hit['measures'].length > 0 ? hit['measures'].map((measure) => new _Measure({name: measure[0], value: measure[1], unit: measure[2]})) : [],
        variants: variants,
        variantsMatches: variantsMatches,
        order: 0,
        score: hit['_score']
    });
};

export const buildVariantObject = (hit) => {
    const product = buildProductObject(hit);
    if (!product.variantsMatches || product.variantsMatches.length === 0) return product;
    const firstVariantId = product.variantsMatches[0];
    const firstVariant = product.variants.find((variant) => variant.id === firstVariantId);
    if (!firstVariant) return product;
    const pricesSource = firstVariant.hasAnyPrice() ? firstVariant : product;
    const multimedia = firstVariant.multimedia ? firstVariant.multimedia : product.multimedia;
    return new _Product({
        parent: product.id,
        id: firstVariant.id,
        sku: firstVariant.sku || product.sku,
        ean: product.ean,
        name: firstVariant.name || product.name,
        brand: product.brand,
        description: product.description,
        longDescription: product.longDescription,
        isFeatured: product.isFeatured,
        categories: product.categories,
        categoryPaths: product.categoryPaths,
        multimedia: multimedia,
        createdAt: product.createdAt,
        updatedAt: product.updatedAt,
        originUpdatedAt: product.originUpdatedAt,
        normalPrice: pricesSource.normalPrice,
        offerPrice: pricesSource.offerPrice,
        exclusivePrice: pricesSource.exclusivePrice,
        sellerName: product.sellerName,
        externalSale: product.externalSale,
        externalSaleUrl: product.externalSaleUrl,
        clientDelivery: product.clientDelivery,
        internationalShipping: product.internationalShipping,
        onRequest: false,
        maxPerItem: product.maxPerItem, // Use the parent value, so the user can see other options in case the matched variant has no stock.
        saleUnit: product.saleUnit,
        has3dModel: product.has3dModel,
        viewItSceneUrl: undefined,
        viewItArUrl: undefined,
        delivery: product.delivery,
        freeDelivery: product.freeDelivery,
        pickup: product.pickup,
        features: product.features,
        measures: product.measures,
        variants: [],
        variantsMatches: [],
        order: product.order,
        score: product.score
    });
};

export const getSearchMainPriceGroup = (pricesData) => {
    let response = {normal: undefined, offer: undefined, exclusive: undefined};
    const storeId = Storage.getStore();
    let source;
    if (storeId && pricesData) {
        const prices = pricesData.filter(priceData => priceData[0] === storeId);
        if (prices && prices.length > 0) source = prices;
    }
    if (!source) {
        const backupStores = Storage.getBackupStores();
        for (let i = 0; i < backupStores.length; i++) {
            const backupStoreId = backupStores[i];
            let backupPrices = undefined;
            if (pricesData && pricesData.length > 0) backupPrices = pricesData.filter(priceData => priceData[0] === backupStoreId);
            if (backupPrices && backupPrices.length > 0) {
                source = backupPrices;
                break;
            }
        }
    }
    if (source) {
        const normalPrice    = source.find(price => price[1] === 'normal'   );
        const offerPrice     = source.find(price => price[1] === 'sale'     );
        const exclusivePrice = source.find(price => price[1] === 'exclusive');
        response.normal    = normalPrice    ? {type:    normalPrice[1], value:    normalPrice[2]} : undefined;
        response.offer     = offerPrice     ? {type:     offerPrice[1], value:     offerPrice[2]} : undefined;
        response.exclusive = exclusivePrice ? {type: exclusivePrice[1], value: exclusivePrice[2]} : undefined;
    }
    return response;
};

export const getSearchStoreMaxPerItem = (stockData) => {
    const storeId = Storage.getStore();
    let maxPerItem = 0;
    let source;
    if (storeId && stockData) {
        const match = stockData.find((item) => item[0] === storeId);
        if (match) source = match;
    }
    if (!source) {
        const backupStores = Storage.getBackupStores();
        for (let i = 0; i < backupStores.length; i++) {
            const backupStoreId = backupStores[i];
            let backupMatch = undefined;
            if (stockData && stockData.length > 0) backupMatch = stockData.find((item) => item[0] === backupStoreId);
            if (backupMatch) {
                source = backupMatch;
                break;
            }
        }
    }
    if (source) maxPerItem = source[1];
    return maxPerItem;
};

export const buildSearchVariantsData = (variantsData) => {
    let variants = [];
    if (variantsData && variantsData.length > 0) {
        variantsData.forEach((variant) => {
            const variantId = variant[0];
            if (variantId) {
                const variantPrices = getSearchMainPriceGroup(variant[5]);
                const variantStock = getSearchStoreMaxPerItem(variant[6]);
                variants.push(
                    new _Product({
                        id: variantId,
                        sku: variant[1] || undefined,
                        name: variant[2] || undefined,
                        normalPrice: variantPrices.normal ? variantPrices.normal.value : undefined,
                        offerPrice: variantPrices.offer ? variantPrices.offer.value : undefined,
                        exclusivePrice: variantPrices.exclusive ? variantPrices.exclusive.value : undefined,
                        maxPerItem: variantStock,
                        saleUnit: undefined, // Not required to index in Algolia, it is shown only in the product page.
                        multimedia: variant[3] ? new _Multimedia({gallery: undefined, mainImage: new _MultimediaItem({m: variant[3]})}) : undefined,
                        onRequest: false
                    })
                );
            }
        });
    }
    return variants;
};

export const getSearchVariantsMatches = (highlights) => {
    let variantsMatches = [];
    if (highlights && highlights['variants'] && highlights['variants'].length > 0) {
        highlights['variants'].forEach((variantHighlight) => {
            const match = variantHighlight.find((variantHighlightItem) => {
                const matchLevel = variantHighlightItem['matchLevel'];
                return matchLevel && matchLevel === 'full';
            });
            if (match) {
                const variantId = variantHighlight[0]['value'];
                if (variantId) variantsMatches.push(variantId);
            }
        });
    }
    return variantsMatches;
};