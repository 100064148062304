import React, {useState} from 'react';
import PaymentStepActions from '../PaymentStepActions/PaymentStepActions';
import CustomInput from '../../../CustomInput/CustomInput';
import Pin from '../../../../helpers/Pin';
import './PaymentIdentification.css';

function PaymentIdentification({
    isLoading,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    customInputOutline,
    customInputClearButtonBackgroundColor,
    customInputClearButtonLabelColor,
    onClose,
    onSubmit
}) {
    const [pin, setPin] = useState('');
    const [error, setError] = useState(false);
    const inputMargin = 70;
    const errorMargin = 25;
    const errorHeight = 18;

    const handlePinChange = (data) => {
        setPin(data.value);
    };

    const handlePinFocus = () => {
        setError(false);
    };

    const handlePinEnterKeyPress = () => {
        handleSubmit();
    };

    const handleSubmit = () => {
        if (Pin.valid(pin)) {
            if (onSubmit) onSubmit(pin);
        } else setError(true);
    };

    const handleClose = () => {
        if (onClose) onClose('custom-close-button');
    };

    return (
        <div className='payment-identification'>
            <h2 className='payment-identification-title'>¿Tienes cuenta con nosotros?</h2>
            <h2 className='payment-identification-description'>Ingresa tu RUT</h2>
            <div className='payment-identification-input-container' style={{margin: `${inputMargin}px auto ${error ? `${(inputMargin - (errorMargin + errorHeight))}px` : '70px'} auto`}}>
                <CustomInput
                    id='config-company'
                    label='RUT'
                    insetLabel={true}
                    placeholder='Ej: 12.345.678-9'
                    showClearButton={true}
                    value={pin}
                    formatFunction={Pin.format}
                    focusBorderColor={customInputOutline}
                    clearButtonBackgroundColor={customInputClearButtonBackgroundColor}
                    clearButtonLabelColor={customInputClearButtonLabelColor}
                    isAddressInput={false}
                    disabled={false}
                    onFocus={handlePinFocus}
                    onChange={handlePinChange}
                    onAnyKeyPress={undefined}
                    onEnterKeyPress={handlePinEnterKeyPress}
                />
                {error && (
                    <p className='payment-identification-input-error' style={{height: errorHeight, margin: `${errorMargin}px 0`}}>El RUT especificado no es válido</p>
                )}
            </div>
            <PaymentStepActions
                isLoading={isLoading}
                loadingLabel='Verificando...'
                submitLabel='Continuar'
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={undefined}
                backButtonBorderColor={undefined}
                backButtonLabelColor={undefined}
                submitButtonBackgroundColor={actionButtonBackgroundColor}
                submitButtonBorderColor={actionButtonBorderColor}
                submitButtonLabelColor={actionButtonLabelColor}
                showCloseButton={!!onClose}
                showBackButton={false}
                showSubmitButton={true}
                onClose={handleClose}
                onBack={undefined}
                onSubmit={handleSubmit}
            />
        </div>
    );
}

export default PaymentIdentification;