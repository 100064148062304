import Debug from './Debug';
import Environment from '../config/Environment';
import Storage from './Storage';
import ProtectedWebServices from './ProtectedWebServices';
import {Product} from '../models/Product';
import {
    GET_SERVER_TIMESTAMP_URL,
    CLIENT_CHECKOUT_URL,
    UPDATE_PRODUCT_URL,
    GET_CLIENT_ENTITY_URL,
    GET_SII_ENTITY_URL,
    GET_STATES_URL,
    GET_DISTRICTS_URL,
    GET_CITIES_URL,
    GET_ACTIVITIES_URL,
    GET_HQ_DATA_URL,
    GET_SEGMENTS_URL,
    SAVE_CUSTOMER_URL,
    SAVE_ORDER_V2_URL
} from '../config/App';

export default class WebServices {
    static getServerTimestamp = () => {
        const url = `${GET_SERVER_TIMESTAMP_URL}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response.timestamp;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getClientShoppingCartUrl = (clientId, storeId, shoppingCart, externalSale) => {
        const environment = Environment.current;
        const url = `${CLIENT_CHECKOUT_URL}?environment=${environment}&client=${clientId}`;
        let body = shoppingCart.toCheckout();
        body.store_id = storeId;
        body.external_sale_flow = externalSale;
        return fetch(url, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)})
            .then(response => this.onResponse(response, true))
            .then(response => {
                if (response) {

                    // TODO: [Walmart] Check that this covers all possible errors from external checkout.
                    const id = response['id'] || undefined;
                    const url = response['checkout_url'] || undefined;
                    const orderNumber = response['external_order_id'] || undefined;
                    const paymentNumber = response['checkout_document'] || undefined;
                    const type = response['details'] && response['details'] && response['details']['type'] ? response['details']['type'] : undefined;
                    const message = response['details'] && response['details'] && response['details']['message'] ? response['details']['message'] : undefined;
                    const status = response['details'] && response['details'] && response['details']['status'] ? response['details']['status'] : undefined;
                    const error = status ? {type: type, message: message, status: status} : undefined;
                    return {id: id, url: url, orderNumber: orderNumber, paymentNumber: paymentNumber, error: error};

                    // No-Stock test.
                    // return {
                    //     id: undefined,
                    //     url: undefined,
                    //     orderNumber: undefined,
                    //     paymentNumber: undefined,
                    //     error: {
                    //         type: 'NO_STOCK',
                    //         message: 'Productos sin stock',
                    //         status: 520
                    //     }
                    // };

                } else return undefined;
            })
            .catch(() => {
                return undefined;
            });
    };

    static startProductExtraction = (clientId, products) => {
        const environment = Environment.current;
        const productsQuery = products && products.length > 0 ? `&${products.map(product => `skus[]=${product.type === 'variant' ? product.parent : product.id}`).join('&')}` : '';
        const url = `${UPDATE_PRODUCT_URL}?environment=${environment}&client=${clientId}&persist=true${productsQuery}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                if (response && response['items'] && response['items']['data']) { // Do not include response.items.data.length > 0, because an empty array is a valid case (when it happens, it means all products in the cart are out of stock).
                    const productsData = response['items']['data'];
                    for (let i = 0; i < products.length; i++) {
                        const product = products[i];
                        const parent = product.parent ? product.parent : product.id;
                        const productData = productsData.find((data) => data.id === parent);
                        if (!productData) continue;
                        if (product.parent) { // Variant flow.
                            const variantData = productData.variants.find((data) => data.id === product.id);
                            if (!variantData) continue;
                            product.maxPerItem = Product.getDatabaseStoreMaxPerItem(variantData.info_stores);
                        } else { // Parent flow.
                            product.maxPerItem = Product.getDatabaseStoreMaxPerItem(productData.info_stores);
                        }
                    }
                    return products;
                } else return undefined;
            })
            .catch((error) => {
                Debug.printToLog('error', `Failed to update products for client ${clientId}: ${error}`);
                return undefined;
            });
    };

    static getClientEntity = (pin) => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_CLIENT_ENTITY_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}&pin=${encodeURIComponent(pin)}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getSiiEntity = (pin) => {
        const url = `${GET_SII_ENTITY_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&pin=${encodeURIComponent(pin)}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getActivities = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_ACTIVITIES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getStates = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_STATES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getDistricts = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_DISTRICTS_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getCities = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_CITIES_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getHQData = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_HQ_DATA_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static getSegments = () => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const url = `${GET_SEGMENTS_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return undefined;
            });
    };

    static saveCustomer = ({entity, document, segment, pin, name, phone, email, activity, state, city, district, street}) => {
        const environment = Environment.current;
        const client = Storage.getClient();
        const body = {entity, document, segment, pin, name, phone, email, activity, state, city, district, street};
        const url = `${SAVE_CUSTOMER_URL}?p=g7InbLgZXnAiax0kzLOtLrPSruaepVq9&environment=${environment}&client=${client}`;
        return fetch(url, {method: 'POST', mode: 'cors', cache: 'no-cache', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)})
            .then(response => this.onResponse(response))
            .then(response => {
                return response;
            })
            .catch(() => {
                return {status: false};
            });
    };

    static saveOrder = ({id, instance, session, pin, name, phone, email, store, quote, document, state, city, district, street, status, cardType, pk}) => {
        const environment = Environment.current;
        const client = Storage.getClient();
        if (!pk) {
            Debug.printToLog('error', 'Invalid PK');
            return Promise.resolve({status: false});
        }
        const data = {id, instance, session, pin, name, phone, email, store, quote, document, state, city, district, street, status, cardType};
        const url = `${SAVE_ORDER_V2_URL}?environment=${environment}&client=${client}`;
        return ProtectedWebServices.makeRequest(pk, url, 'POST', data)
            .then(response => {
                if (response) return response;
                else return {status: false};
            });
    };

    static onResponse = (response, parseErrors = false) => {
        if (response.ok || parseErrors) {
            try {
                return response.json();
            } catch (error) {
                Debug.printToLog('error', `Unable to parse JSON response for endpoint ${response.url}`);
                return undefined;
            }
        } else {
            // Debug.printToLog('error', `Server returned status code ${response.status} for endpoint: ${response.url}`); // Redundant because Chrome already prints this information.
            return undefined;
        }
    };
}