import {ShoppingCart} from '../models/ShoppingCart';
import Debug from "./Debug";

export default class Storage {
    static getLatestUpdate() {
        let value = localStorage.getItem('kiosk_latest_update');
        try {
            value = parseInt(value);
        } catch (error) {
            value = new Date().getTime();
        }
        return value;
    };

    static setLatestUpdate(timestamp) {
        localStorage.setItem('kiosk_latest_update', timestamp);
    };

    static getClient() {
        return localStorage.getItem('kiosk_client');
    };

    static setClient(instance) {
        localStorage.setItem('kiosk_client', instance);
    };

    static removeClient() {
        localStorage.removeItem('kiosk_client');
    };

    static getInstance() {
        return localStorage.getItem('kiosk_instance');
    };

    static setInstance(instance) {
        localStorage.setItem('kiosk_instance', instance);
    };

    static removeInstance() {
        localStorage.removeItem('kiosk_instance');
    };

    static getStore() {
        return localStorage.getItem('kiosk_store');
    };

    static setStore(store) {
        localStorage.setItem('kiosk_store', store);
    };

    static removeStore() {
        localStorage.removeItem('kiosk_store');
    };

    static getBackupStores() {
        const value = localStorage.getItem('kiosk_backup_stores');
        try {
            return value ? value.split(',') : [];
        } catch (error) {
            Debug.printToLog('error', `Invalid backup stores '${value}', reverting to default. ${error}`);
            return [];
        }
    };

    static setBackupStores(store) {
        localStorage.setItem('kiosk_backup_stores', store);
    };

    static removeBackupStores() {
        localStorage.removeItem('kiosk_backup_stores');
    };

    static getSession() {
        return localStorage.getItem('kiosk_session');
    };

    static setSession(session) {
        localStorage.setItem('kiosk_session', session);
    };

    static removeSession() {
        localStorage.removeItem('kiosk_session');
    };

    // TODO: Check Nan.
    static getActionBarPosition() {
        let position = localStorage.getItem('kiosk_action_bar_position');
        if (position === undefined || position === null || position.length === 0) return undefined;
        try {
            return parseInt(position);
        } catch (error) {
            Debug.printToLog('error', `Invalid action bar position '${position}', reverting to default. ${error}`);
            return undefined;
        }
    };

    static setActionBarPosition(position) {
        localStorage.setItem('kiosk_action_bar_position', position);
    };

    static removeActionBarPosition() {
        localStorage.removeItem('kiosk_action_bar_position');
    };

    static getCategoryFilters() {
        const categoryFilters = localStorage.getItem('kiosk_category_filters');
        try {
            return categoryFilters ? JSON.parse(categoryFilters) : undefined;
        } catch (error) {
            Debug.printToLog('error', `Invalid category filters '${categoryFilters}', reverting to default. ${error}`);
            return undefined;
        }
    };

    static setCategoryFilters(categoryFilters) {
        localStorage.setItem('kiosk_category_filters', JSON.stringify(categoryFilters));
    };

    static removeCategoryFilters() {
        localStorage.removeItem('kiosk_category_filters');
    };

    static getQuickFilters() {
        const quickFilters = localStorage.getItem('kiosk_quick_filters');
        try {
            return quickFilters ? JSON.parse(quickFilters) : undefined;
        } catch (error) {
            Debug.printToLog('error', `Invalid quick filters '${quickFilters}', reverting to default. ${error}`);
            return undefined;
        }
    };

    static setQuickFilters(quickFilters) {
        localStorage.setItem('kiosk_quick_filters', JSON.stringify(quickFilters));
    };

    static removeQuickFilters() {
        localStorage.removeItem('kiosk_quick_filters');
    };

    static getFilters() {
        const filters = localStorage.getItem('kiosk_filters');
        try {
            return filters ? JSON.parse(filters) : undefined;
        } catch (error) {
            Debug.printToLog('error', `Invalid filters '${filters}', reverting to default. ${error}`);
            return undefined;
        }
    };

    static setFilters(filters) {
        localStorage.setItem('kiosk_filters', JSON.stringify(filters));
    };

    static removeFilters() {
        localStorage.removeItem('kiosk_filters');
    };

    static getSorting() {
        const sorting = localStorage.getItem('kiosk_sorting');
        try {
            return sorting ? JSON.parse(sorting) : undefined;
        } catch (error) {
            Debug.printToLog('error', `Invalid sorting '${sorting}', reverting to default. ${error}`);
            return undefined;
        }
    };

    static setSorting(sorting) {
        localStorage.setItem('kiosk_sorting', JSON.stringify(sorting));
    };

    static removeSorting() {
        localStorage.removeItem('kiosk_sorting');
    };

    static getPage() {
        const page = localStorage.getItem('kiosk_page');
        try {
            return page ? JSON.parse(page) : undefined;
        } catch (error) {
            this.removePage();
            Debug.printToLog('error', `Invalid page '${page}', reverting to default. ${error}`);
            return undefined;
        }
    };

    static setPage(page) {
        localStorage.setItem('kiosk_page', JSON.stringify(page));
    };

    static removePage() {
        localStorage.removeItem('kiosk_page');
    };

    static getLastProduct() {
        return localStorage.getItem('kiosk_last_product');
    };

    static setLastProduct(productId) {
        localStorage.setItem('kiosk_last_product', productId);
    };

    static removeLastProduct() {
        localStorage.removeItem('kiosk_last_product');
    };

    static getAccessibilityMode() {
        return localStorage.getItem('kiosk_accessibility_mode') === 'true';
    };

    static setAccessibilityMode(accessibilityMode) {
        localStorage.setItem('kiosk_accessibility_mode', accessibilityMode);
    };

    static removeAccessibilityMode() {
        localStorage.removeItem('kiosk_accessibility_mode');
    };

    static getShoppingCart() {
        const data = localStorage.getItem('kiosk_shopping_cart');
        if (!data) return undefined;
        try {
            return ShoppingCart.fromStorage(JSON.parse(data));
        } catch (error) {
            Debug.printToLog('error', `Invalid shopping cart '${data}', reverting to default. ${error}`);
            return undefined;
        }
    };

    static setShoppingCart(shoppingCart) {
        localStorage.setItem('kiosk_shopping_cart', JSON.stringify(shoppingCart.toStorage()));
    };

    static removeShoppingCart() {
        localStorage.removeItem('kiosk_shopping_cart');
    };

    static getZoomLevel() {
        let zoomLevel = localStorage.getItem('kiosk_zoom_level');
        try {
           zoomLevel = zoomLevel ? parseFloat(zoomLevel) : 1.0;
           if (isNaN(zoomLevel)) throw new Error('Not a number');
           return zoomLevel;
        } catch (error) {
            this.setZoomLevel(1.0);
            Debug.printToLog('error', `Invalid zoom level '${zoomLevel}', reverting to default. ${error}`);
            return 1.0;
        }
    };

    static setZoomLevel(zoomLevel) {
        localStorage.setItem('kiosk_zoom_level', zoomLevel);
    };

    static removeZoomLevel() {
        localStorage.removeItem('kiosk_zoom_level');
    };

    static clearAll() {
        this.removeClient();
        this.removeInstance();
        this.removeStore();
        this.removeBackupStores();
        this.removeSession();
        this.removeActionBarPosition();
        this.removeCategoryFilters();
        this.removeQuickFilters();
        this.removeFilters();
        this.removeSorting();
        this.removePage();
        this.removeLastProduct();
        this.removeAccessibilityMode();
        this.removeShoppingCart();
        this.removeZoomLevel();
    };

    static clearSession() {
        this.removeSession();
        this.removeActionBarPosition();
        this.removeCategoryFilters();
        this.removeQuickFilters();
        this.removeFilters();
        this.removeSorting();
        this.removePage();
        this.removeLastProduct();
        this.removeAccessibilityMode();
        this.removeShoppingCart();
    };
}