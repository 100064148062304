import React from 'react';
import Button from '../Button/Button';
import ImageWrapper from "../ImageWrapper/ImageWrapper";
import defaultImage from './images/error.png';
import './DataError.css';

function DataError({
    message,
    customImage,
    customImageBorderRadius,
    action,
    actionLabel,
    actionButtonLabelColor,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonMinWidth,
    secondaryAction,
    secondaryActionLabel,
    secondaryActionButtonLabelColor,
    secondaryActionButtonBackgroundColor,
    secondaryActionButtonBorderColor,
    secondaryActionButtonMinWidth,
    maxWidth,
    padding
}) {
    const anyAction = () => {
        return (action && actionLabel) || (secondaryAction && secondaryActionLabel);
    };

    const hasAction = () => {
        return action && actionLabel;
    };

    const hasSecondaryAction = () => {
        return secondaryAction && secondaryActionLabel;
    };

    const handleActionClick = () => {
        if (action) action();
    };

    const handleSecondaryActionClick = () => {
        if (secondaryAction) secondaryAction();
    };

    const containerStyle = () => {
        return {
            padding: padding !== undefined ? padding : 20,
            maxWidth: maxWidth ? maxWidth : 300
        };
    };

    const renderButton = (label, labelColor, backgroundColor, borderColor, minWidth, boxShadow, onClick) => {
        return (
            <div className='data-error-action'>
                <Button label={label} labelColor={labelColor} backgroundColor={backgroundColor} borderColor={borderColor} minWidth={minWidth !== undefined ? minWidth : 'auto'} maxWidth='100%' boxShadow={boxShadow} onClick={onClick}/>
            </div>
        );
    };

    return (
        <div className='data-error' style={containerStyle()}>
            <div className='data-error-image-container'>
                <ImageWrapper image={customImage ? customImage : defaultImage} alt='Error' display='block' width={200} height='auto' loadDelay={0} borderRadius={customImageBorderRadius}/>
            </div>
            {message ? <p className='data-error-message'>{message}</p> : undefined}
            {anyAction() && (
                <React.Fragment>
                    <div className='data-error-actions'>
                        {hasSecondaryAction() && renderButton(
                            secondaryActionLabel ? secondaryActionLabel : '',
                            secondaryActionButtonLabelColor,
                            secondaryActionButtonBackgroundColor,
                            secondaryActionButtonBorderColor,
                            secondaryActionButtonMinWidth,
                            'unset',
                            handleSecondaryActionClick
                        )}
                        {hasAction() && renderButton(
                            actionLabel ? actionLabel : '',
                            actionButtonLabelColor,
                            actionButtonBackgroundColor,
                            actionButtonBorderColor,
                            actionButtonMinWidth,
                            undefined,
                            handleActionClick
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default DataError;