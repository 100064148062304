import React from 'react';
import PaymentOption from './PaymentOption/PaymentOption';
import PaymentStepActions from '../PaymentStepActions/PaymentStepActions';
import './PaymentOptionSelection.css';

function PaymentOptionSelection({
    title1,
    title2,
    titleLineBreak,
    showShadow,
    options,
    customOptionWidth,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    padding,
    onClose,
    onBack
}) {
    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleBack = () => {
        if (onBack) onBack();
    };

    const renderOptions = () => {
        return options.map((option) => (
            <PaymentOption
                key={option.type}
                type={option.type}
                icon={option.icon}
                label1={option.label1}
                label2={option.label2}
                boldLabel1={option.boldLabel1}
                boldLabel2={option.boldLabel2}
                labelLineBreak={option.labelLineBreak}
                borderRadius={option.borderRadius}
                showShadow={showShadow}
                waitingMode={option.waitingMode}
                waitingModeIcon={option.waitingModeIcon}
                waitingModeLabel={option.waitingModeLabel}
                customWidth={customOptionWidth}
                disabled={option.disabled}
                onClick={option.onClick}
            />
        ));
    };

    return (
        <div className='payment-option-selection' style={{padding: padding !== undefined ? padding : 30}}>
            <p className='payment-option-selection-title'>
                {title1}
                {title1 && titleLineBreak && <br/>}
                <span className='bold'>{title2}</span>
            </p>
            <div className='payment-option-selection-content'>
                {renderOptions()}
            </div>
            {(onBack || onClose) && (
                <PaymentStepActions
                    isLoading={false}
                    loadingLabel={undefined}
                    submitLabel={undefined}
                    cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                    cancelButtonBorderColor={cancelButtonBorderColor}
                    cancelButtonLabelColor={cancelButtonLabelColor}
                    backButtonBackgroundColor={backButtonBackgroundColor}
                    backButtonBorderColor={backButtonBorderColor}
                    backButtonLabelColor={backButtonLabelColor}
                    submitButtonBackgroundColor={undefined}
                    submitButtonBorderColor={undefined}
                    submitButtonLabelColor={undefined}
                    showCloseButton={!!onClose}
                    showBackButton={!!onBack}
                    showSubmitButton={false}
                    onClose={handleClose}
                    onBack={handleBack}
                    onSubmit={undefined}
                />
            )}
        </div>
    );
}

export default PaymentOptionSelection;