import Storage from "../helpers/Storage";

export class QuickFilter {
    constructor({id, label, selected, action}) {
        this.id = id;
        this.label = label;
        this.selected = selected;
        this.action = action;
    };

    toggle() {
        this.selected = !this.selected;
    };

    clone() {
        return new QuickFilter({id: this.id, label: this.label, selected: this.selected, action: this.action});
    };

    static fromStorage = (categoryId, searchQuery, quickFilters) => {
        const storageData = Storage.getQuickFilters();
        const storageType = storageData ? storageData['type'] : undefined;
        const storageKey = storageData ? storageData['key'] : undefined;
        const storageQuickFilters = storageData ? storageData['quickFilters'] : undefined;
        let isCategory = storageType === 'category' && categoryId && storageKey === categoryId;
        let isSearch = storageType === 'search' && !categoryId && storageKey === searchQuery;
        if (isCategory || isSearch) {
            storageQuickFilters.forEach((storageQuickFilter) => {
                const quickFilter = quickFilters.find(quickFilter => quickFilter.id === storageQuickFilter.id);
                if (quickFilter) quickFilter.selected = storageQuickFilter.selected;
            });
        } else Storage.removeQuickFilters();
        return quickFilters;
    };
}