import React, {useEffect, useState, useRef} from 'react';
import CustomSelectOption from './CustomSelectOption/CustomSelectOption';
import CustomInput from "../CustomInput/CustomInput";
import Text from '../../helpers/Text';
import Viewport from '../../helpers/Viewport';
import Search from '../../helpers/Search';
import selectIcon from './images/down-arrow.svg';
import './CustomSelect.css';

function CustomSelect({
    id,
    label,
    prefix,
    placeholder,
    value,
    options,
    showValueInLabel,
    formatLabel,
    allowSearch,
    selectionHoverColor,
    selectionAccentColor,
    optionHoverColor,
    optionHoverLabelColor,
    onClick,
    onChange
}) {
    const ref = useRef(null);
    const [query, setQuery] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const viewport = Viewport.dimensions;

    useEffect(() => {
        setCloseListener();
        return () => {
            removeCloseListener();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCloseListener = () => {
        document.body.addEventListener('click', handleClose);
    };

    const removeCloseListener = () => {
        document.body.removeEventListener('click', handleClose);
    };

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const handleClose = () => {
        setShowOptions(false);
    };

    const inSmallMode = () => {
        return viewport.width < 400;
    };

    const getValueLabel = () => {
        const match = options.find(option => option.value === value);
        if (match) {
            if (match.customRenderer) {
                return (
                    <div className='custom-select-selection-custom-container'>
                        {!inSmallMode() && <span>{prefix}&nbsp;</span>}
                        <span>{match.customRenderer()}</span>
                    </div>
                );
            } else {
                const formattedLabel = match.label ? (formatLabel ? Text.capitalizeAll(match.label) : match.label) : undefined;
                const value = showValueInLabel ? (formattedLabel ? `${formattedLabel} (${match.value})` : match.value) : (formattedLabel || match.value);
                return (prefix ? (
                    <span>
                        {!inSmallMode() && <span>{prefix}&nbsp;</span>}
                        <span className='custom-select-selection-value-label-prefix-selection' style={selectionLabelStyle()}>{value}</span>
                    </span>
                ) : value);
            }
        } else return placeholder;
    };

    const openOptions = () => {
        setShowOptions(true);
    };

    const closeOptions = () => {
        setShowOptions(false);
    };

    const toggleOptions = () => {
        if (showOptions) closeOptions();
        else openOptions();
    };

    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) onClick();
    };

    const handleQueryChange = (data) => {
        setQuery(data.value);
    };

    const handleOptionClick = (value) => {
        if (onChange) onChange(value);
        closeOptions();
    };

    const selectionStyle = () => {
        return {
            border: `2px solid ${isHovering ? (selectionHoverColor ? selectionHoverColor : '#005B80') : '#CCCCCC'}`
        };
    };

    const selectionLabelStyle = () => {
        return {
            color: selectionAccentColor ? selectionAccentColor : '#005B80'
        };
    };

    const optionsStyle = () => {
        return {
            top: label ? 85 : 55
        };
    };

    const optionsWrapperStyle = () => {
        return {
            maxHeight: allowSearch ? 159 : 220
        }
    };

    const renderOptions = () => {
        const results = query ? Search.searchList(options, ['label'], query) : options;
        return results.map((result, index) => (
            <CustomSelectOption
                key={`${id}-custom-select-option-${index}`}
                label={result.label}
                value={result.value}
                customRenderer={result.customRenderer}
                showValueInLabel={showValueInLabel}
                formatLabel={formatLabel}
                hoverColor={optionHoverColor}
                hoverLabelColor={optionHoverLabelColor}
                onClick={handleOptionClick}
            />
        ));
    };

    return (
        <div ref={ref} className='custom-select' onClick={handleClick}>
            {label && <label className='custom-select-label' htmlFor={id}>{label}</label>}
            <div className='custom-select-selection' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={toggleOptions} style={selectionStyle()}>
                <div className='custom-select-selection-value-label'>{getValueLabel()}</div>
                <div className='custom-select-selection-icon-container'>
                    <img className='custom-select-selection-icon' src={`${selectIcon}`} alt='Select'/>
                </div>
            </div>
            {showOptions && options && options.length > 0 && (
                <div className='custom-select-options' style={optionsStyle()}>
                    {allowSearch && (
                        <div className='custom-select-options-search'>
                            <CustomInput
                                id={`${id}-input`}
                                label={undefined}
                                insetLabel={undefined}
                                value={query}
                                placeholder='🔎 Buscar...'
                                padding='7px'
                                height={35}
                                borderRadius={3}
                                showClearButton={false}
                                formatFunction={undefined}
                                focusBorderColor={optionHoverColor}
                                clearButtonBackgroundColor={undefined}
                                clearButtonLabelColor={undefined}
                                isAddressInput={false}
                                disabled={false}
                                onFocus={undefined}
                                onChange={handleQueryChange}
                                onAnyKeyPress={undefined}
                                onEnterKeyPress={undefined}
                            />
                        </div>
                    )}
                    <div className='custom-select-options-wrapper' style={optionsWrapperStyle()}>
                        {renderOptions()}
                    </div>
                </div>
            )}
        </div>
    );
}

export default CustomSelect;