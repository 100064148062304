import React, {useState} from 'react';
import CustomInput from '../../../CustomInput/CustomInput';
import CustomSelect from '../../../CustomSelect/CustomSelect';
import PaymentStepActions from '../PaymentStepActions/PaymentStepActions';
import Pin from '../../../../helpers/Pin';
import Phone from '../../../../helpers/Phone';
import Email from '../../../../helpers/Email';
import Search from '../../../../helpers/Search';
import './PaymentRegister.css';

function PaymentRegister({
    title1,
    title2,
    titleLineBreak,
    subtitle,
    entity,
    pin,
    name,
    allowNameChange,
    phone,
    email,
    states,
    state,
    districts,
    district,
    cities,
    city,
    street,
    allActivities,
    activities,
    activity,
    segments,
    segment,
    isSaving,
    saveError,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    customInputOutline,
    onPinChange,
    onActivityChange,
    onNameChange,
    onAddressChange,
    onStateChange,
    onDistrictChange,
    onCityChange,
    onPhoneChange,
    onEmailChange,
    onSegmentChange,
    onClose,
    onBack,
    onSubmit
}) {
    const [errors, setErrors] = useState([]);

    const isCompany = () => {
        return entity === 'company';
    };

    const allowPinChange = () => {
        return false;
    };

    const getActivitiesMap = () => {
        const validActivities = activities && activities.length > 0 ? activities : allActivities;
        const userActivityData = allActivities.find((item) => item["activityId"] === activity);
        if (activity && userActivityData) {
            const userActivityIndex = validActivities.findIndex((item) => item["activityId"] === activity);
            if (userActivityIndex === -1) validActivities.push(userActivityData);
        }
        return validActivities.map((activity) => ({value: activity["activityId"], label: activity["activityName"]}));
    };

    const getStatesMap = () => {
        return states.map((state) => ({value: state["stateId"], label: state["stateName"]}));
    };

    const getDistrictsMap = () => {
        const filteredDistricts = state ? districts.filter((item) => item["stateId"] === state) : [];
        return filteredDistricts.map((district) => ({value: district["districtName"], label: district["districtName"]}));
    };

    const getCitiesMap = () => {
        const filteredCities = state ? cities.filter((item) => item["stateId"] === state) : [];
        return filteredCities.map((district) => ({value: district["cityKey"], label: district["cityName"]}));
    };

    const getSegmentsMap = () => {
        return segments.map((state) => ({value: state["segment"], label: state["description"]}));
    };

    const getErrors = (input) => {
        return errors.filter((error) => error.input === input);
    };

    const clearErrors = (input) => {
        const newErrors = errors.filter((error) => error.input !== input);
        setErrors(newErrors);
    };

    const hasValidData = () => {
        let newErrors = [];
        if (!pin)                      newErrors.push({input: 'pin',   message: 'El RUT de facturación es obligatorio.'});
        else {if (!Pin.valid(pin))     newErrors.push({input: 'pin',   message: 'El RUT de facturación no es válido.'});}
        if (!name)                     newErrors.push({input: 'name',  message: 'El nombre es obligatorio.'});
        if (!phone)                    newErrors.push({input: 'phone', message: 'El teléfono es obligatorio.'});
        else {if (!Phone.valid(phone)) newErrors.push({input: 'phone', message: 'El teléfono no es válido.'});}
        if (!email)                    newErrors.push({input: 'email', message: 'El correo electrónico es obligatorio.'});
        else {if (!Email.valid(email)) newErrors.push({input: 'email', message: 'El correo electrónico no es válido.'});}
        if (!segment)                  newErrors.push({input: 'segment', message: 'El segmento especificado no es válido.'});
        if (isCompany()) {
            if (!activity)             newErrors.push({input: 'activity', message: 'La actividad económica es obligatoria.'});
            if (!street)               newErrors.push({input: 'address',  message: 'La dirección es obligatoria.'});
            if (!state)                newErrors.push({input: 'state',    message: 'La región es obligatoria.'});
            if (!district)             newErrors.push({input: 'district', message: 'La comuna es obligatoria.'});
            if (!city)                 newErrors.push({input: 'city',     message: 'La ciudad es obligatoria.'});
        }
        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handlePinChange = (data) => {
        if (onPinChange) onPinChange(data);
    };

    const handleActivityChange = (newActivity) => {
        if (onActivityChange) onActivityChange(newActivity);
    };

    const handleNameChange = (data) => {
        if (onNameChange) onNameChange(data);
    };

    const handleSegmentChange = (newSegment) => {
        if (onSegmentChange) onSegmentChange(newSegment);
    };

    const handleAddressChange = (data) => {
        if (data.components) {
            const hasNumber   = data.components.find((component) => component.types.includes('street_number'));
            const hasStreet   = data.components.find((component) => component.types.includes('route'));
            const hasDistrict = data.components.find((component) => component.types.includes('administrative_area_level_3'));
            const hasCity     = data.components.find((component) => component.types.includes('administrative_area_level_2'));
            const hasState    = data.components.find((component) => component.types.includes('administrative_area_level_1'));
            const number  = hasNumber ? hasNumber['long_name'] : undefined;
            const street  = hasStreet ? hasStreet['long_name'] : undefined;
            const district  = hasDistrict ? hasDistrict['long_name'] : undefined;
            const city  = hasCity ? hasCity['long_name'] : undefined;
            const state  = hasState ? hasState['long_name'] : undefined;
            const fullStreet = street ? `${street}${number ? ` ${number}` : ''}` : undefined;
            const districtMatch = districts.find((item) => Search.normalizeText(item["districtName"]) === Search.normalizeText(district));
            const cityMatch = cities.find((item) => Search.normalizeText(item["cityName"]) === Search.normalizeText(city));
            const stateMatch = states.find((item) => Search.normalizeText(item["stateName"]) === Search.normalizeText(state));
            const districtId = districtMatch ? districtMatch["districtName"] : undefined;
            const cityId = cityMatch ? cityMatch["cityKey"] : undefined;
            const stateId = stateMatch ? stateMatch["stateId"] : undefined;
            if (onAddressChange) onAddressChange(fullStreet, districtId, cityId, stateId);
        } else {
            if (onAddressChange) onAddressChange(data.value, undefined, undefined, undefined);
        }
    };

    const handleStateChange = (newState) => {
        if (onStateChange) onStateChange(newState);
    };

    const handleDistrictChange = (newDistrict) => {
        if (onDistrictChange) onDistrictChange(newDistrict);
    };

    const handleCityChange = (newCity) => {
        if (onCityChange) onCityChange(newCity);
    };

    const handlePhoneChange = (data) => {
        if (onPhoneChange) onPhoneChange(data);
    };

    const handleEmailChange = (data) => {
        if (onEmailChange) onEmailChange(data);
    };

    const handlePinFocus = () => {
        clearErrors('pin');
    };

    const handleActivityFocus = () => {
        clearErrors('activity');
    };

    const handleNameFocus = () => {
        clearErrors('name');
    };

    const handleSegmentFocus = () => {
        clearErrors('segment');
    };

    const handleAddressFocus = () => {
        clearErrors('address');
    };

    const handleStateFocus = () => {
        clearErrors('state');
    };

    const handleDistrictFocus = () => {
        clearErrors('district');
    };

    const handleCityFocus = () => {
        clearErrors('city');
    };

    const handlePhoneFocus = () => {
        clearErrors('phone');
    };

    const handleEmailFocus = () => {
        clearErrors('email');
    };

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleBack = () => {
        if (onBack) onBack();
    };

    const handleSubmit = () => {
        if (onSubmit && hasValidData()) onSubmit();
    };

    const rowStyle = (zIndex) => {
        return {
            zIndex: zIndex || 0
        };
    };

    const renderErrors = (input) => {
        const inputErrors = getErrors(input);
        if (inputErrors.length === 0) return undefined;
        return (
            <div className='payment-register-errors'>
                {inputErrors.map((error, index) => (
                    <div key={`payment-errors-${input}-${index}`} className='payment-register-error'>{error.message}</div>
                ))}
            </div>
        );
    };

    return (
        <div className='payment-register'>
            <React.Fragment>
                <p className='payment-register-title'>
                    {title1}
                    {titleLineBreak && <br/>}
                    <span className='bold'>{title2}</span>
                </p>
                <p className='payment-register-subtitle'>{subtitle}</p>
                <div className='payment-register-content'>
                    <div className='payment-input-row' style={rowStyle(5)}>
                        <div className='payment-input-container'>
                            <CustomInput
                                id='payment-register-pin'
                                label={isCompany() ? 'RUT de facturación' : 'RUT'}
                                insetLabel={false}
                                placeholder=''
                                showClearButton={false}
                                value={pin}
                                formatFunction={Pin.format}
                                focusBorderColor={customInputOutline}
                                clearButtonBackgroundColor={undefined}
                                clearButtonLabelColor={undefined}
                                isAddressInput={false}
                                disabled={!allowPinChange()}
                                onFocus={handlePinFocus}
                                onChange={handlePinChange}
                                onAnyKeyPress={undefined}
                                onEnterKeyPress={undefined}
                            />
                            {renderErrors('pin')}
                        </div>
                        {isCompany() && (
                            <div className='payment-input-container'>
                                <CustomSelect
                                    id='payment-activities-options'
                                    label='Actividad económica'
                                    prefix={undefined}
                                    placeholder='Selecciona una actividad'
                                    value={activity}
                                    options={getActivitiesMap()}
                                    showValueInLabel={false}
                                    formatLabel={false}
                                    allowSearch={true}
                                    selectionHoverColor={customInputOutline}
                                    selectionAccentColor={undefined}
                                    optionHoverColor={customInputOutline}
                                    onClick={handleActivityFocus}
                                    onChange={handleActivityChange}
                                />
                                {renderErrors('activity')}
                            </div>
                        )}
                    </div>
                    <div className='payment-input-row' style={rowStyle(4)}>
                        <div className='payment-input-container'>
                            <CustomInput
                                id='payment-register-name'
                                label={isCompany() ? 'Razón social' : 'Nombre'}
                                insetLabel={false}
                                placeholder={isCompany() ? 'Ingresa la razón social de tu empresa' : 'Ingresa tu nombre'}
                                showClearButton={false}
                                value={name}
                                formatFunction={undefined}
                                focusBorderColor={customInputOutline}
                                clearButtonBackgroundColor={undefined}
                                clearButtonLabelColor={undefined}
                                isAddressInput={false}
                                disabled={!allowNameChange}
                                onFocus={handleNameFocus}
                                onChange={handleNameChange}
                                onAnyKeyPress={undefined}
                                onEnterKeyPress={undefined}
                            />
                            {renderErrors('name')}
                        </div>
                        <div className='payment-input-container'>
                            <CustomSelect
                                id='payment-segments-options'
                                label='Segmento'
                                prefix={undefined}
                                placeholder='Selecciona un segmento'
                                value={segment}
                                options={getSegmentsMap()}
                                showValueInLabel={false}
                                formatLabel={false}
                                allowSearch={true}
                                selectionHoverColor={customInputOutline}
                                selectionAccentColor={undefined}
                                optionHoverColor={customInputOutline}
                                onClick={handleSegmentFocus}
                                onChange={handleSegmentChange}
                            />
                            {renderErrors('segment')}
                        </div>
                    </div>
                    {isCompany() && (
                        <React.Fragment>
                            <div className='payment-input-row' style={rowStyle(3)}>
                                <div className='payment-input-container'>
                                    <CustomInput
                                        id='payment-register-address'
                                        label='Dirección'
                                        insetLabel={false}
                                        placeholder='Ingresa tu dirección'
                                        showClearButton={false}
                                        value={street}
                                        formatFunction={undefined}
                                        focusBorderColor={customInputOutline}
                                        clearButtonBackgroundColor={undefined}
                                        clearButtonLabelColor={undefined}
                                        isAddressInput={true}
                                        disabled={false}
                                        onFocus={handleAddressFocus}
                                        onChange={handleAddressChange}
                                        onAnyKeyPress={undefined}
                                        onEnterKeyPress={undefined}
                                    />
                                    {renderErrors('address')}
                                </div>
                                <div className='payment-input-container'>
                                    <CustomSelect
                                        id='payment-states-options'
                                        label='Región'
                                        prefix={undefined}
                                        placeholder='Selecciona una región'
                                        value={state}
                                        options={getStatesMap()}
                                        showValueInLabel={false}
                                        formatLabel={false}
                                        allowSearch={true}
                                        selectionHoverColor={customInputOutline}
                                        selectionAccentColor={undefined}
                                        optionHoverColor={customInputOutline}
                                        onClick={handleStateFocus}
                                        onChange={handleStateChange}
                                    />
                                    {renderErrors('state')}
                                </div>
                            </div>
                            <div className='payment-input-row' style={rowStyle(2)}>
                                <div className='payment-input-container'>
                                    <CustomSelect
                                        id='payment-districts-options'
                                        label='Comuna'
                                        prefix={undefined}
                                        placeholder={state ? 'Selecciona una comuna' : 'Primero selecciona una región'}
                                        value={district}
                                        options={getDistrictsMap()}
                                        showValueInLabel={false}
                                        formatLabel={false}
                                        allowSearch={true}
                                        selectionHoverColor={customInputOutline}
                                        selectionAccentColor={undefined}
                                        optionHoverColor={customInputOutline}
                                        onClick={handleDistrictFocus}
                                        onChange={handleDistrictChange}
                                    />
                                    {renderErrors('district')}
                                </div>
                                <div className='payment-input-container'>
                                    <CustomSelect
                                        id='payment-cities-options'
                                        label='Ciudad'
                                        prefix={undefined}
                                        placeholder={state ? 'Selecciona una ciudad' : 'Primero selecciona una región'}
                                        value={city}
                                        options={getCitiesMap()}
                                        showValueInLabel={false}
                                        formatLabel={false}
                                        allowSearch={true}
                                        selectionHoverColor={customInputOutline}
                                        selectionAccentColor={undefined}
                                        optionHoverColor={customInputOutline}
                                        onClick={handleCityFocus}
                                        onChange={handleCityChange}
                                    />
                                    {renderErrors('city')}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className='payment-input-row' style={rowStyle(1)}>
                        <div className='payment-input-container'>
                            <CustomInput
                                id='payment-register-phone'
                                label='Teléfono'
                                insetLabel={false}
                                placeholder='+56 9 0000 0000'
                                showClearButton={false}
                                value={phone}
                                formatFunction={undefined}
                                focusBorderColor={customInputOutline}
                                clearButtonBackgroundColor={undefined}
                                clearButtonLabelColor={undefined}
                                isAddressInput={false}
                                disabled={false}
                                onFocus={handlePhoneFocus}
                                onChange={handlePhoneChange}
                                onAnyKeyPress={undefined}
                                onEnterKeyPress={undefined}
                            />
                            {renderErrors('phone')}
                        </div>
                        <div className='payment-input-container'>
                            <CustomInput
                                id='payment-register-email'
                                label='Correo electrónico'
                                insetLabel={false}
                                placeholder='Ingresa tu correo'
                                showClearButton={false}
                                value={email}
                                formatFunction={undefined}
                                focusBorderColor={customInputOutline}
                                clearButtonBackgroundColor={undefined}
                                clearButtonLabelColor={undefined}
                                isAddressInput={false}
                                disabled={false}
                                onFocus={handleEmailFocus}
                                onChange={handleEmailChange}
                                onAnyKeyPress={undefined}
                                onEnterKeyPress={undefined}
                            />
                            {renderErrors('email')}
                        </div>
                    </div>
                </div>
                <div className='payment-register-actions-container'>
                    <PaymentStepActions
                        isLoading={isSaving}
                        loadingLabel='Guardando...'
                        submitLabel='Continuar'
                        cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                        cancelButtonBorderColor={cancelButtonBorderColor}
                        cancelButtonLabelColor={cancelButtonLabelColor}
                        backButtonBackgroundColor={backButtonBackgroundColor}
                        backButtonBorderColor={backButtonBorderColor}
                        backButtonLabelColor={backButtonLabelColor}
                        submitButtonBackgroundColor={actionButtonBackgroundColor}
                        submitButtonBorderColor={actionButtonBorderColor}
                        submitButtonLabelColor={actionButtonLabelColor}
                        showCloseButton={!!onClose}
                        showBackButton={true}
                        showSubmitButton={true}
                        onClose={handleClose}
                        onBack={handleBack}
                        onSubmit={handleSubmit}
                    />
                </div>
                {saveError && <div className='payment-register-save-error'>Se ha producido un error al guardar la información</div>}
            </React.Fragment>
        </div>
    );
}

export default PaymentRegister;