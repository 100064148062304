import React from 'react';
import Button from "../../../Button/Button";
import './PaymentStepActions.css';

function PaymentStepActions({
    isLoading,
    loadingLabel,
    submitLabel,
    cancelButtonBackgroundColor,
    cancelButtonLabelColor,
    cancelButtonBorderColor,
    backButtonBackgroundColor,
    backButtonLabelColor,
    backButtonBorderColor,
    submitButtonBackgroundColor,
    submitButtonLabelColor,
    submitButtonBorderColor,
    disableCloseButton,
    showCloseButton,
    showBackButton,
    showSubmitButton,
    onClose,
    onBack,
    onSubmit
}) {

    const handleBack = () => {
        if (onBack) onBack();
    };

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleSubmit = () => {
        if (onSubmit) onSubmit();
    };

    return (
        <div className='payment-step-actions'>
            {showBackButton && (
                <div className='payment-step-action'>
                    <Button
                        label='Volver'
                        labelColor={backButtonLabelColor}
                        backgroundColor={backButtonBackgroundColor}
                        borderColor={backButtonBorderColor}
                        borderWidth={1}
                        minWidth='auto'
                        maxWidth='100%'
                        onClick={handleBack}
                    />
                </div>
            )}
            {showCloseButton && (
                <div className='payment-step-action'>
                    <Button
                        label='Cerrar'
                        labelColor={cancelButtonLabelColor}
                        backgroundColor={cancelButtonBackgroundColor}
                        borderColor={cancelButtonBorderColor}
                        borderWidth={1}
                        minWidth='auto'
                        maxWidth='100%'
                        boxShadow='unset'
                        disabled={disableCloseButton || false}
                        onClick={() => handleClose('custom-close-button')}
                    />
                </div>
            )}
            {showSubmitButton && (
                <div className='payment-step-action'>
                    <Button
                        label={submitLabel || 'Continuar'}
                        labelColor={submitButtonLabelColor}
                        backgroundColor={submitButtonBackgroundColor}
                        borderColor={submitButtonBorderColor}
                        borderWidth={1}
                        minWidth='auto'
                        maxWidth='100%'
                        isLoading={isLoading}
                        loadLabel={loadingLabel}
                        showPulseEffect={!isLoading}
                        onClick={handleSubmit}
                    />
                </div>
            )}
        </div>
    );
}

export default PaymentStepActions;