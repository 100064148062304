import React from 'react';
import Activity from "../../../helpers/Activity";
import checkIcon from "./images/check.svg";
import './QuickFilter.css';

function QuickFilter({selected, category, searchQuery, quickFilter, width, marginRight, labelColor, backgroundColor, checkboxBackgroundColor, onToggle, metadata}) {
    const handleToggle = () => {
        if (onToggle) {
            Activity.log(metadata, 'quick-filter-option', quickFilter.id, 'toggle', {category_id: category.id, category_name: category.name, search_query: searchQuery || null, quick_filter_id: quickFilter.id, quick_filter_label: quickFilter.label, quick_filter_selected: !quickFilter.selected});
            onToggle(quickFilter.id);
        }
    };

    const quickFilterStyle = () => {
        return {
            width: width ? width : 200,
            minWidth: width ? width : 200,
            marginRight: marginRight ? marginRight : 15,
            background: backgroundColor || '#005B80'
        };
    };

    const labelStyle = () => {
        return {
            fontSize: 16,
            color: labelColor || '#FFFFFF'
        };
    };

    const checkContainerStyle = () => {
        return {
            backgroundColor: selected ? (checkboxBackgroundColor || '#005B80') : '#FFFFFF',
            border: `solid 1px ${selected ? 'transparent' : '#979797'}`
        };
    };

    return (
        <div className='quick-filter-option' style={quickFilterStyle()} onClick={handleToggle}>
            <p className='quick-filter-option-label' style={labelStyle()}>{quickFilter.label || '?'}</p>
            <div className='quick-filter-check-container' style={checkContainerStyle()}>
                {selected && <img className='quick-filter-check' src={`${checkIcon}`} alt='Selected'/>}
            </div>
        </div>
    );
}

export default QuickFilter;