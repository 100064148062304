import React from 'react';
import {SENTRY_ERROR_TESTING_MODE} from '../../config/App';
import './VersionTag.css';

function VersionTag({version}) {
    const handleClick = () => {
        if (SENTRY_ERROR_TESTING_MODE) {
            const randomMessage = Math.random().toString(36).substring(2);
            throw new Error(randomMessage);
        }
    };

    const getEnvironmentKey = () => {
        const hostname = window.location.hostname;
        switch (hostname) {
            case 'localhost':
                return 'LOC';
            case 'kiosk-3d971--development-q2boteho.web.app':
                return 'DEV';
            case 'kiosk-3d971--staging-p1c8s52w.web.app':
                return 'STA';
            case 'kiosk-3d971--preproduction-spys6x1l.web.app':
                return 'PRE';
            case 'kiosco.motiondisplays.com':
                return 'PRO';
            default:
                return 'UNK';
        }
    };

    return (
        <div className='version-tag' onClick={handleClick}>v{version}-{getEnvironmentKey()}</div>
    );
}

export default VersionTag;