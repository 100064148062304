import React from 'react';
import ProductTag from '../ProductBox/ProductTag/ProductTag';
import ImageWrapper from '../ImageWrapper/ImageWrapper';
import CurrencyFormat from 'react-currency-format';
import Currency from '../../config/Currency';
import Viewport from '../../helpers/Viewport';
import {Product} from '../../models/Product';
import defaultFreeDeliveryIcon from './images/free-delivery.svg';
import defaultDeliveryIcon from './images/delivery.svg';
import defaultPickupIcon from './images/pickup.svg';
import defaultShopIcon from './images/shop.png';
import defaultPlaneIcon from "./images/plane.png";
import './ProductTitle.css';

function ProductTitle({
    product,
    isSidebarOpen,
    clientName,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    freeDeliveryIcon,
    freeDeliveryLabelColor,
    freeDeliveryBackgroundColor,
    deliveryIcon,
    deliveryLabelColor,
    deliveryBackgroundColor,
    pickupIcon,
    pickupLabelColor,
    pickupBackgroundColor,
    externalSaleBorderColor,
    externalSaleBackgroundColor,
    externalSaleLabelColor,
    sellerLabelColor,
    mainSellerIcon
}) {
    const viewport = Viewport.dimensions;
    const currencyFormat = Currency.CLP;

    const getDiscount = () => {
        return product.exclusivePrice ? product.getExclusiveDiscountPercentage() : product.getOfferDiscountPercentage();
    };

    const isSmallScreen = () => {
        return viewport.width <= 800;
    };

    const isSmallScreenWithSidebarOpen = () => {
        return isSidebarOpen && viewport.width <= 1000;
    };

    const hasSeller = () => {
        return (product.externalSale && product.sellerName) || (!product.externalSale && !!clientName);
    };

    const hasDelivery = () => {
        return !sameSellerAndDelivery() && product.clientDelivery && !!clientName;
    }

    const sameSellerAndDelivery = () => {
        return !product.externalSale && product.clientDelivery;
    };

    const hasInternationalShipping = () => {
        return !product.clientDelivery && product.internationalShipping;
    };

    const containerStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {
                display: 'block',
                flexWrap: 'unset',
                justifyContent: 'unset',
                alignItems: 'unset',
                alignContent: 'unset'
            };
        } else {
            return {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                alignContent: 'center'
            }
        }
    };

    const titleInfoStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {width: '100%'};
        } else {
            return {width: '70%'}
        }
    };

    const sellerNameStyle = () => {
        return {
            color: sellerLabelColor || '#111111'
        };
    };

    const pricesStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {width: '100%', marginTop: 30};
        } else {
            return {width: '30%', marginTop: 0}
        }
    };

    const priceStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {justifyContent: 'flex-start'};
        } else {
            return {justifyContent: 'flex-end'};
        }
    };

    const discountStyle = () => {
        return {
            background: discountBackgroundColor || '#005B80',
            color: discountLabelColor || '#FFFFFF'
        };
    };

    const mainPriceStyle = (isExclusivePrice) => {
        const style = priceStyle();
        style.color = isExclusivePrice ? (exclusivePriceLabelColor || '#005B80') : '#414042';
        return style;
    };

    const unitStyle = () => {
        if (isSmallScreen() || isSmallScreenWithSidebarOpen()) {
            return {justifyContent: 'flex-start'};
        } else {
            return {justifyContent: 'flex-end'};
        }
    };

    const renderClientIcon = () => {
        return (
            <div className='product-title-main-seller-icon-container'>
                <ImageWrapper image={mainSellerIcon} alt='Main' display='block' width={20} height='auto' loadDelay={0.2}/>
            </div>
        );
    };

    const discount = getDiscount();

    return (
        <div className='product-title' style={containerStyle()}>
            <div className='product-title-info' style={titleInfoStyle()}>
                <div className='product-title-first-line'>
                    {product.freeDelivery && <ProductTag icon={freeDeliveryIcon || defaultFreeDeliveryIcon} label='Despacho gratis' labelColor={freeDeliveryLabelColor} backgroundColor={freeDeliveryBackgroundColor} borderColor={freeDeliveryBackgroundColor}/>}
                    {!product.freeDelivery && product.delivery && <ProductTag icon={deliveryIcon || defaultDeliveryIcon} label='Despacho' labelColor={deliveryLabelColor} backgroundColor={deliveryBackgroundColor} borderColor={deliveryBackgroundColor}/>}
                    {product.pickup && <ProductTag icon={pickupIcon || defaultPickupIcon} label='Retiro' labelColor={pickupLabelColor} backgroundColor={pickupBackgroundColor} borderColor={pickupBackgroundColor}/>}
                    {product.externalSale && <ProductTag icon={undefined} label='Pago con celular' labelColor={externalSaleLabelColor} backgroundColor={externalSaleBackgroundColor} borderColor={externalSaleBorderColor}/>}
                    {discount && discount > 0 ? <div className='product-title-discount-tag' style={discountStyle()}>{discount}%</div> : null}
                    {product.sku && <div className='product-title-sku'>SKU: {product.sku}</div>}
                </div>
                <div className='product-title-second-line'>
                    {product.name && <div className='product-title-name'>{product.name}</div>}
                </div>
                {product.sellerName && (
                    <div className='product-title-third-line'>
                        <React.Fragment>
                            {(hasSeller() || hasDelivery()) && (
                                <div className='product-title-seller-icon-container'>
                                    <ImageWrapper image={defaultShopIcon} alt='Seller' display='block' width={25} height='auto' loadDelay={0.2}/>
                                </div>
                            )}
                            <div className='product-title-seller-message'>
                                {hasSeller() && (
                                    <React.Fragment>
                                        {sameSellerAndDelivery() ? 'Vendido y enviado por ' : 'Vendido por '}
                                        <span className='product-title-seller-name' style={sellerNameStyle()}>{product.externalSale ? product.sellerName : clientName}</span>
                                        {mainSellerIcon && !product.externalSale && renderClientIcon()}
                                    </React.Fragment>
                                )}
                                {hasDelivery() && (
                                    <React.Fragment>
                                        <span className='product-title-info-delivery'>{hasSeller() ? " y e" : "E"}nviado por <span className='product-title-seller-name' style={sellerNameStyle()}>{clientName}</span></span>
                                        {mainSellerIcon && renderClientIcon()}
                                    </React.Fragment>
                                )}
                                {hasInternationalShipping() && (
                                    <React.Fragment>
                                        <span className='product-title-info-delivery-icon-container'>
                                            <ImageWrapper image={defaultPlaneIcon} alt='Seller' display='inline-block' width={25} height='auto' loadDelay={0.2}/>
                                        </span>
                                        <span className='product-title-info-delivery'>Compra internacional</span>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    </div>
                )}
            </div>
            <div className='product-title-prices' style={pricesStyle()}>
                {product.normalPrice && ((product.exclusivePrice && product.exclusivePrice !== product.normalPrice) || (product.offerPrice && product.offerPrice !== product.normalPrice)) && (
                    <div className='product-title-irrelevant-price' style={priceStyle()}>
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={product.normalPrice}
                            displayType='text'
                        />
                    </div>
                )}
                {product.exclusivePrice && product.offerPrice && (
                    <div className='product-title-secondary-price' style={priceStyle()}>
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={product.offerPrice}
                            displayType='text'
                        />
                    </div>
                )}
                {(product.exclusivePrice || product.offerPrice || product.normalPrice) && (
                    <div className='product-title-main-price' style={mainPriceStyle(!!product.exclusivePrice)}>
                        {product.exclusivePrice && (
                            <div className='product-title-price-card'>
                                <ImageWrapper image={exclusivePriceIcon} alt='Card' display='block' width={35} height='auto' loadDelay={0.2}/>
                            </div>
                        )}
                        <CurrencyFormat
                            prefix={currencyFormat.prefix}
                            suffix={currencyFormat.suffix}
                            thousandSeparator={currencyFormat.thousandsSeparator}
                            decimalSeparator={currencyFormat.decimalSeparator}
                            decimalScale={currencyFormat.decimalScale}
                            value={product.exclusivePrice || product.offerPrice || product.normalPrice}
                            displayType='text'
                        />
                    </div>
                )}
                {product.saleUnit && (
                    <div className="product-title-unit" style={unitStyle()}>Por {Product.getUnitText(product.saleUnit, false)}</div>
                )}
            </div>
        </div>
    );
}

export default ProductTitle;