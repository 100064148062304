import HTML2canvas from "html2canvas";
import JsPDF from "jspdf";
import Debug from "./Debug";

export default class Printer {
    static getProperties() {
        return {
            dpi: 203, // 203 is the DPI property of the printer.
            width: 80 // 80 is the maximun width of the printer paper, the heights must depend on the content.
            // Millimeters = (Pixels * 25.4) / DPI
            // Pixels = (DPI * Millimeters) / 25.4
        };
    };

    static getDocumentSize = () => {
        const {dpi, width} = this.getProperties();
        return {
            width: dpi * width / 25.4,
            height: 'auto'
        };
    };

    static printHTMLFromElement(containerRef, documentRef) {
        const properties = this.getProperties();
        const html = documentRef && documentRef.current ? documentRef.current : undefined;
        if (!html) {
            Debug.printToLog('error', 'Invalid document reference');
            Promise.reject(false);
        }
        let dataUrl;
        return HTML2canvas(html, {scale: 1})
            .then((canvas) => {
                dataUrl = canvas.toDataURL('image/png');
                return this.getPrinterImageSize(dataUrl);
            })
            .then((size) => {
                if (!size) throw new Error('Error obtaining printer image size');
                const height = (size.height * 25.4) / properties.dpi;
                const pdf = new JsPDF({orientation: 'p', unit: 'mm', format: [properties.width, height], userUnit: properties.dpi, compress: false});
                pdf.addImage({imageData: dataUrl, format: 'PNG', x: 0, y: 0, width: properties.width, height: height, compression: 'NONE'});
                const content = pdf.output('bloburl');
                this.removePrinterIframe('budget-print-iframe');
                this.addPrinterIframe('budget-print-iframe', containerRef, `${content}`);
            })
            .then(() => {
                return true;
            })
            .catch((error) => {
                Debug.printToLog('error', error);
                return false;
            });
    };

    static getPrinterImageSize(dataUrl) {
        return new Promise((resolve, reject) => {
            const image = document.createElement('img');
            image.src = dataUrl;
            image.onload = () => {
                resolve({width: image.width, height: image.height});
            };
            image.onerror = () => {
                reject(undefined);
            };
        });
    };

    static addPrinterIframe(iframeId, containerRef, content) {
        const container = containerRef && containerRef.current ? containerRef.current : undefined;
        if (container) {
            return new Promise((resolve, reject) => {
                const element = document.createElement('iframe');
                element.id = iframeId;
                element.src = content;
                element.onload = () => {
                    element.focus();
                    element.contentWindow.print();
                    resolve();
                };
                element.onerror = () => {
                    reject();
                };
                container.appendChild(element);
            });
        } else return Promise.reject();
    };

    static removePrinterIframe(iframeId) {
        const element = document.getElementById(iframeId);
        if (element) element.remove();
    };
}