import Debug from "./Debug";
import WebServices from "./WebServices";

export default class ProtectedWebServices {
    static async makeRequest(apiKey, url, method, data = {}) {
        let timestamp;
        let nonce;
        let messageData;
        return WebServices.getServerTimestamp()
            .then((serverTimestamp) => {
                if (!serverTimestamp) throw new Error('Invalid server timestamp');
                timestamp = serverTimestamp.toString();
                nonce = crypto.randomUUID();
                const message = `${timestamp}:${nonce}:${JSON.stringify(data)}`;
                const encoder = new TextEncoder();
                const keyData = encoder.encode(apiKey);
                messageData = encoder.encode(message);
                return crypto.subtle.importKey('raw', keyData, {name: 'HMAC', hash: 'SHA-256'}, false, ['sign']);
            })
            .then((key) => {
                return crypto.subtle.sign('HMAC', key, messageData);
            })
            .then((signature) => {
                return Array.from(new Uint8Array(signature)).map(b => b.toString(16).padStart(2, '0')).join('');
            })
            .then((signatureHex) => {
                const headers = {'Content-Type': 'application/json', 'X-Timestamp': timestamp, 'X-Nonce': nonce, 'X-Signature': signatureHex};
                return fetch(url, {method: method, mode: 'cors', cache: 'no-cache', headers: headers, body: JSON.stringify(data)});
            })
            .then(response => {
                if (!response.ok) throw new Error(`Request failed: ${response.statusText}`);
                return response.json();
            })
            .then(response => {
                return response;
            })
            .catch((error) => {
                Debug.printToLog('error', error);
                return undefined;
            });
    }
};