import React, {useState, useEffect, useRef} from 'react';
import Slider from '../Slider/Slider';
import Button from '../Button/Button';
import {setElapsedConfigTime, getElapsedConfigTime, getRemainingConfigTime} from '../../config/App';
import usePrevious from '../../helpers/UsePrevious';
import configIcon from './images/config.png';
import './ConfigOverlay.css';

function ConfigOverlay({zoomLevel, onZoomRelease, onOpen, onClose, onClear}) {
    const [isConfigButtonPressed, setIsConfigButtonPressed] = useState(false);
    const [remainingTimeUntilConfig, setRemainingTimeUntilConfig] = useState(getRemainingConfigTime());
    const [showConfig, setShowConfig] = useState(false);
    const [currentZoomLevel, setCurrentZoomLevel] = useState(zoomLevel);
    const previousConfigButtonPressed = usePrevious(isConfigButtonPressed);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (!previousConfigButtonPressed && isConfigButtonPressed) startConfigTimer();
        if (previousConfigButtonPressed && !isConfigButtonPressed) stopConfigTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfigButtonPressed]);

    const startConfigTimer = () => {
        intervalRef.current = setInterval(() => {
            setElapsedConfigTime(getElapsedConfigTime() + 1);
            const _remainingTimeUntilConfig = getRemainingConfigTime();
            setRemainingTimeUntilConfig(_remainingTimeUntilConfig);
            if (_remainingTimeUntilConfig <= 0) {
                setShowConfig(true);
                setIsConfigButtonPressed(false);
                if (onOpen) onOpen();
            }
        }, 1000);
    };

    const stopConfigTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setElapsedConfigTime(0);
        setRemainingTimeUntilConfig(getRemainingConfigTime());
    };

    const handleConfigButtonPress = () => {
        setIsConfigButtonPressed(true);
    };

    const handleConfigButtonRelease = () => {
        setIsConfigButtonPressed(false);
    };

    const handleCurrentZoomLevelChange = (value) => {
        setCurrentZoomLevel(value / 100);
    };

    const handleCurrentZoomLevelRelease = (value) => {
        if (onZoomRelease) onZoomRelease(value / 100);
    };

    const handleConfigClear = () => {
        if (onClear) onClear();
    };

    const handleConfigClose = () => {
        setShowConfig(false);
        setIsConfigButtonPressed(false);
        if (onClose) onClose();
    };

    const configButtonStyle = () => {
        return {
            opacity: isConfigButtonPressed ? 1 : 0
        };
    };

    const containerStyle = () => {
        return {
            width: isConfigButtonPressed || showConfig ? '100%' : 0,
            height: isConfigButtonPressed || showConfig ? '100%' : 0
        };
    };

    const zoomLevelPercent = Math.round(currentZoomLevel * 100);

    return (
        <React.Fragment>
            {!showConfig && (
                <div className='view-config-button' onMouseDown={handleConfigButtonPress} onTouchStart={handleConfigButtonPress} onMouseUp={handleConfigButtonRelease} onTouchEnd={handleConfigButtonRelease} onMouseLeave={handleConfigButtonRelease} style={configButtonStyle()}>
                    <img className='view-config-button-icon' src={`${configIcon}`} alt='Config' draggable={false}/>
                </div>
            )}
            <div className='config-overlay' style={containerStyle()}>
                {!showConfig && <p className='config-overlay-timer'>Abriendo configuración en {remainingTimeUntilConfig} segundo(s)...</p>}
                {showConfig && (
                    <div className='config-overlay-panel'>
                        <div className='config-overlay-panel-close-button' onClick={handleConfigClose}>
                            <span className='config-overlay-panel-close-button-icon'>x</span>
                        </div>
                        <h2 className='config-overlay-panel-title'>Configuración</h2>
                        <div className='config-overlay-panel-option'>
                            <label className='config-overlay-panel-option-label'>Zoom: {zoomLevelPercent}%</label>
                            <Slider
                                initialValue={zoomLevelPercent}
                                min={30}
                                max={200}
                                step={1}
                                highlightColor="#888888"
                                onChange={handleCurrentZoomLevelChange}
                                onRelease={handleCurrentZoomLevelRelease}
                            />
                        </div>
                        <div className='config-overlay-panel-buttons'>
                            <Button
                                label='Reiniciar Kiosco'
                                labelColor='#FFFFFF'
                                backgroundColor='#CD5C5C'
                                borderColor='#CD5C5C'
                                boxShadow='unset'
                                onClick={handleConfigClear}
                            />
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default ConfigOverlay;