import React, {useEffect, useState} from 'react';
import ExternalCheckout from './PaymentFlows/ExternalCheckout/ExternalCheckout';
import IntegratedPOS from './PaymentFlows/IntegratedPOS/IntegratedPOS';
import Signup from './PaymentFlows/Signup/Signup';
import DataError from '../DataError/DataError';
import DataLoader from '../DataLoader/DataLoader';
import Activity from '../../helpers/Activity';
import WebServices from '../../helpers/WebServices';
import './PaymentModal.css';

function PaymentModal({
    instance,
    client,
    store,
    shoppingCart,
    paymentMode,
    pk,
    taxMultiplier,
    mainClientLogo,
    kioskOptionImage,
    smartphoneOptionImage,
    smartphoneOptionQrScanImage,
    personOptionImage,
    companyOptionImage,
    cardOptionImage,
    otherPaymentOptionImage,
    ticketOptionImage,
    invoiceOptionImage,
    timerImage,
    printMessageImage,
    paymentSuccessImage,
    getHelpErrorImage,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    customInputOutline,
    customInputClearButtonBackgroundColor,
    customInputClearButtonLabelColor,
    inSmallMode,
    openShoppingCart,
    resetPromotionalVideoTimer,
    allowClosing,
    preventClosing,
    flowFinished,
    handleClose,
    silentClose,
    metadata
}) {
    const [isLoadingCheckout, setIsLoadingCheckout] = useState(true);
    const [checkoutLoaded, setCheckoutLoaded] = useState(false);
    const [checkoutUrl, setCheckoutUrl] = useState(undefined);
    const [orderNumber, setOrderNumber] = useState(undefined);
    const [paymentNumber, setPaymentNumber] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        WebServices.getClientShoppingCartUrl(client, store.id, shoppingCart, false)
            .then(response => {
                if (response && !response.error) {
                    const loaded = paymentMode === 'external-checkout' ? !!response.url : !!response.id;
                    shoppingCart.dbId = response.id;
                    setCheckoutUrl(response.url);
                    setOrderNumber(response.orderNumber);
                    setPaymentNumber(response.paymentNumber);
                    setCheckoutLoaded(loaded);
                    sendAlgoliaInsight();
                } else {
                    const type = response && response.error && response.error.type ? response.error.type : undefined;
                    let message;
                    let actionLabel;
                    let action;
                    switch (type) {
                        case 'NO_STOCK':
                            message = 'Algunos de los productos en el carro de compras no tienen stock disponible. Puede cambiarlos o eliminarlos del carro.';
                            actionLabel = 'Ir al carro';
                            action = () => handleChangeProducts();
                            break;
                        default:
                            message = 'Se ha producido un error al iniciar el proceso de checkout';
                            actionLabel = 'Cerrar';
                            action = () => handleClose('checkout-error-close');
                            break;
                    }
                    setError({message, actionLabel, action});
                    setCheckoutUrl(undefined);
                    setCheckoutLoaded(false);
                }
                setIsLoadingCheckout(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLoading = () => {
        return isLoadingCheckout;
    };

    const dataLoaded = () => {
        return checkoutLoaded;
    };

    const handleChangeProducts = () => {
        Activity.log(metadata, 'checkout', 'no-stock-error', 'open-cart-button', shoppingCart.toActivityLog());
        if (openShoppingCart) openShoppingCart();
        silentClose();
    };

    const sendAlgoliaInsight = () => {
        if (metadata && metadata.algoliaInsights) {
            const algoliaInsights = metadata.algoliaInsights;
            const items = shoppingCart.items;
            const queryIds = items.map(item => item.queryId);
            const queryId = queryIds.length > 0 ? queryIds[0] : undefined;
            const matches = queryId ? items.filter(item => item.queryId === queryId) : [];
            const sameQueryId = matches.length === items.length;
            const productsIds = items.map(item => item.product);
            if (metadata && metadata.session && metadata.session.id) {
                if (sameQueryId) algoliaInsights.convertedObjectIDsAfterSearch(metadata.session.id, queryId, productsIds);
                else algoliaInsights.convertedObjectIDs(metadata.session.id, productsIds);
            }
        }
    };

    const renderLoader = () => {
        return (
            <div className='payment-modal-loader'>
                <DataLoader message='Iniciando interfaz de pago...'/>
            </div>
        );
    };

    const renderError = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message={error.message}
                    actionLabel={error.actionLabel}
                    action={error.action}
                    actionButtonLabelColor={actionButtonLabelColor}
                    actionButtonBackgroundColor={actionButtonBackgroundColor}
                    actionButtonBorderColor={actionButtonBorderColor}
                    maxWidth={500}
                />
            </div>
        );
    };

    const renderFlow = () => {
        switch (paymentMode) {
            case 'external-checkout':
                return renderExternalCheckout();
            case 'integrated-pos':
                return renderIntegratedPOS();
            case 'budget-print':
                return renderBudgetPrint();
            default:
                return renderInvalidFlow();
        }
    };

    const renderExternalCheckout = () => {
        return (
            <ExternalCheckout
                checkoutUrl={checkoutUrl}
                shoppingCart={shoppingCart}
                smartphoneOptionImage={smartphoneOptionImage}
                kioskOptionImage={kioskOptionImage}
                smartphoneOptionQrScanImage={smartphoneOptionQrScanImage}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                resetPromotionalVideoTimer={resetPromotionalVideoTimer}
                onClose={silentClose}
                metadata={metadata}
            />
        );
    };

    const renderIntegratedPOS = () => {
        return (
            <IntegratedPOS
                instance={instance}
                client={client}
                store={store}
                shoppingCart={shoppingCart}
                orderNumber={orderNumber}
                paymentNumber={paymentNumber}
                pk={pk}
                taxMultiplier={taxMultiplier}
                mainClientLogo={mainClientLogo}
                personOptionImage={personOptionImage}
                companyOptionImage={companyOptionImage}
                cardOptionImage={cardOptionImage}
                otherPaymentOptionImage={otherPaymentOptionImage}
                ticketOptionImage={ticketOptionImage}
                invoiceOptionImage={invoiceOptionImage}
                timerImage={timerImage}
                printMessageImage={printMessageImage}
                paymentSuccessImage={paymentSuccessImage}
                getHelpErrorImage={getHelpErrorImage}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                customInputOutline={customInputOutline}
                customInputClearButtonBackgroundColor={customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={customInputClearButtonLabelColor}
                inSmallMode={inSmallMode}
                onClose={silentClose}
                allowClosing={allowClosing}
                preventClosing={preventClosing}
                flowFinished={flowFinished}
                openShoppingCart={openShoppingCart}
                metadata={metadata}
            />
        );
    };

    const renderBudgetPrint = () => {
        return (
            <Signup
                flow='budget-print'
                initialData={undefined}
                instance={instance}
                store={store}
                shoppingCart={shoppingCart}
                orderNumber={orderNumber}
                allowBudgetPrint={true}
                pk={pk}
                mainClientLogo={mainClientLogo}
                personOptionImage={personOptionImage}
                companyOptionImage={companyOptionImage}
                printMessageImage={printMessageImage}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                customInputOutline={customInputOutline}
                customInputClearButtonBackgroundColor={customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={customInputClearButtonLabelColor}
                inSmallMode={inSmallMode}
                onClose={silentClose}
                onRegisterFinished={undefined}
                flowFinished={flowFinished}
                metadata={metadata}
            />
        );
    };

    const renderInvalidFlow = () => {
        return (
            <div className='payment-modal-error'>
                <DataError
                    message='Flujo de pago inválido. Por favor, contacte a soporte técnico.'
                    actionLabel='Cerrar'
                    action={() => handleClose('invalid-flow-close')}
                    actionButtonLabelColor={actionButtonLabelColor}
                    actionButtonBackgroundColor={actionButtonBackgroundColor}
                    actionButtonBorderColor={actionButtonBorderColor}
                />
            </div>
        );
    };

    return (
        <React.Fragment>
            {isLoading() && renderLoader()}
            {!isLoading() && !dataLoaded() && renderError()}
            {!isLoading() && dataLoaded() && renderFlow()}
        </React.Fragment>
    );
}

export default PaymentModal;