import React, {useState} from 'react';
import Modal from '../Modal/Modal';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import CrossSellModal from '../CrossSellModal/CrossSellModal';
import PaymentModal from '../PaymentModal/PaymentModal';

function CheckoutModal({
    catalog,
    instance,
    client,
    store,
    shoppingCart,
    paymentMode,
    pk,
    taxMultiplier,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    clientName,
    productBoxDesign,
    _3dIcon,
    featureIcon,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    offerBackgroundColor,
    offerLabelColor,
    freeDeliveryIcon,
    freeDeliveryLabelColor,
    freeDeliveryBackgroundColor,
    deliveryIcon,
    deliveryLabelColor,
    deliveryBackgroundColor,
    pickupIcon,
    pickupLabelColor,
    pickupBackgroundColor,
    externalSaleBorderColor,
    externalSaleBackgroundColor,
    externalSaleLabelColor,
    sellerLabelColor,
    mainSellerIcon,
    mainClientLogo,
    kioskOptionImage,
    smartphoneOptionImage,
    smartphoneOptionQrScanImage,
    personOptionImage,
    companyOptionImage,
    cardOptionImage,
    otherPaymentOptionImage,
    ticketOptionImage,
    invoiceOptionImage,
    timerImage,
    printMessageImage,
    paymentSuccessImage,
    getHelpErrorImage,
    backButtonBackgroundColor,
    backButtonBorderColor,
    backButtonLabelColor,
    customInputOutline,
    customInputClearButtonBackgroundColor,
    customInputClearButtonLabelColor,
    showDefaultCloseButton,
    createShoppingCart,
    openShoppingCart,
    resetPromotionalVideoTimer,
    onClose,
    metadata
}) {
    const [mode, setMode] = useState(paymentMode !== 'budget-print' ? 'cross-sell' : 'payment');
    const [allowClose, setAllowClose] = useState(true);
    const [isFlowFinished, setIsFlowFinished] = useState(false);

    const smallModeTreshold = {width: 1000, height: 850};

    const inSmallMode = () => {
        const viewport = Viewport.dimensions;
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        if (!allowClose) return;
        if (closeType !== 'silent') Activity.log(metadata, 'checkout', closeType, 'close', {mode: mode || null});
        if (shoppingCart) shoppingCart.dbId = undefined;
        if (isFlowFinished && createShoppingCart) createShoppingCart();
        if (onClose) onClose();
    };

    const silentClose = () => {
        handleClose('silent');
    };

    const allowClosing = () => {
        setAllowClose(true);
    };

    const preventClosing = () => {
        setAllowClose(false);
    };

    const toCheckout = () => {
        setMode('payment');
    };

    const flowFinished = () => {
        setIsFlowFinished(true);
    };

    const renderCrossSellModal = () => {
        return (
            <CrossSellModal
                catalog={catalog}
                client={client}
                shoppingCart={shoppingCart}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                clientName={clientName}
                productBoxDesign={productBoxDesign}
                _3dIcon={_3dIcon}
                featureIcon={featureIcon}
                discountBackgroundColor={discountBackgroundColor}
                discountLabelColor={discountLabelColor}
                exclusivePriceLabelColor={exclusivePriceLabelColor}
                exclusivePriceIcon={exclusivePriceIcon}
                offerBackgroundColor={offerBackgroundColor}
                offerLabelColor={offerLabelColor}
                freeDeliveryIcon={freeDeliveryIcon}
                freeDeliveryLabelColor={freeDeliveryLabelColor}
                freeDeliveryBackgroundColor={freeDeliveryBackgroundColor}
                deliveryIcon={deliveryIcon}
                deliveryLabelColor={deliveryLabelColor}
                deliveryBackgroundColor={deliveryBackgroundColor}
                pickupIcon={pickupIcon}
                pickupLabelColor={pickupLabelColor}
                pickupBackgroundColor={pickupBackgroundColor}
                externalSaleBorderColor={externalSaleBorderColor}
                externalSaleBackgroundColor={externalSaleBackgroundColor}
                externalSaleLabelColor={externalSaleLabelColor}
                sellerLabelColor={sellerLabelColor}
                mainSellerIcon={mainSellerIcon}
                toCheckout={toCheckout}
                onClose={silentClose}
                metadata={metadata}
            />
        );
    };

    const renderPaymentModal = () => {
        return (
            <PaymentModal
                instance={instance}
                client={client}
                store={store}
                shoppingCart={shoppingCart}
                paymentMode={paymentMode}
                pk={pk}
                taxMultiplier={taxMultiplier}
                mainClientLogo={mainClientLogo}
                kioskOptionImage={kioskOptionImage}
                smartphoneOptionImage={smartphoneOptionImage}
                smartphoneOptionQrScanImage={smartphoneOptionQrScanImage}
                personOptionImage={personOptionImage}
                companyOptionImage={companyOptionImage}
                cardOptionImage={cardOptionImage}
                otherPaymentOptionImage={otherPaymentOptionImage}
                ticketOptionImage={ticketOptionImage}
                invoiceOptionImage={invoiceOptionImage}
                timerImage={timerImage}
                printMessageImage={printMessageImage}
                paymentSuccessImage={paymentSuccessImage}
                getHelpErrorImage={getHelpErrorImage}
                actionButtonBackgroundColor={actionButtonBackgroundColor}
                actionButtonBorderColor={actionButtonBorderColor}
                actionButtonLabelColor={actionButtonLabelColor}
                cancelButtonBackgroundColor={cancelButtonBackgroundColor}
                cancelButtonBorderColor={cancelButtonBorderColor}
                cancelButtonLabelColor={cancelButtonLabelColor}
                backButtonBackgroundColor={backButtonBackgroundColor}
                backButtonBorderColor={backButtonBorderColor}
                backButtonLabelColor={backButtonLabelColor}
                customInputOutline={customInputOutline}
                customInputClearButtonBackgroundColor={customInputClearButtonBackgroundColor}
                customInputClearButtonLabelColor={customInputClearButtonLabelColor}
                inSmallMode={inSmallMode()}
                openShoppingCart={openShoppingCart}
                resetPromotionalVideoTimer={resetPromotionalVideoTimer}
                allowClosing={allowClosing}
                preventClosing={preventClosing}
                flowFinished={flowFinished}
                handleClose={handleClose}
                silentClose={silentClose}
                metadata={metadata}
            />
        );
    };

    return (
        <Modal
            mode='center'
            smallMode={inSmallMode()}
            backgroundColor='#FFFFFF'
            borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}}
            padding={0}
            width={1000}
            maxWidth='100%'
            height='auto'
            maxHeight='100%'
            showDefaultCloseButton={showDefaultCloseButton}
            enableCloseButton={allowClose}
            customDefaultCloseButtonPosition={showDefaultCloseButton ? {top: -10, right: -5, bottom: 'unset', left: 'unset'} : undefined}
            onClose={handleClose}
        >
            {mode === 'cross-sell' && renderCrossSellModal()}
            {mode === 'payment' && renderPaymentModal()}
        </Modal>
    );
}

export default CheckoutModal;