import React, {useState, useEffect, useRef} from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import {Timer} from '../../helpers/Timer';
import {getRemainingTime} from '../../config/App';
import './InactivityModal.css';

function InactivityModal({
    timerLabelColor,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    onReset,
    onClose,
    metadata
}) {
    const [remainingTimeUntilReset, setRemainingTimeUntilReset] = useState(getRemainingTime());
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 768, height: 600};
    const intervalRef = useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setRemainingTimeUntilReset(getRemainingTime());
        }, 1000);
        return() => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        }
    }, []);

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleReset = () => {
        if (onReset) {
            Activity.log(metadata, 'inactivity-modal', 'to-start-button', 'close-session', undefined);
            Activity.log(metadata, 'kiosk', 'session', 'end', undefined); // Session close event.
            const redirect = window.location.pathname !== '/';
            setTimeout(() => {onReset(redirect)}, 100);
        }
    };

    const handleClose = (closeType) => {
        if (onClose) {
            Activity.log(metadata, 'inactivity-modal', closeType, 'close', undefined);
            onClose();
        }
    };

    const secondsToTimer = () => {
        const timer = new Timer({seconds: remainingTimeUntilReset});
        return timer.secondsToTimer();
    };

    const timerStyle = () => {
        return {
            color: timerLabelColor || '#005B80'
        };
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={40} width={800} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            <div className='inactivity-modal'>
                <React.Fragment>
                    <p className='inactivity-modal-title'>Alerta de inactividad</p>
                    <p className='inactivity-modal-description'>Este kiosco se reiniciará en:</p>
                    <p className='inactivity-modal-timer' style={timerStyle()}>{secondsToTimer()}</p>
                    <p className='inactivity-modal-title'>¿Qué deseas hacer?</p>
                </React.Fragment>
                <div className='inactivity-modal-actions'>
                    <div className='inactivity-modal-action'>
                        <Button label='Seguir comprando' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} minWidth={230} maxWidth='100%' boxShadow='unset' onClick={() => handleClose('custom-close-button')}/>
                    </div>
                    <div className='inactivity-modal-action'>
                        <Button label='Volver al inicio' labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} minWidth={230} maxWidth='100%' boxShadow='unset' onClick={handleReset}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default InactivityModal