import React, {useRef, useEffect, useState} from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import SpinnerLoader from '../SpinnerLoader/SpinnerLoader';
import ImageWrapper from "../ImageWrapper/ImageWrapper";
import Debug from '../../helpers/Debug';
import Viewport from '../../helpers/Viewport';
import Activity from '../../helpers/Activity';
import WebServices from '../../helpers/WebServices';
import {ShoppingCart as _ShoppingCart, ShoppingCartItem as _ShoppingCartItem} from '../../models/ShoppingCart';
import defaultQrScanImage from "./images/scan.gif";
import packageIcon from './images/package.png';
import truckIcon from './images/truck.png';
import planeIcon from './images/plane.png';
import QRCode from 'qrcode';
import './ExternalProductModal.css';

function ExternalProductModal({
    queryId,
    client,
    clientName,
    store,
    product,
    variant,
    mainSellerIcon,
    qrScanImage,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    cancelButtonBackgroundColor,
    cancelButtonBorderColor,
    cancelButtonLabelColor,
    onClose,
    metadata
}) {
    const defaultQrCodeUrl = 'https://www.motiondisplays.com';
    const canvasRef = useRef(null);
    const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
    const [checkoutLoaded, setCheckoutLoaded] = useState(false);
    const [error, setError] = useState(undefined);
    const [qrCodeUrl, setQrCodeUrl] = useState(defaultQrCodeUrl);
    const viewport = Viewport.dimensions;
    const smallModeTreshold = {width: 768, height: 600};

    useEffect(() => {
        renderQrCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        renderQrCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrCodeUrl]);

    const inSmallMode = () => {
        return viewport.width <= smallModeTreshold.width || viewport.height <= smallModeTreshold.height;
    };

    const handleClose = (closeType) => {
        Activity.log(metadata, 'external-product-modal', closeType, 'close', {product_id: product.id, product_name: product.name, variant_id: variant ? variant.id : null, variant_name: variant ? variant.name : null});
        if (onClose) onClose();
    };

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    };

    const createQRCode = () => {
        const categoryPath = product.categoryPaths && product.categoryPaths.length > 0 ? product.categoryPaths[0] : null;
        Activity.log(metadata, 'external-product-modal', 'create-qr-button', error ? 'retry' : 'create', {product_id: product.id, product_name: product.name, variant_id: variant ? variant.id : null, variant_name: variant ? variant.name : null, category_path: categoryPath, error: error || null});
        setIsLoadingCheckout(true);
        setCheckoutLoaded(false);
        const source = variant ? variant : product;
        const type = variant ? 'variant' : 'product';
        const id = `${client}-cart-${new Date().getTime()}`;
        const shoppingCart = new _ShoppingCart({id: id, dbId: undefined, client: client, items: []});
        const item = new _ShoppingCartItem({
            id: `${client}-${source.id}`,
            product: source.id,
            type: type,
            parent: type === 'variant' ? product.id : undefined,
            name: source.name,
            quantity: 1,
            minQuantity: 1,
            maxQuantity: source.maxPerItem,
            normalPrice: source.normalPrice,
            offerPrice: source.offerPrice,
            exclusivePrice: source.exclusivePrice,
            queryId: queryId
        });
        shoppingCart.addItem(item);
        WebServices.getClientShoppingCartUrl(client, store.id, shoppingCart, true)
            .then(response => {
                if (response && response.url) {
                    clearCanvas();
                    setIsLoadingCheckout(false);
                    setCheckoutLoaded(true);
                    setQrCodeUrl(response.url);
                    setError(undefined);
                    sendAlgoliaInsight(shoppingCart);
                } else {
                    setIsLoadingCheckout(false);
                    setCheckoutLoaded(false);
                    setQrCodeUrl(defaultQrCodeUrl);
                    setError('Se ha producido un error al iniciar el proceso de checkout');
                }
            });
    };

    const sendAlgoliaInsight = (shoppingCart) => {
        if (metadata && metadata.algoliaInsights) {
            const algoliaInsights = metadata.algoliaInsights;
            const item = shoppingCart.items.length > 0 ? shoppingCart.items[0] : undefined;
            if (metadata && metadata.session && metadata.session.id) {
                if (queryId) algoliaInsights.convertedObjectIDsAfterSearch(metadata.session.id, queryId, [item.product]);
                else algoliaInsights.convertedObjectIDs(metadata.session.id, [item.product]);
            }
        }
    };

    const hasSeller = () => {
        return (product.externalSale && product.sellerName) || (!product.externalSale && !!clientName);
    };

    const hasDelivery = () => {
        return !sameSellerAndDelivery() && product.clientDelivery && !!clientName;
    }

    const sameSellerAndDelivery = () => {
        return !product.externalSale && product.clientDelivery;
    };

    const hasInternationalShipping = () => {
        return !product.clientDelivery && product.internationalShipping;
    };

    const buildSellerMessage = () => {
        let message = '';
        if (hasSeller()) {
            message += sameSellerAndDelivery() ? 'Vendido y enviado por ' : 'Vendido por ';
            message += product.externalSale ? product.sellerName : clientName;
        }
        if (hasDelivery()) {
            message += hasSeller() ? ' y e' : 'E';
            message += `nviado por ${clientName}`;
        }
        return message;
    };

    const renderSellerDetails = () => {
        return (
            <div className='external-product-modal-details'>
                {mainSellerIcon && (
                    <div className='external-product-modal-details-icon-container'>
                        <ImageWrapper image={mainSellerIcon} alt='Seller' display='block' width={40} height='auto' loadDelay={0}/>
                    </div>
                )}
                <div className='external-product-modal-details-title'>{buildSellerMessage()}</div>
                <div className='external-product-modal-details-info-container'>
                    <div className='external-product-modal-details-info'>
                        <div className='external-product-modal-details-info-icon'>
                            <ImageWrapper image={packageIcon} alt='Package' display='block' width={40} height='auto' loadDelay={0}/>
                        </div>
                        <p className='external-product-modal-details-info-message'>Producto preparado y despachado desde
                            nuestro centro de distribución</p>
                    </div>
                    <div className='external-product-modal-details-info'>
                        <div className='external-product-modal-details-info-icon'>
                            <ImageWrapper image={truckIcon} alt='Package' display='block' width={40} height='auto' loadDelay={0}/>
                        </div>
                        <p className='external-product-modal-details-info-message'>Envío más expedito</p>
                    </div>
                </div>
            </div>
        );
    };

    const renderInternationalShippingDetails = () => {
        return (
            <div className='external-product-modal-details'>
                <div className='external-product-modal-details-icon-container'>
                    <ImageWrapper image={planeIcon} alt='Seller' display='block' width={40} height='auto' loadDelay={0}/>
                </div>
                <div className='external-product-modal-details-title'>Compra internacional</div>
                <div className='external-product-modal-details-info-container'>
                    <div className='external-product-modal-details-info'>
                        <p className='external-product-modal-details-info-message'>- El precio publicado <b>incluye todos los costos de importación</b> (costos logísticos y pagos de impuestos en aduana)</p>
                    </div>
                    <div className='external-product-modal-details-info'>
                        <p className='external-product-modal-details-info-message'>- En caso de que tu producto sea fiscalizado en el aeropuerto, <b>no tendrás que incurrir en ningún gasto</b></p>
                    </div>
                    <div className='external-product-modal-details-info'>
                        <p className='external-product-modal-details-info-message'>- En productos de perfumería y cosmetología <b>podrían exigirte autorización de importación</b></p>
                    </div>
                    <div className='external-product-modal-details-info'>
                        <p className='external-product-modal-details-info-message'>- Si compras celulares, <b>tendrás que registrarlo en tu país</b></p>
                    </div>
                </div>
            </div>
        );
    };

    const renderQrCode = () => {
        if (qrCodeUrl) {
            const canvas = canvasRef.current;
            QRCode.toCanvas(canvas, qrCodeUrl, {width: 200, height: 200}, (error) => {
                if (error) {
                    Debug.printToLog('error', error);
                    setError('Se ha producido un error al renderizar el código QR');
                }
            });
        } else {
            setError('Se ha producido un error al generar un código QR válido');
        }
    };

    return (
        <Modal mode='center' smallMode={inSmallMode()} backgroundColor='#FFFFFF' borderRadius={{topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50}} padding={40} width={800} maxWidth='100%' height='auto' maxHeight='100%' showDefaultCloseButton={false} onClose={handleClose}>
            <div className='external-product-modal'>
                <React.Fragment>
                    <p className='external-product-modal-title'><span className='bold'>Finaliza tu compra en nuestro sitio web</span>
                    </p>
                    <p className='external-product-modal-description'>Escanea este código QR para <span className='bold'>continuar con tu compra desde tu celular</span></p>
                    <div className='external-product-modal-content'>
                        {(hasSeller() || hasInternationalShipping()) && !checkoutLoaded && (
                            <div className='external-product-modal-left-panel'>
                                {hasSeller() && !hasInternationalShipping() && renderSellerDetails()}
                                {hasInternationalShipping() && renderInternationalShippingDetails()}
                            </div>
                        )}
                        {checkoutLoaded && (
                            <div className='external-product-modal-left-panel'>
                                <div className='external-product-modal-qr-guide-container'>
                                    <ImageWrapper image={qrScanImage || defaultQrScanImage} alt='Scan' display='block' width={200} height={200} borderRadius='100%' loadDelay={0.2}/>
                                </div>
                            </div>
                        )}
                        <div className='external-product-modal-right-panel'>
                            <div className='external-product-modal-qr-image-container'>
                                <div className='external-product-modal-qr-image-wrapper'>
                                    <canvas ref={canvasRef} className='external-product-modal-qr-image-canvas'></canvas>
                                    {isLoadingCheckout && (
                                        <div className='external-product-modal-qr-image-loader-container'>
                                            <div className='external-product-modal-qr-image-loader'>
                                                <SpinnerLoader size={80} color='#FFFFFF'/>
                                            </div>
                                        </div>
                                    )}
                                    {!isLoadingCheckout && !checkoutLoaded && (
                                        <div className='external-product-modal-qr-image-generator' onClick={createQRCode}>
                                            <div className='external-product-modal-qr-image-generator-message'>{error ? 'Reintentar' : 'Presiona para generar un código QR'}</div>
                                        </div>
                                    )}
                                </div>
                                <p className={`external-product-modal-qr-image-description${error ? ' error' : ''}`}>
                                    {error ? error : 'Escanea el código'}
                                </p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                <div className='external-product-modal-actions'>
                    <div className='external-product-modal-action'>
                        <Button label='Cerrar' labelColor={cancelButtonLabelColor} backgroundColor={cancelButtonBackgroundColor} borderColor={cancelButtonBorderColor} minWidth='auto' maxWidth='100%' boxShadow='unset' onClick={() => handleClose('custom-close-button')}/>
                    </div>
                    {!checkoutLoaded && (
                        <div className='external-product-modal-action'>
                            <Button label={clientName ? `Comprar en ${clientName}` : 'Comprar'} labelColor={actionButtonLabelColor} backgroundColor={actionButtonBackgroundColor} borderColor={actionButtonBorderColor} minWidth='auto' maxWidth='100%' showPulseEffect={true} onClick={createQRCode}/>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default ExternalProductModal;