import {OTHER_FILTERS_IGNORED_KEYS} from "../config/App";
import Text from "../helpers/Text";
import Storage from "../helpers/Storage";

export class Filter {
    constructor({id, label, type, animate, options}) {
        this.id = id;
        this.label = label;
        this.type = type;
        this.animate = animate;
        this.options = options || [];
    };

    clone() {
        return new Filter({id: this.id, label: this.label, type: this.type, animate: this.animate, options: this.options.map(option => option.clone())});
    };

    static buildFilters = (products, showExternalSale, externalSaleLabel, clientName) => {
        let filters = [];
        filters = filters.concat(Filter.buildBrandsFilters(products));
        filters = filters.concat(Filter.buildSizeFilters(products));
        filters = filters.concat(Filter.buildPriceFilters(products));
        filters = filters.concat(Filter.buildOtherFilters(products, showExternalSale, externalSaleLabel, clientName));
        return filters;
    };

    static buildBrandsFilters = (products) => {
        const filter = new Filter({id: 'brands-filter', label: 'Marcas', type: 'brands', animate: false, options: []});
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            const brand = product.brand;
            if (!brand) continue;
            const label = Text.capitalizeAll(brand);
            const hash = Text.toHash(label);
            const optionMatchIndex = filter.options.findIndex(option => option.id === hash);
            if (optionMatchIndex < 0) {
                const option = new NormalFilterOption({id: hash, label: label, selected: false});
                filter.options.push(option);
            }
        }
        return filter.options.length > 1 ? [filter] : [];
    };

    static buildSizeFilters = (products) => {
        let ranges = {
            width:  {min: undefined, max: undefined},
            height: {min: undefined, max: undefined},
            depth:  {min: undefined, max: undefined},
            unit: undefined
        };
        products.forEach((product) => {
            const size = product.size();
            if (size.width)  ranges.width.min  = !ranges.width.min  || size.width  < ranges.width.min  ? Math.round(size.width)  : ranges.width.min;
            if (size.width)  ranges.width.max  = !ranges.width.max  || size.width  > ranges.width.max  ? Math.round(size.width)  : ranges.width.max;
            if (size.height) ranges.height.min = !ranges.height.min || size.height < ranges.height.min ? Math.round(size.height) : ranges.height.min;
            if (size.height) ranges.height.max = !ranges.height.max || size.height > ranges.height.max ? Math.round(size.height) : ranges.height.max;
            if (size.depth)  ranges.depth.min  = !ranges.depth.min  || size.depth  < ranges.depth.min  ? Math.round(size.depth)  : ranges.depth.min;
            if (size.depth)  ranges.depth.max  = !ranges.depth.max  || size.depth  > ranges.depth.max  ? Math.round(size.depth)  : ranges.depth.max;
            if (size.unit)   ranges.unit       = size.unit;
        });
        const validWidthRange  = ranges.width.min  && ranges.width.max  && ranges.width.min  !== ranges.width.max  && ranges.width.min  < ranges.width.max;
        const validHeightRange = ranges.height.min && ranges.height.max && ranges.height.min !== ranges.height.max && ranges.height.min < ranges.height.max;
        const validDepthRange  = ranges.depth.min  && ranges.depth.max  && ranges.depth.min  !== ranges.depth.max  && ranges.depth.min  < ranges.depth.max;
        if (validWidthRange || validHeightRange || validDepthRange) {
            const filter = new Filter({id: 'size-filter', label: 'Dimensiones', type: 'size', animate: false, options: []});
            if (validWidthRange)  filter.options.push(new SizeFilterOption({id: 'width-range',  label: 'Ancho',       value: ranges.width.max,  min: ranges.width.min,  max: ranges.width.max,  step: 1, unit: ranges.unit}));
            if (validHeightRange) filter.options.push(new SizeFilterOption({id: 'height-range', label: 'Alto',        value: ranges.height.max, min: ranges.height.min, max: ranges.height.max, step: 1, unit: ranges.unit}));
            if (validDepthRange)  filter.options.push(new SizeFilterOption({id: 'depth-range',  label: 'Profundidad', value: ranges.depth.max,  min: ranges.depth.min,  max: ranges.depth.max,  step: 1, unit: ranges.unit}));
            return [filter];
        } else return [];
    };

    static buildPriceFilters = (products) => {
        let minProductsPrice = undefined;
        products.forEach((product) => {
            const minPrice = product.minPrice();
            if (minPrice) minProductsPrice = !minProductsPrice || minPrice < minProductsPrice ? minPrice : minProductsPrice;
        });
        let maxProductsPrice = undefined;
        products.forEach((product) => {
            const maxPrice = product.maxPrice();
            if (maxPrice) maxProductsPrice = !maxProductsPrice || maxPrice > maxProductsPrice ? maxPrice : maxProductsPrice;
        });
        if (minProductsPrice && maxProductsPrice) {
            return [new Filter({id: 'price-filter', label: 'Rango de precios', type: 'price', animate: false, options: [
                new PriceFilterOption({id: 'price-range', label: 'Precio', startValue: minProductsPrice, endValue: maxProductsPrice, min: minProductsPrice, max: maxProductsPrice, step: 1, currency: 'CLP'})
            ]})];
        } else return [];
    };

    static buildOtherFilters = (products, showExternalSale, externalSaleLabel, clientName) => {
        let filters = [];
        if (showExternalSale) filters.push(Filter.buildExternalSaleFilters(externalSaleLabel, clientName));
        products.forEach((product) => {
            const features = product.indexableFeatures().filter(feature => feature.indexable);
            for (let i = 0; i < features.length; i++) {
                const feature = features[i];
                if (!feature.name || !feature.value) continue;
                if (!OTHER_FILTERS_IGNORED_KEYS.includes(Text.minify(feature.name))) {
                    const filterName = Text.capitalizeFirst(feature.name);
                    const filterHash = Text.toHash(filterName);
                    const optionValue = Text.capitalizeFirst(feature.value);
                    const optionHash = Text.toHash(optionValue);
                    const option = new NormalFilterOption({id: optionHash, label: optionValue, selected: false});
                    const filter = new Filter({id: filterHash, label: filterName, type: 'normal', animate: false, options: [option]});
                    const filterMatchIndex = filters.findIndex(filter => filter.id === filterHash);
                    if (filterMatchIndex >= 0) {
                        const optionMatchIndex = filters[filterMatchIndex].options.findIndex(option => option.id === optionHash);
                        if (optionMatchIndex < 0) filters[filterMatchIndex].options.push(option);
                    } else filters.push(filter);
                }
            }
        });
        return filters;
    };

    static buildExternalSaleFilters = (clientName, externalSaleLabel) => {
        const filter = new Filter({id: Text.toHash('external-sale-filter'), label: 'Vendido por', type: 'external-sale', options: []});
        filter.options.push(new NormalFilterOption({id: Text.toHash('external-sale-true'), label: externalSaleLabel || 'Desconocido', selected: false}));
        filter.options.push(new NormalFilterOption({id: Text.toHash('external-sale-false'), label: clientName || 'Desconocido', selected: false}));
        return filter;
    };

    static getSelectedFilters = (filters) => {
        let selectedFilters = [];
        filters.forEach((filter) => {
            let filterClone = filter.clone();
            filterClone.options = [];
            filter.options.forEach((option) => {
                switch (filter.type) {
                    case 'size':
                        if (option.value >= option.min && option.value < option.max) filterClone.options.push(option.clone());
                        break;
                    case 'price':
                        if (option.startValue > option.min || option.endValue < option.max) filterClone.options.push(option.clone());
                        break;
                    default:
                        if (option.selected) filterClone.options.push(option.clone());
                        break;
                }
            });
            if (filterClone.options.length > 0) selectedFilters.push(filterClone);
        });
        return selectedFilters;
    };

    static toStorage = (type, key, filters) => {
        let storageFilters = {type: type, key: key, filters: []};
        filters.forEach((filter) => {
            const options = [];
            filter.options.forEach((option) => {
                switch (filter.type) {
                    case 'size':
                        if (option.value > option.min && option.value <= option.max) options.push({id: option.id, value: option.value});
                        break;
                    case 'price':
                        if (option.startValue > option.min || option.endValue < option.max) options.push({id: option.id, startValue: option.startValue, endValue: option.endValue});
                        break;
                    default:
                        if (option.selected) options.push({id: option.id, value: true});
                        break;
                }
            });
            if (filter.options.length > 0) storageFilters.filters.push({id: filter.id, options: options});
        });
        return storageFilters;
    };

    static fromStorage = (categoryId, searchQuery, filters) => {
        const storageData = Storage.getFilters();
        const storageType = storageData ? storageData['type'] : undefined;
        const storageKey = storageData ? storageData['key'] : undefined;
        const storageFilters = storageData ? storageData['filters'] : undefined;
        let isCategory = storageType === 'category' && categoryId && storageKey === categoryId;
        let isSearch = storageType === 'search' && !categoryId && storageKey === searchQuery;
        if (isCategory || isSearch) {
            storageFilters.forEach((storageFilter) => {
                storageFilter.options.forEach((storageOption) => {
                    const filter = filters.find(filter => filter.id === storageFilter.id);
                    if (filter) {
                        const option = filter.options.find(option => option.id === storageOption.id);
                        if (option) {
                            filter.animate = true;
                            switch (filter.type) {
                                case 'size':
                                    option.setValue(storageOption.value);
                                    break;
                                case 'price':
                                    option.setStartValue(storageOption.startValue);
                                    option.setEndValue(storageOption.endValue);
                                    break;
                                default:
                                    option.select();
                                    break;
                            }
                        }
                    }
                });
            });
        } else Storage.removeFilters();
        return filters;
    };
}

export class NormalFilterOption {
    constructor({id, label, selected}) {
        this.id = id;
        this.label = label;
        this.selected = selected;
    };

    select() {
        this.selected = true;
    };

    deselect() {
        this.selected = false;
    };

    toggle() {
        if (this.selected) this.deselect();
        else this.select();
    };

    clone() {
        return new NormalFilterOption({id: this.id, label: this.label, selected: this.selected});
    };
}

export class SizeFilterOption {
    constructor({id, label, value, min, max, step, unit}) {
        this.id = id;
        this.label = label;
        this.value = value;
        this.min = min;
        this.max = max;
        this.step = step;
        this.unit = unit;
    };

    setValue(value) {
        this.value = value;
    };

    deselect() {
        this.setValue(this.max);
    };

    clone() {
        return new SizeFilterOption({id: this.id, label: this.label, value: this.value, min: this.min, max: this.max, step: this.step, unit: this.unit});
    };
}

export class PriceFilterOption {
    constructor({id, label, startValue, endValue, min, max, step, currency}) {
        this.id = id;
        this.label = label;
        this.startValue = startValue;
        this.endValue = endValue;
        this.min = min;
        this.max = max;
        this.step = step;
        this.currency = currency;
    };

    setStartValue(value) {
        this.startValue = value;
    };

    setEndValue(value) {
        this.endValue = value;
    };

    deselect() {
        this.setStartValue(this.min);
        this.setEndValue(this.max);
    };

    clone() {
        return new PriceFilterOption({id: this.id, label: this.label, startValue: this.startValue, endValue: this.endValue, min: this.min, max: this.max, step: this.step, currency: this.currency});
    };
}