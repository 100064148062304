import React, {useState, useRef, useEffect} from 'react';
import CrossLoader from '../../CrossLoader/CrossLoader';
import LinkButton from '../../LinkButton/LinkButton';
import ImageWrapper from '../../ImageWrapper/ImageWrapper';
import Button from '../../Button/Button';
import Viewport from '../../../helpers/Viewport';
import Activity from '../../../helpers/Activity';
import Currency from '../../../config/Currency';
import Navigation from "../../../config/Navigation";
import {Product} from "../../../models/Product";
import CurrencyFormat from 'react-currency-format';
import noImage from './images/no-image.png';
import './ShoppingCartItem.css';

function ShoppingCartItem({
    item,
    product,
    valid,
    discountBackgroundColor,
    discountLabelColor,
    exclusivePriceLabelColor,
    exclusivePriceIcon,
    actionButtonBackgroundColor,
    actionButtonBorderColor,
    actionButtonLabelColor,
    onQuantityIncrease,
    onQuantityDecrease,
    onDelete,
    metadata
}) {
    const imageContainerRef = useRef(null);
    const imageRef = useRef(null);
    const [isLoadingImage, setIsLoadingImage] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const currencyFormat = Currency.CLP;
    const viewport = Viewport.dimensions;

    useEffect(() => {
        const image = imageRef.current;
        if (!image) return;
        image.onload = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(true);
            }, 1000);
        };
        image.onerror = () => {
            setTimeout(() => {
                setIsLoadingImage(false);
                setImageLoaded(false);
            }, 1000);
        };
        image.src = getImage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const container = imageContainerRef.current;
        const image = imageRef.current;
        if (!image) return;
        const newImage = getImage();
        const oldImage = image.src;
        if (newImage) {
            if (newImage !== oldImage) {
                setIsLoadingImage(true);
                setTimeout(() => {
                    image.src = newImage;
                    container.style.display = 'block';
                }, 500);
            }
        } else {
            image.src = undefined;
            container.style.display = 'none';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.getMainImage('m')]);

    const inSmallMode = () => {
        return viewport.width <= 1000;
    };

    const getImage = () => {
        const image = product.getMainImage('m');
        if (!image) return noImage;
        return !isLoadingImage && !imageLoaded ? noImage : image;
    };

    const totalPricePerProduct = () => {
        const price = product.exclusivePrice || product.offerPrice || product.normalPrice;
        return price * item.quantity;
    };

    const hasNoStock = () => {
        return valid && product.hasAnyPrice() && product.maxPerItem <= 0;
    };

    const notEnoughStock = () => {
        return valid && product.hasAnyPrice() && product.maxPerItem > 0 && item.quantity > product.maxPerItem;
    };

    const hasNoPrice = () => {
        return valid && !product.hasAnyPrice() && product.maxPerItem > 0;
    };

    const hasNoStockNorPrice = () => {
        return valid && !product.hasAnyPrice() && product.maxPerItem <= 0;
    };

    const isOnRequestProduct = () => {
        return valid && product.hasAnyPrice() && product.maxPerItem > 0 && item.quantity <= product.maxPerItem && product.onRequest;
    };

    const handleClick = (event) => {
        event.stopPropagation();
        const productId = product.parent ? product.parent : product.id;
        const variantId = product.parent ? product.id : null;
        Activity.log(metadata, 'shopping-cart-item', 'shopping-cart-item', 'click', {
            item: item.toActivityLog(),
            product: {
                product_id: productId,
                product_name: product.name,
                variant_id: variantId,
                has_3d_model: product.has3dModel || false
            }
        });
        const url = Navigation.getProductUrl(item.type === 'product' ? item.product : item.parent, item.type === 'product' ? undefined : item.product, undefined);
        Navigation.forceRedirect(url);
    };

    const handleDeleteClick = () => {
        if (onDelete) onDelete(item.id);
    };

    const handleQuantityIncreaseClick = (event) => {
        event.stopPropagation();
        if (onQuantityIncrease) onQuantityIncrease(item.id);
    };

    const handleQuantityDecreaseClick = (event) => {
        event.stopPropagation();
        if (onQuantityDecrease) onQuantityDecrease(item.id);
    };

    const viewSimilarProducts = () => {
        let categoryId = product.getMainCategory();
        const url= categoryId ? Navigation.getCategoryUrl(categoryId, undefined, undefined) : Navigation.getHomeUrl();
        Activity.log(metadata, 'shopping-cart-item', 'same-category-products', 'click', item.toActivityLog());
        Navigation.forceRedirect(url);
    };

    const itemDataStyle = () => {
        const total = totalPricePerProduct();
        return {
            justifyContent: total > 0 ? 'space-between' : 'flex-start'
        };
    };

    const imageStyle = () => {
        return {
            opacity: isLoadingImage ? 0 : 1
        };
    };

    const loaderStyle = () => {
        return {
            opacity: isLoadingImage ? 1 : 0
        };
    };

    const exclusivePriceLabelStyle = () => {
        return {
            color: exclusivePriceLabelColor || '#005B80'
        };
    };

    const discountStyle = () => {
        return {
            background: discountBackgroundColor || '#005B80',
            color: discountLabelColor || '#FFFFFF'
        };
    };

    const actionsStyle = () => {
        const showBottomPadding = !hasNoStock() && !hasNoPrice() && !hasNoStockNorPrice();
        const showBottomMargin = !showBottomPadding;
        return {
            padding: showBottomPadding ? '0 20px 20px 20px' : '0 20px',
            margin: showBottomMargin ? '10px 0' : '10px 0 0 0'
        };
    };

    const renderAsExclusivePrice = (price, discount) => {
        return (
            <div className='shopping-cart-item-price exclusive' style={exclusivePriceLabelStyle()}>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {exclusivePriceIcon && (
                    <div className='shopping-cart-item-card'>
                        <ImageWrapper image={exclusivePriceIcon} alt='Card' display='block' width={35} height='auto' loadDelay={0.2}/>
                    </div>
                )}
                {discount && discount > 0 ? <div className='shopping-cart-item-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsOfferPrice = (price, discount) => {
        return (
            <div className='shopping-cart-item-price offer'>
                <CurrencyFormat
                    prefix={currencyFormat.prefix}
                    suffix={currencyFormat.suffix}
                    thousandSeparator={currencyFormat.thousandsSeparator}
                    decimalSeparator={currencyFormat.decimalSeparator}
                    decimalScale={currencyFormat.decimalScale}
                    value={price}
                    displayType='text'
                />
                {discount && discount > 0 ? <div className='shopping-cart-item-discount' style={discountStyle()}>{discount}%</div> : null}
            </div>
        );
    };

    const renderAsNormalPrice = (price) => {
        return (
            <div className='shopping-cart-item-price normal'>
                <span>Antes: </span>
                <div className='shopping-cart-item-normal-price-wrapper'>
                    <CurrencyFormat
                        prefix={currencyFormat.prefix}
                        suffix={currencyFormat.suffix}
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={price}
                        displayType='text'
                    />
                </div>
            </div>
        );
    };

    const renderTotalPrice = (mode) => {
        const total = totalPricePerProduct();
        return (
            total > 0 ? (
                <div className={`shopping-cart-final-price ${mode}`}>
                    <CurrencyFormat
                        prefix={inSmallMode() ? `Total: ${currencyFormat.prefix}` : currencyFormat.prefix}
                        suffix={currencyFormat.suffix}
                        thousandSeparator={currencyFormat.thousandsSeparator}
                        decimalSeparator={currencyFormat.decimalSeparator}
                        decimalScale={currencyFormat.decimalScale}
                        value={total}
                        displayType='text'
                    />
                </div>
            ) : undefined
        );
    };

    const name = product.name || 'Sin nombre';
    const unit = Product.getUnitText(product.saleUnit, true);

    return (
        <div className='shopping-cart-item' onClick={handleClick}>
            <div className='shopping-cart-item-data' style={itemDataStyle()}>
                {valid && (
                    <React.Fragment>
                        <div ref={imageContainerRef} className='shopping-cart-item-image-wrapper'>
                            <img ref={imageRef} className='shopping-cart-item-image' alt={name} style={imageStyle()}/>
                            <div className='shopping-cart-item-image-loader' style={loaderStyle()}>
                                <CrossLoader/>
                            </div>
                        </div>
                        <div className='shopping-cart-item-details'>
                            <div className='shopping-cart-item-name'>{name}</div>
                            {product.hasAnyPrice() && (
                                <div className='shopping-cart-item-prices'>
                                    {product.exclusivePrice && renderAsExclusivePrice(product.exclusivePrice, product.getExclusiveDiscountPercentage())}
                                    {product.offerPrice && renderAsOfferPrice(product.offerPrice, product.getOfferDiscountPercentage())}
                                    {product.normalPrice && product.normalPrice !== product.offerPrice && (product.offerPrice ? renderAsNormalPrice(product.normalPrice) : renderAsOfferPrice(product.normalPrice, undefined))}
                                </div>
                            )}
                        </div>
                        {!inSmallMode() && product.hasAnyPrice() && renderTotalPrice('normal')}
                    </React.Fragment>
                )}
                {!valid && (
                    <div className='shopping-cart-item-error'>Error al cargar el producto ({item.product})</div>
                )}
            </div>
            {inSmallMode() && product.hasAnyPrice() && renderTotalPrice('small')}
            <div className='shopping-cart-item-actions' style={actionsStyle()}>
                <div className='shopping-cart-item-delete-container'>
                    <LinkButton icon={undefined} label='Eliminar' labelColor='#7A797A' onClick={handleDeleteClick}/>
                </div>
                {valid && product.maxPerItem > 0 && product.hasAnyPrice() && (
                    <div className='shopping-cart-item-quantity-container'>
                        <div className={`shopping-cart-item-quantity-decrease ${item.quantity <= item.minQuantity ? 'disabled' : ''}`} onClick={handleQuantityDecreaseClick}>
                            <span className='shopping-cart-item-quantity-content'>-</span>
                        </div>
                        <div className='shopping-cart-item-quantity'>
                            <span className='shopping-cart-item-quantity-content'>{item.quantity}</span>
                        </div>
                        <div className={`shopping-cart-item-quantity-increase ${item.quantity >= item.maxQuantity ? 'disabled' : ''}`} onClick={handleQuantityIncreaseClick}>
                            <span className='shopping-cart-item-quantity-content'>+</span>
                        </div>
                    </div>
                )}
                {(hasNoStock() || hasNoPrice() || hasNoStockNorPrice() || isOnRequestProduct()) && (
                    <div className='shopping-cart-redirect-button-container'>
                        <Button
                            label='Ver categoría'
                            labelColor={actionButtonLabelColor}
                            backgroundColor={actionButtonBackgroundColor}
                            borderColor={actionButtonBorderColor}
                            borderWidth={1}
                            minWidth='auto'
                            maxWidth='100%'
                            height='auto'
                            fontSize={16}
                            padding='4px 16px'
                            boxShadow={undefined}
                            showPulseEffect={true}
                            disabled={false}
                            onClick={viewSimilarProducts}/>
                    </div>
                )}
            </div>
            {hasNoStock() && (
                <div className='shopping-cart-item-invalid'>Producto sin stock</div>
            )}
            {hasNoPrice() && (
                <div className='shopping-cart-item-invalid'>Producto sin precio</div>
            )}
            {hasNoStockNorPrice() && (
                <div className='shopping-cart-item-invalid'>Producto sin stock ni precio</div>
            )}
            {notEnoughStock() && (
                <div className='shopping-cart-item-invalid'>Máximo {product.maxPerItem}{unit ? ` ${unit}` : ''}</div>
            )}
            {isOnRequestProduct() && (
                <div className='shopping-cart-item-invalid'>Disponible sólo a pedido en el mesón de atención</div>
            )}
        </div>
    );
}

export default ShoppingCartItem;